import React, {useState} from "react";
import {DatePickerCalendar} from "react-nice-dates";
import {de} from 'date-fns/locale';
import 'react-nice-dates/build/style.css'
import {Button, Dialog, Grid,} from "@mui/material";
import {GlobalStyledDialogPaper, GlobalStyledDivider} from "../StyledComponents/GlobalStyled/GlobalStyled";
import {useTranslation} from "react-i18next";
import {getCurrentDateAsUTCTimestamp, localDateToUTCTimestamp} from "../../utility/dateUtil";
import useTheme from "@mui/material/styles/useTheme";

interface propsType {
    open: boolean,

    selectCallback(datum: number): void

    closeCallback(): void
}

function SelectionDialog(props: propsType) {
    const [date, setDate] = useState<Date | any>(new Date(getCurrentDateAsUTCTimestamp()));
    const {t} = useTranslation();
    const theme = useTheme();
    return (

        <Dialog open={props.open} PaperProps={{sx: {backgroundColor: "paper.default"}}}
                onClose={props.closeCallback}>
            <Grid container xs={12}>
                <GlobalStyledDialogPaper>
                    <Grid item xs={12} sx={{
                        " .nice-dates-day.-outside": {
                            color: "#691414"
                        },
                        ".nice-dates-week-header_day": {
                            color: "black"
                        },
                        ".nice-dates-day_month": {
                            color: "black"
                        },
                        ".nice-dates-navigation": {
                            color: "black"
                        },
                        ".nice-dates-day:before": {
                            backgroundColor: theme.palette.secondary.dark,
                            border: `2px solid ${theme.palette.secondary.dark}`
                        },
                        ".nice-dates-day:after": {
                            border: `2px solid ${theme.palette.secondary.dark}`
                        },

                        ".nice-dates-navigation_previous:hover:before, .nice-dates-navigation_next:hover:before": {
                            borderColor: `${theme.palette.secondary.dark}`
                        },
                        ".nice-dates-day:not(.-disabled):hover:after": {
                            borderColor: `${theme.palette.secondary.dark}`,
                        },
                        ".nice-dates-navigation_previous:before, .nice-dates-navigation_next:before": {
                            borderRight: `2px solid black`,
                            borderTop: `2px solid black`
                        }
                    }}>
                        <DatePickerCalendar
                            date={date} onDateChange={setDate}
                            locale={de}/>
                        <GlobalStyledDivider/>
                    </Grid>
                    <Grid item xs={12} container justifyContent={"center"}>
                        <Button variant={"contained"} color={"secondary"} onClick={() => {
                            if (date != null) {
                                props.selectCallback(localDateToUTCTimestamp(date))
                            }
                        }}>
                            {t("actions.chooseDate")}
                        </Button>
                    </Grid>
                </GlobalStyledDialogPaper>
            </Grid>
        </Dialog>)
}

export default SelectionDialog;
