import React, {createContext} from "react";

interface ThemeModeContextContextType {
    mode: string,
    updateContent: (newText: string) => void,
}

const initialValue: ThemeModeContextContextType = {
    mode: "light",
    updateContent: (mode: string) => {
    }
}
const ThemeModeContext = createContext<ThemeModeContextContextType>(initialValue);

export {ThemeModeContext};
export type {ThemeModeContextContextType};