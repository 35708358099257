import {List, ListItem, Tab, Tabs, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AppointmentModel, ResourceType} from "../../model/types/basistypes/times/AppointmentModel";
import {addDays} from "date-fns";
import {getAppointmentsForRange} from "../../model/ModelController/times/AppointmentController";
import {Box} from "@mui/system";
import {GlobalStyledTextField} from "../StyledComponents/GlobalStyled/GlobalStyled";
import {useTranslation} from "react-i18next";
import {
    getCurrentDateAtStartAsUTCTimestamp,
    localDateToUTCTimestamp,
    utcTimestampToFormattedString
} from "../../utility/dateUtil";


export default function EventDashboard() {
    const [employeeEvents, setEmployeeEvents] = useState<AppointmentModel[]>([]);
    const [vehicleEvents, setVehicleEvents] = useState<AppointmentModel[]>([]);
    const [equipmentEvents, setEquipmentEvents] = useState<AppointmentModel[]>([]);
    const [events, setEvents] = useState<AppointmentModel[]>([]);
    const [selected, setSelected] = useState(0)
    const [timeRange, setTimeRange] = useState(31);
    const {t, i18n} = useTranslation();


    useEffect(() => {
        let today = getCurrentDateAtStartAsUTCTimestamp();
        let future = localDateToUTCTimestamp(addDays(today, timeRange));

        let getEvents = async () => {
            getAppointmentsForRange(ResourceType.EMPLOYEE, today, future)
                .then((events) => {
                    setEmployeeEvents(events);
                    setEvents(employeeEvents)
                })

            getAppointmentsForRange(ResourceType.VEHICLE, today, future).then(events => setVehicleEvents(events))
            getAppointmentsForRange(ResourceType.EQUIPMENT, today, future).then(events => setEquipmentEvents(events))
        }
        getEvents();
    }, [timeRange]);

    return (
        <Box>
            <Typography variant={"h6"} align={"center"}>{t("dashboard.upcomingAppointments")}</Typography>
            <GlobalStyledTextField label={t("dashboard.periodInDays")}
                                   onChange={(e) => {
                                       let number = Number(e.currentTarget.value);
                                       console.log(number);
                                       setTimeRange(number)
                                   }}
                                   value={timeRange}/>
            <Tabs value={selected} variant="scrollable" scrollButtons aria-label="basic tabs example">
                <Tab label={t("employee.title") + " (" + employeeEvents.length + ")"}
                     value={0}
                     onClick={() => {
                         setEvents(employeeEvents);
                         setSelected(0)
                     }}/>
                <Tab label={t("vehicle.title") + " (" + vehicleEvents.length + ")"}
                     value={1}
                     onClick={() => {
                         setEvents(vehicleEvents);
                         setSelected(1)
                     }}/>
                <Tab label={t("equipment.title") + " (" + equipmentEvents.length + ")"}
                     value={2}
                     onClick={() => {
                         setEvents(equipmentEvents);
                         setSelected(2)
                     }}/>
            </Tabs>
            <List>
                {
                    events.map((event, index) => {
                        return (<ListItem>
                            {utcTimestampToFormattedString(event.date)} : {event.appointmentType?.description} - {(selected === 0) ? event.resource.surname + ", " + event.resource.name : (selected === 1) ? event.resource.licensePlace : event.resource.producer + "[" + event.resource.type + "]"}
                        </ListItem>)
                    })
                }
            </List>
        </Box>
    )
}
