import {styled} from "@mui/material/styles";
import {AccordionSummary} from "@mui/material";

const SidebarStyledAccordionSummary = styled(AccordionSummary)(({theme}) => ({
    width: "100%",
    paddingLeft: theme.spacing(2),
    "& .MuiAccordionSummary-content": {
        margin: 0,
        padding: 0
    },
    "& .MuiList-root": {
        width: "100%",
        "& .MuiListItemButton-root": {
            width: "100%",
        }
    }
}));


export {SidebarStyledAccordionSummary};
