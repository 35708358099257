import VehicleModel from "../../../model/types/basistypes/ressources/VehicleModel";
import EmployeeModel from "../../../model/types/basistypes/ressources/EmployeeModel";
import TeamModel from "../../../model/types/TeamModel";
import DrivingLicenseClassModel from "../../../model/types/basistypes/ressources/DrivingLicenseClassModel";

const determineLicenseTypePermutation = (startIndex: number, resoruces: VehicleModel[] | EmployeeModel[], licenseIds: number[], permutationId: String) => {
    if (startIndex === resoruces.length - 1 || resoruces.length === 0) {
        let resource = resoruces[startIndex]
        let finalMap = new Map<string, number[]>();
        if (resource) {
            if (resource.drivingLicenseClasses.length === 0)
                finalMap.set(permutationId + "_[]", [...licenseIds])
            if (resource.drivingLicenseClasses) {
                resource.drivingLicenseClasses.forEach((licenseType: DrivingLicenseClassModel, index: number) => {
                    finalMap.set(permutationId + "_" + index, [...licenseIds, licenseType.id])
                })
            }
        }
        return finalMap;
    } else {
        let resource = resoruces[startIndex];
        let tempMap: Map<string, number[]> = new Map<string, number[]>();
        if (resource) {
            if (resource.drivingLicenseClasses) {
                if (resource.drivingLicenseClasses.length === 0) {
                    let returnedMap = determineLicenseTypePermutation((startIndex + 1), resoruces, [...licenseIds], permutationId + "_[]")
                    tempMap = new Map<string, number[]>(returnedMap);
                }
                resource.drivingLicenseClasses.forEach((licenseType: DrivingLicenseClassModel, index: number) => {
                    let returnedMap = determineLicenseTypePermutation((startIndex + 1), resoruces, [...licenseIds, licenseType.id], permutationId + "_" + index);
                    // @ts-ignore
                    tempMap = new Map<string, number[]>([...tempMap, ...returnedMap]);
                })
            }
        }
        return tempMap;
    }
}

const getDistinctLicencePermutatation = (permutations: Map<string, number[]>) => {
    let distinctPermuations = new Map<number, Map<number, number>>();

    permutations.forEach((value) => {
        let licenseCountMap = new Map<number, number>();
        let sum = 0;
        value.forEach((licenseId) => {
            sum = licenseId + sum;
            let licenseIdCount = licenseCountMap.get(licenseId);
            if (licenseIdCount) {
                licenseCountMap.set(licenseId, licenseIdCount + 1)
            } else {
                licenseCountMap.set(licenseId, 1)
            }
        })
        distinctPermuations.set(sum, licenseCountMap);
    })
    return distinctPermuations;
}

const validateTeamConfiguration = (team: TeamModel, isAvailable: (Id: number) => boolean) => {
    let relevantVehicles: VehicleModel[] = []
    team.vehicles.forEach((value) => {
        if (isAvailable(value.id)) {
            relevantVehicles.push(value)
        }
    })

    let relevantEmployees: EmployeeModel[] = []
    team.employees.forEach((value) => {
        if (isAvailable(value.id)) {
            relevantEmployees.push(value)
        }
    })
    if (relevantVehicles.length > 0) {
        let vehiclePermutations = determineLicenseTypePermutation(0, relevantVehicles, [], "Vehicle");
        let employeePermuitations = determineLicenseTypePermutation(0, relevantEmployees, [], "Employee")

        let distinctVehiclePermutations = getDistinctLicencePermutatation(vehiclePermutations)
        let distinctEmployeePermutations = getDistinctLicencePermutatation(employeePermuitations)

        // @ts-ignore
        for (let distinctVehiclePermutation of distinctVehiclePermutations.values()) {
            // @ts-ignore
            for (let distinctEmployeePermutation of distinctEmployeePermutations.values()) {
                let permutationMatches = true;
                for (let [key, value] of distinctVehiclePermutation.entries()) {
                    let employeeLicenseIdCount = distinctEmployeePermutation.get(key)
                    if (employeeLicenseIdCount) {
                        permutationMatches = (employeeLicenseIdCount >= value)
                        if (!permutationMatches) {
                            break;
                        }
                    } else {
                        permutationMatches = false;
                        break;
                    }
                }
                if (permutationMatches) {
                    return true;
                }
            }
        }
        return false;
    } else {
        return true;
    }
}


export {validateTeamConfiguration};