import React from 'react';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

interface LineDiagramData {
    name: string,
    employee: number,
    vehicle: number,
    equipment: number,
}

interface propsType {
    data: LineDiagramData[]
    title: string
}

export default function ResponsiveLineChart(props: propsType) {
    const {t, i18n} = useTranslation();

    return (
        <Grid item container xs={12} sx={{height: "100%"}}>
            <Grid item xs={12}>
                <Typography variant={"h6"} align={"center"}>{props.title}</Typography>
            </Grid>
            <Grid item xs={12}>
                <ResponsiveContainer aspect={4}>
                    <LineChart
                        width={500}
                        height={300}
                        data={props.data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Line type="monotone" dataKey="employee" name={t("employee.title")} stroke={"#8884d8"}
                              activeDot={{r: 8}}/>
                        <Line type="monotone" dataKey="vehicle" name={t("vehicle.title")} stroke={"#ff5200"}/>
                        <Line type="monotone" dataKey="equipment" name={t("equipment.title")} stroke={"#62ff00"}/>
                    </LineChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>
    );
}

export type {LineDiagramData}
