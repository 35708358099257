import {FieldValidator, proxiedPropertiesOf, ValidatorType} from "../../../ValidatorHook";
import EquipmentModel from "../../../../model/types/basistypes/ressources/EquipmentModel";
import {checkDateInPast} from "../../../../utility/dateUtil";

let machine: EquipmentModel;
// @ts-ignore
const properties = proxiedPropertiesOf(machine)
const nonEmptyString = "^(?!\\s*$).+";

const EquipmentValidator: FieldValidator[] = [
    {
        FieldName: properties.producer,
        Validation: nonEmptyString,
        Type: ValidatorType.REGEX,
        ErrorMessage: "validator.nonEmpty"
    },
    {
        FieldName: properties.type,
        Validation: nonEmptyString,
        Type: ValidatorType.REGEX,
        ErrorMessage: "validator.nonEmpty",
    },
    {
        FieldName: properties.investmentNumber,
        Validation: "^[a-zA-Z0-9]+$",
        Type: ValidatorType.REGEX,
        ErrorMessage: "validator.equipmentMask.investmentError",
    },
    {
        FieldName: properties.serialNumber,
        Validation: "^[a-zA-Z0-9 -]+$",
        Type: ValidatorType.REGEX,
        ErrorMessage: "validator.equipmentMask.serialNumberError",
    },
    {
        FieldName: properties.purchaseDate,
        Validation: checkDateInPast,
        Type: ValidatorType.Function,
        ErrorMessage: "validator.futureDate"
    },
]

export default EquipmentValidator;
