import Role from "../../../../model/types/permissions/Role";
import {Checkbox, Grid, Typography} from "@mui/material";
import Entities from "../../../../model/types/permissions/Entities";
import {ResourceStyledFormControlLabel} from "../../../StyledComponents/ComponentStyled/ResourceStyled";
import React, {useEffect, useState} from "react";
import {
    GlobalStyledDivider,
    GlobalStyledPaper,
    GlobalStyledTextField
} from "../../../StyledComponents/GlobalStyled/GlobalStyled";
import {proxiedPropertiesOf} from "../../../ValidatorHook";
import {useTranslation} from "react-i18next";
import Permission from "../../../../model/types/permissions/Permission";
import {Delete, Save} from "@mui/icons-material";
import {addRole, deleteRole} from "../../../../model/ModelController/Permissions/RoleController";
import GenericActionBar from "../../../ActionBar/GenericActionBar";

interface propsType {
    editEnabled: boolean
    asDialog: boolean
    role: Role
    entities: Entities

    updateCallback(equipment: Role): void

    deleteCallback(equipment: Role): void

    isDirtyCallback?(): void

    closeCallback(): void

}


export default function RoleMask(props: propsType) {
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [localRole, setLocalRole] = useState<Role>(props.role)

    useEffect(() => {
        if (props.role._isNew) {
            setIsDirty(true);
        } else {
            setIsDirty(false);
        }

        let permissions = props.role.permissions;
        for (let entity of props.entities.entities) {
            let includesEntity = permissions.map(value => value.entity === entity).reduce((previousValue, currentValue) => previousValue || currentValue)
            if (!includesEntity) {
                permissions.push({
                    entity: entity,
                    updateAccess: false,
                    deleteAccess: false,
                    readAccess: false,
                    createAccess: false
                } as Permission)
            }
        }


        setLocalRole({...props.role, permissions: permissions})


    }, [props.entities.entities, props.role])
    const fieldDescriptor = proxiedPropertiesOf(localRole)


    const {t} = useTranslation();

    const wrapCallback = (callback: any, windowmode: boolean) => {
        return (windowmode) ?
            () => {
                callback()
                props.closeCallback();
            } : callback
    }

    const roleActions = [
        {
            icon: <Save/>,
            name: 'Save',
            tooltip: t("actions.save"),
            callback: wrapCallback(() => {
                addRole(localRole).then(value => {
                    setIsDirty(false);
                });
                props.updateCallback(localRole)
            }, props.asDialog),
            disabled: !isDirty,
            actionKey: "s"
        },
        {
            icon: <Delete/>,
            name: 'Delete',
            tooltip: t("actions.delete"),
            callback: wrapCallback(() => {
                deleteRole(localRole);
                props.deleteCallback(localRole)
            }, props.asDialog)
        },]

    const getCheckbox = (permission: Permission, key: string, value: boolean, index: number) => {
        return <Grid xs={2}>
            <ResourceStyledFormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={() => {
                            let permissions = [...localRole.permissions];
                            permissions.splice(index, 1, {
                                ...permission,
                                [key]: !value
                            })
                            if (props.isDirtyCallback) {
                                setIsDirty(true);
                                props.isDirtyCallback();
                            }
                            setLocalRole({...localRole, permissions: permissions})
                        }}
                        name={key}
                    />
                }
                label={t("roles.permission." + key) + ""}
            />
        </Grid>
    }

    return <Grid container xs={12}>
        <GlobalStyledPaper sx={{height: "25vh"}}>
            <Grid container
                  alignItems={"flex-start"}
                  xs={12}>
                <GenericActionBar actions={roleActions}/>
                <Grid xs={12}>
                    <Typography variant={"h6"} align={"center"}> {t("roles.attributes")}</Typography>
                    <GlobalStyledDivider/>
                </Grid>
                <Grid item xs={6}>
                    <GlobalStyledTextField
                        name={fieldDescriptor.id}
                        required
                        label={t("roles." + fieldDescriptor.id)}
                        value={localRole.id}
                        onChange={(e) => {
                            setLocalRole({...localRole, id: e.currentTarget.value} as Role)
                            if (props.isDirtyCallback) {
                                setIsDirty(true);
                                props.isDirtyCallback();
                            }
                        }}
                        variant={"outlined"}/>
                </Grid>

                <Grid xs={2}>
                    <ResourceStyledFormControlLabel
                        control={
                            <Checkbox
                                checked={localRole.assignableAsResponsible}
                                onChange={() => {
                                    setLocalRole({
                                        ...localRole,
                                        assignableAsResponsible: !localRole.assignableAsResponsible
                                    })
                                    if (props.isDirtyCallback) {
                                        setIsDirty(true);
                                        props.isDirtyCallback();
                                    }
                                }}
                                name={fieldDescriptor.assignableAsResponsible}
                            />
                        }
                        label={t("roles." + fieldDescriptor.assignableAsResponsible) + ""}
                    />
                </Grid>
            </Grid>
        </GlobalStyledPaper>

        <GlobalStyledPaper sx={{height: "60vh"}}>
            <Grid container xs={12}>
                <Grid xs={12}><GlobalStyledDivider/></Grid>
                <Grid xs={12}>
                    <Typography variant={"h6"} align={"center"}> {t("roles.permissions")}</Typography>
                    <GlobalStyledDivider/>
                </Grid>
                <Grid xs={12}>
                    {
                        localRole.permissions.map((value: Permission, index: number) => {
                            let permissionFieldDescriptor = proxiedPropertiesOf(value)
                            return <Grid container xs={12}>
                                <Grid xs={4}> <Typography> {value.entity}</Typography></Grid>
                                {getCheckbox(value, permissionFieldDescriptor.createAccess, value.createAccess, index)}
                                {getCheckbox(value, permissionFieldDescriptor.updateAccess, value.updateAccess, index)}
                                {getCheckbox(value, permissionFieldDescriptor.readAccess, value.readAccess, index)}
                                {getCheckbox(value, permissionFieldDescriptor.deleteAccess, value.deleteAccess, index)}
                                <Grid xs={12}> <GlobalStyledDivider/> </Grid>
                            </Grid>
                        })
                    }
                </Grid>
            </Grid>
        </GlobalStyledPaper>
    </Grid>

}
