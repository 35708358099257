import {Delete} from "@mui/icons-material";
import {IconButton, TableCell, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import {CustomerContactModel} from "../../../../../model/types/basistypes/ressources/CustomerModel";
import {GlobalStyledTextField} from "../../../../StyledComponents/GlobalStyled/GlobalStyled";
import useValidator, {proxiedPropertiesOf} from "../../../../ValidatorHook";
import CustomerContactEntryValidator from "./CustomerContactEntryValidator";
import React from "react";

interface propsType {
	contact: CustomerContactModel;
	updateCustomerContact: (contact: CustomerContactModel) => void;
	delete: () => void;
	customerId: number;
}

const CustomerContactEntry = (props: propsType) => {
	const customerContactEntryValidator = useValidator(CustomerContactEntryValidator, props.contact, props.customerId);

	let customerContactFields = proxiedPropertiesOf(props.contact);
	const {t} = useTranslation();

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const fieldName: string = e.currentTarget.name;
		const newValue = e.currentTarget.value;
		let newState: CustomerContactModel = {...props.contact};
		newState[fieldName] = newValue;
		props.updateCustomerContact(newState);
	};

	return (
		<TableRow>
			<TableCell>
				<IconButton
					onClick={() => {
						props.delete();
					}}
				>
					<Delete/>
				</IconButton>
			</TableCell>
			<TableCell>
				<GlobalStyledTextField
					name={customerContactFields.name}
					required
					onFocus={event => {
						event.target.select();
					}}
					label={t("customer.contact.name")}
					value={props.contact.name}
					error={customerContactEntryValidator.containsError(customerContactFields.name)}
					helperText={customerContactEntryValidator.getErrorMessage(customerContactFields.name)}
					onChange={customerContactEntryValidator.wrapOnChangeInValidator(onChange)}
					variant={"outlined"}/>
			</TableCell>
			<TableCell>
				<GlobalStyledTextField
					name={customerContactFields.position}
					label={t("customer.contact.position")}
					value={props.contact.position}
					onChange={onChange}
					variant={"outlined"}
				/>
			</TableCell>
			<TableCell>
				<GlobalStyledTextField
					name={customerContactFields.email}
					label={t("customer.contact.email")}
					value={props.contact.email}
					onChange={onChange}
					variant={"outlined"}
				/>
			</TableCell>
			<TableCell>
				<GlobalStyledTextField
					name={customerContactFields.mobile}
					label={t("customer.contact.mobile")}
					value={props.contact.mobile}
					onChange={onChange}
					variant={"outlined"}
				/>
			</TableCell>
			<TableCell>
				<GlobalStyledTextField
					name={customerContactFields.telNr}
					label={t("customer.contact.telNr")}
					value={props.contact.telNr}
					onChange={onChange}
					variant={"outlined"}
				/>
			</TableCell>
		</TableRow>
	);
};

export default CustomerContactEntry;
