import {AppointmentModel, ResourceType} from "../../types/basistypes/times/AppointmentModel";
import {getFirebase} from "../FirebaseWrapper";
import getCompanyAffiliation from "../CompanyAffiliation";
import {getAppointmentTypDocument} from "./TerminTypController";
import {AppointmentSeriesModel} from "../../types/basistypes/times/AppointmentSeriesModel";
import VehicleModel from "../../types/basistypes/ressources/VehicleModel";
import EmployeeModel from "../../types/basistypes/ressources/EmployeeModel";
import EquipmentModel from "../../types/basistypes/ressources/EquipmentModel";
import {addDays} from "date-fns";
import {addAppointment, getAppointmentDocument} from "./AppointmentController";
import firebase from "firebase";
import {resolveNestedDocumentList} from "../FirebaseConverter";


function uuidv4() {
    //@ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    ) as string;
}



function getAppointment(date: number, appointmentSeries: AppointmentSeriesModel, resource: VehicleModel | EmployeeModel | EquipmentModel, resourceType: ResourceType): AppointmentModel {
    return {
        appointmentSeriesId: appointmentSeries.id,
        date: date,
        appointmentType: appointmentSeries.appointmentType,
        id: appointmentSeries.id + "_" + uuidv4(),
        resource: resource,
        resourceType: resourceType,
        reusable: appointmentSeries.reusable
    } as AppointmentModel
}


const updateAppointmentSeries = async (appointmentSeries: AppointmentSeriesModel) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();
    let companyId = await getCompanyAffiliation();

    if (companyId && appointmentSeries.appointmentType) {
        const batch = dbConnection.batch();
        const seriesRef = dbConnection
            .collection(companyId)
            .doc("AppointmentSeries")
            .collection("AppointmentSeries").doc(appointmentSeries.id.toString());

        const parsedAppointments: any[] = [];
        for (let appointment of appointmentSeries.appointments) {
            let appointmentRef = await getAppointmentDocument(appointment.id);
            parsedAppointments.push(appointmentRef);
            if (appointmentRef)
                batch.update(appointmentRef, {
                    reusable: appointmentSeries.reusable,
                    appointmentType: await getAppointmentTypDocument(appointmentSeries.appointmentType.id)
                } as AppointmentModel)


        }
        if (appointmentSeries.appointmentType)
            batch.set(seriesRef, {
                    ...appointmentSeries,
                    appointments: parsedAppointments,
                    appointmentType: await getAppointmentTypDocument(appointmentSeries.appointmentType.id)
                } as AppointmentSeriesModel
            );

        batch.commit().then().catch(e => console.log(e))
    }
}

const deleteAppointmentSeries = async (appointmentSeries: AppointmentSeriesModel) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();
    let companyId = await getCompanyAffiliation();

    if (companyId) {
        const batch = dbConnection.batch();
        const seriesRef = dbConnection
            .collection(companyId)
            .doc("AppointmentSeries")
            .collection("AppointmentSeries").doc(appointmentSeries.id.toString());

        batch.delete(seriesRef);

        for (let appointment of appointmentSeries.appointments) {
            let appointmentRef = await getAppointmentDocument(appointment.id);
            if (appointmentRef)
                batch.delete(appointmentRef)
        }

        batch.commit().then().catch(e => console.log(e))

    }
}

const resolveAppointmentSeriesModel = async (doc: any) => {
    let series = doc.data() as AppointmentSeriesModel;
    let array: firebase.firestore.DocumentReference[] = []
    series.appointments = (await resolveNestedDocumentList(series.appointments));
    if (series.appointmentType) {
        series.appointmentType = (await resolveNestedDocumentList([series.appointmentType]))[0];
    }

    return series as AppointmentSeriesModel;
}

const getAppointmentSeriesById = async (id: number) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();
    let companyId = await getCompanyAffiliation();

    if (companyId !== undefined) {
        const series = await dbConnection
            .collection(companyId)
            .doc("AppointmentSeries")
            .collection("AppointmentSeries")
            .doc(id.toString()).get();

        return await resolveAppointmentSeriesModel(series);
    }
}

const createNewSeries = async (appointmentSeries: AppointmentSeriesModel, resource: VehicleModel | EmployeeModel | EquipmentModel, resourceType: ResourceType) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();
    let companyId = await getCompanyAffiliation();

    if (companyId !== undefined && appointmentSeries.appointmentType) {
        if (appointmentSeries.appointmentType.multiday) {
            let date: number = appointmentSeries.from;
            while (date <= appointmentSeries.to) {

                let appointment: AppointmentModel = getAppointment(date, appointmentSeries, resource, resourceType)
                await addAppointment(appointment);
                appointmentSeries.appointments.push(appointment);
                date = addDays(date, 1).getTime();
            }

            let parsedAppointments = [];

            for (let innerAppointment of appointmentSeries.appointments) {

                parsedAppointments.push(await getAppointmentDocument(innerAppointment.id));
            }
            let appointmentSeriesFirebase: any = {
                ...appointmentSeries,
                appointmentType: await getAppointmentTypDocument(appointmentSeries.appointmentType.id),
                appointments: parsedAppointments,
            }
            dbConnection
                .collection(companyId)
                .doc("AppointmentSeries")
                .collection("AppointmentSeries")
                .doc(appointmentSeriesFirebase.id.toString()).set(appointmentSeriesFirebase);

            return appointmentSeries;
        } else {
            let appointment: AppointmentModel = getAppointment(appointmentSeries.from, appointmentSeries, resource, resourceType)
            await addAppointment(appointment);
            appointmentSeries.appointments.push(appointment);
            let parsedAppointments = [];

            for (let innerAppointment of appointmentSeries.appointments) {

                parsedAppointments.push(await getAppointmentDocument(innerAppointment.id));
            }
            let appointmentSeriesFirebase: any = {
                ...appointmentSeries,
                appointmentType: await getAppointmentTypDocument(appointmentSeries.appointmentType.id),
                appointments: parsedAppointments,
            }
            dbConnection
                .collection(companyId)
                .doc("AppointmentSeries")
                .collection("AppointmentSeries")
                .doc(appointmentSeriesFirebase.id.toString()).set(appointmentSeriesFirebase);

            return appointmentSeries
        }
    }
    return appointmentSeries
}

export {createNewSeries, deleteAppointmentSeries, updateAppointmentSeries, getAppointmentSeriesById}
