import firebase from "firebase";
import firebaseConfig from "./FireBaseConfig";

require("firebase/firestore");

let isInitialized: boolean = false;
let secondaryInitialzed: boolean = false;

let primaryApp: firebase.app.App;
let authenticationApp: firebase.app.App;
let dbConnection: firebase.firestore.Firestore;

const getFirebase = () => {
    if (!isInitialized) {
        primaryApp = firebase.initializeApp(firebaseConfig)
        primaryApp.firestore().settings({cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED})
        dbConnection = primaryApp.firestore();
        dbConnection.enablePersistence({synchronizeTabs: true})
        /*keeping this to enable during local testing.
        if (window.location.hostname === "localhost") {
            firebase.functions().useEmulator("localhost", 5001)
        }*/
        isInitialized = true;
    }
    return primaryApp;
}

const getUserCreationFirebaseConnection = () => {
    if (!secondaryInitialzed) {
        authenticationApp = firebase.initializeApp(firebaseConfig, "UserCreationApp")
        secondaryInitialzed = true;
    }
    return authenticationApp;

}

const isAuthenticated = () => {
    const initialFirebase = getFirebase();
    let auth = initialFirebase.auth()
    let user = auth.currentUser
    return user != null;
}

const getUser = () => {
    const initialFirebase = getFirebase();
    let auth = initialFirebase.auth()
    return auth.currentUser;
}


const getFireStore = () => {
    if (dbConnection) {
        return dbConnection;
    } else {
        let firebase = getFirebase();
        return firebase.firestore();
    }


}

export {getFirebase, isAuthenticated, getUserCreationFirebaseConnection, getUser, getFireStore}



