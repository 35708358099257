import {FieldValidator, proxiedPropertiesOf, ValidatorType} from "../../../ValidatorHook";
import DrivingLicenseClassModel from "../../../../model/types/basistypes/ressources/DrivingLicenseClassModel";

let licenseClass:DrivingLicenseClassModel;
// @ts-ignore
const properties = proxiedPropertiesOf(licenseClass)
const nonEmptyString = "^(?!\\s*$).+";

const DrivingLicenseClassValidator: FieldValidator[] = [
    {
        FieldName: properties.description,
        Validation: nonEmptyString,
        Type: ValidatorType.REGEX,
        ErrorMessage: "validator.nonEmpty"
    },
]

export default DrivingLicenseClassValidator;
