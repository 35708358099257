import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

enum ValidatorType {
    REGEX,
    Function,
}

interface FieldValidator {
    FieldName?: string,
    Type: ValidatorType,
    Validation: string | ((value: any) => boolean)
    ErrorMessage: string
}

interface Error {
    FieldValue: string,
    ErrorMessage: string,
}

const executeValidator = (fieldValue: any, fieldValidators: FieldValidator[]) => {
    let errorList: Error[] = [];
    for (let fieldValidator of fieldValidators) {
        switch (fieldValidator.Type) {
            case ValidatorType.Function:
                if (typeof fieldValidator.Validation === "function") {
                    let valid = fieldValidator.Validation(fieldValue)
                    if (!valid) {
                        errorList.push({FieldValue: fieldValue, ErrorMessage: fieldValidator.ErrorMessage})
                    }
                }
                break;
            case ValidatorType.REGEX: {
                if (typeof fieldValidator.Validation === "string") {
                    let regex = new RegExp(fieldValidator.Validation)
                    let valid = regex.test(fieldValue)
                    if (!valid) {
                        errorList.push({FieldValue: fieldValue, ErrorMessage: fieldValidator.ErrorMessage})
                    }
                }
            }
                break;
        }
    }
    return errorList
}

const proxiedPropertiesOf = <TObj>(obj?: TObj) => new Proxy({}, {
    get: (_, prop) => prop,
    set: () => {
        throw Error('Set not supported');
    },
}) as {
    [P in keyof TObj]: P;
};


export default function useValidator(Validator: FieldValidator[], ValidationBaseObject: any, resetIndicator: any) {
    const [errorMap, setErrorMap] = useState<Map<string, Error[]>>(new Map<string, Error[]>())
    const {t} = useTranslation();

    useEffect(() => {
        setErrorMap(new Map<string, Error[]>())
    }, [resetIndicator])

    const performFullValidation = () => {
        let keys = Object.keys(ValidationBaseObject);
        let stateCopy = new Map(errorMap);
        let errorCount = 0;
        for (let key of keys) {
            let FieldValidators = Validator.filter((fieldValidator, index) => {
                return (fieldValidator.FieldName === key)
            })
            let identifiedErrors: Error[] = executeValidator(ValidationBaseObject[key], FieldValidators)
            errorCount = errorCount + identifiedErrors.length;
            stateCopy.set(key, identifiedErrors);
        }
        setErrorMap(stateCopy);
        return {stateCopy, errorCount};
    }
    const wrapOnChangeInValidator = (onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            const targetField = event.currentTarget.name;
            const fieldValue = event.currentTarget.value;
            let FieldValidators = Validator.filter((fieldValidator, index) => {
                return (fieldValidator.FieldName === targetField)
            })
            let identifiedError = executeValidator(fieldValue, FieldValidators);
            const stateCopy = new Map(errorMap);
            stateCopy.set(targetField, identifiedError);
            setErrorMap(stateCopy)
            onChangeHandler(event)
        }
    }

    const containsError = (key: string | undefined) => {
        if (!key) {
            return false;
        }

        let errorArray = errorMap.get(key);
        if (errorArray) {
            if (errorArray.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const getErrorMessage = (key: string | undefined) => {
        if (!key) {
            return "";
        }

        let errorArray = errorMap.get(key);
        if (errorArray) {
            if (errorArray.length > 0) {
                return t(errorArray[0].ErrorMessage);
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    const buildAllErrorMessages = (errors: Map<string, Error[]>): Array<string> => {
        const messageArray: Array<string> = [];
        errors.forEach(errorArray => (errorArray.forEach(error => messageArray.push(t(error.ErrorMessage)))))
        return messageArray;
    }

    return {
        errorMap,
        performFullValidation,
        wrapOnChangeInValidator,
        containsError,
        getErrorMessage,
        getAllErrorMessages: buildAllErrorMessages
    }
}

export {proxiedPropertiesOf, ValidatorType};
export type {FieldValidator}


