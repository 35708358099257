import {createTheme} from '@mui/material/styles';
import {colors, paletteDark, paletteLight} from "../ColorPalette";

declare module '@mui/material/styles' {
    /*    Fucking hell if you change things in here you have to restart the Script*/
    interface Palette {
        paper: {
            main: string;
            light: string;
            dark: string;
            default: string;
            error: string;
            drawer: string;
        };
    }

    interface PaletteOptions {
        paper?: {
            main?: string;
            light?: string;
            dark?: string;
            default?: string;
            error?: string;
            drawer?: string;
        };
    }
}


const getTheme = (mode: string) => {
    let paletteOptions = (mode === "light") ? paletteLight : paletteDark;
    let colorPalette = (mode === "light") ? colors.light : colors.dark;
    return createTheme({
        palette: paletteOptions,
        components: {
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        "& .MuiInputLabel-root": {
                            color: colorPalette.text.primary
                        },
                        "& label.Mui-focused": {
                            color: colorPalette.action.focus
                        },

                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: colorPalette.action.focus
                            },
                            '&:hover fieldset': {
                                borderColor: colorPalette.action.focus
                            },
                        }
                    }
                }
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: colorPalette.text.primary,
                        "&.Mui-checked": {color: colorPalette.secondary.main}
                    }
                }
            },

            MuiAccordion: {
                styleOverrides: {
                    root: {
                        width: "100%",
                        backgroundColor: colorPalette.paper.dark
                    }
                }
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        "&.Mui-selected": {
                            backgroundColor: colorPalette.action.selected,
                        }
                    }
                }
            }
        }

    })
}


export {getTheme};
