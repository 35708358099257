import React, {useEffect, useState} from "react";
import EquipmentModel from "../../../../model/types/basistypes/ressources/EquipmentModel";
import AppointmentList from "../../../Time/AppointmentList";
import {ResourceType} from "../../../../model/types/basistypes/times/AppointmentModel";
import AppointmentMask from "../../../Time/AppointmentMask";
import DnDContextWrapper, {createRegisterCallBack, DnDItem} from "../../../Planning/Helper/DndContextWrapper";
import DroppableJSXwrapper from "../../../Planning/Helper/DroppableJSXwrapper";
import DnDItemCard from "../../../Planning/Helper/DnDItemCard";
import DateField from "../DateField";
import useValidator, {proxiedPropertiesOf} from "../../../ValidatorHook";
import EquipmentValidator from "./EquipmentValidator";
import FullScreenDialogSidebar from "../Helper/FullScreenDialogSidebar";
import {
    addEquipment,
    deleteEquipment,
    getEquipmentThatIsNotAssignedToAGroup
} from "../../../../model/ModelController/Resources/EquipmentController";

import {Add, Delete, Save,} from "@mui/icons-material";
import {Checkbox, FormGroup, Grid, List, ListItem, Typography} from "@mui/material";
import {
    ResourceStyledFormControlLabel,
    ResourceStyledMachinePaper,
} from "../../../StyledComponents/ComponentStyled/ResourceStyled";
import {GlobalStyledDivider, GlobalStyledTextField} from "../../../StyledComponents/GlobalStyled/GlobalStyled";
import {useTranslation} from "react-i18next";
import {Action} from "../../../ActionBar/GenericActionBarIconButton";
import GenericActionBar from "../../../ActionBar/GenericActionBar";

interface propsType {
    editEnabled: boolean
    asDialog: boolean
    equipment: EquipmentModel

    updateCallback(equipment: EquipmentModel): void

    deleteCallback(equipment: EquipmentModel): void

    isDirtyCallback?(): void

    closeCallback(): void

}

function EquipmentMask(props: propsType) {
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [localEquipment, setLocalEquipment] = useState<EquipmentModel>(props.equipment)
    const [openAppointment, setOpenAppointment] = useState(false);
    const [dndRegister, setDndRegister] = useState<Map<string, DnDItem>>(new Map<string, DnDItem>());
    const registerToDnDDRegister = createRegisterCallBack(dndRegister, setDndRegister)
    const [assignableEquipment, setAssignableEquipment] = useState<EquipmentModel[]>([]);
    const fieldDescriptor = proxiedPropertiesOf(localEquipment)
    const machineValidator = useValidator(EquipmentValidator, localEquipment, localEquipment.id);
    const {t} = useTranslation();


    useEffect(() => {
        if (props.equipment._isNew) {
            setIsDirty(true);
        } else {
            setIsDirty(false);
        }
        
        getEquipmentThatIsNotAssignedToAGroup(localEquipment.id).then((Equipment) => {
            setAssignableEquipment(Equipment);
        })
    }, [props.equipment])

    if (props.equipment === undefined) {
        setLocalEquipment(props.equipment)
    }

    if (props.equipment === undefined) {
        return (<div/>)
    }

    if (props.equipment.id !== localEquipment.id) {
        setLocalEquipment(props.equipment)

    }

    const wrapCallback = (callback: any, windowmode: boolean) => {
        return (windowmode) ?
            () => {
                callback()
                props.closeCallback();
            } : callback
    }

    const saveToFireBase = () => {
        let errors = machineValidator.performFullValidation()
        if (errors.errorCount > 0) {
            alert(t("validator.errorPreventingSave"));
            return;
        }
        addEquipment(localEquipment)
            .then(value => setIsDirty(false))
            .catch(e => console.log(e))
        props.updateCallback(localEquipment)
    }

    const deleteFromFireBase = () => {
        deleteEquipment(localEquipment).catch(e => console.log(e))
        props.deleteCallback(localEquipment)
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName: string = e.currentTarget.name;
        const newValue = e.currentTarget.value
        let newState: EquipmentModel = {...localEquipment};
        if (props.isDirtyCallback) {
            setIsDirty(true);
            props.isDirtyCallback();
        }
        newState[fieldName] = newValue;
        setLocalEquipment(newState)
    }

    const appointmentActions: Array<Action> = [
        {
            icon: <Add/>, name: 'Appointment', tooltip: t("actions.appointment"), callback: () => {
                setOpenAppointment(true)
            }
        },
    ];

    const equipmentActions = [];

    if (!props.asDialog) {
        equipmentActions.push(
            {
                icon: <Save/>,
                name: 'Save',
                tooltip: t("actions.save"),
                callback: wrapCallback(saveToFireBase, props.asDialog),
                disabled: !isDirty,
                actionKey: "s"
            } as Action,
            {
                icon: <Delete/>,
                name: 'Delete',
                tooltip: t("actions.delete"),
                callback: wrapCallback(deleteFromFireBase, props.asDialog)
            },
        );
    }

    const getDnDListe = (machineList: EquipmentModel[], updateList: (newList: EquipmentModel[]) => void, DnDId: string) => {
        return (
            <DroppableJSXwrapper Id={DnDId} referencedDnDArray={machineList}
                                 referencedUpdateCallback={updateList}
                                 dndType={"Machine"}
                                 registerCallBack={registerToDnDDRegister}>
                <List>
                    <Grid item container xs={12}>
                        {
                            machineList.map((machine, index) => {
                                if (machine.id === localEquipment.id)
                                    return <div/>
                                return (<Grid item xs={6}><ListItem key={index} sx={{padding: 0, margin: 0}}>
                                    <DnDItemCard title={machine.producer + "[" + machine.type + "]"}
                                                 id={machine.id} index={index} referencedObject={machine}
                                                 available={true} actions={[]}/>
                                </ListItem></Grid>)
                            })
                        }
                    </Grid>
                </List>
            </DroppableJSXwrapper>)
    }

    return ((localEquipment) ?
            <Grid container xs={12}>
                <Grid item xs={12}>
                    {
                        (props.asDialog) ?

                            <FullScreenDialogSidebar iconBackAction={props.closeCallback}
                                                     iconSaveAction={wrapCallback(saveToFireBase, props.asDialog)}
                                                     iconDeleteAction={wrapCallback(deleteFromFireBase, props.asDialog)}
                                                     title={t("equipment.manageTitle")}/>
                            : <div/>
                    }
                </Grid>
                <Grid item container xs={6}>
                    <ResourceStyledMachinePaper>
                        <Grid item container
                              alignItems={"flex-start"}
                              xs={12}>
                            <GenericActionBar actions={equipmentActions}/>
                            <Grid item xs={12}>
                                <Typography variant={"h6"} align={"center"}>
                                    {t("equipment.title") + `: ${localEquipment.producer} [ ${localEquipment.type} ]`}
                                </Typography>
                                <GlobalStyledDivider/>
                            </Grid>
                            <Grid item xs={6}>
                                <GlobalStyledTextField
                                    name={fieldDescriptor.type}
                                    required
                                    label={t(fieldDescriptor.type)}
                                    value={localEquipment.type}
                                    error={machineValidator.containsError(fieldDescriptor.type)}
                                    helperText={machineValidator.getErrorMessage(fieldDescriptor.type)}
                                    onChange={machineValidator.wrapOnChangeInValidator(onChange)}
                                    variant={"outlined"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <GlobalStyledTextField
                                    name={fieldDescriptor.investmentNumber}
                                    required
                                    label={t(fieldDescriptor.investmentNumber)}
                                    value={localEquipment.investmentNumber}
                                    error={machineValidator.containsError(fieldDescriptor.investmentNumber)}
                                    helperText={machineValidator.getErrorMessage(fieldDescriptor.investmentNumber)}
                                    onChange={machineValidator.wrapOnChangeInValidator(onChange)}
                                    variant={"outlined"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <GlobalStyledTextField
                                    name={fieldDescriptor.serialNumber}
                                    required
                                    label={t(fieldDescriptor.serialNumber)}
                                    value={localEquipment.serialNumber}
                                    error={machineValidator.containsError(fieldDescriptor.serialNumber)}
                                    helperText={machineValidator.getErrorMessage(fieldDescriptor.serialNumber)}
                                    onChange={machineValidator.wrapOnChangeInValidator(onChange)}
                                    variant={"outlined"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <DateField Date={localEquipment.purchaseDate}
                                           label={t(fieldDescriptor.purchaseDate)}
                                           error={machineValidator.containsError(fieldDescriptor.purchaseDate)}
                                           helperText={machineValidator.getErrorMessage(fieldDescriptor.purchaseDate)}
                                           onChange={(Date) => {
                                               if (props.isDirtyCallback) {
                                                   setIsDirty(true);
                                                   props.isDirtyCallback();
                                               }
                                               setLocalEquipment({...localEquipment, purchaseDate: Date})
                                           }}/>
                            </Grid>
                            <Grid item xs={6}>
                                <GlobalStyledTextField
                                    name={fieldDescriptor.producer}
                                    required
                                    label={t(fieldDescriptor.producer)}
                                    value={localEquipment.producer}
                                    error={machineValidator.containsError(fieldDescriptor.producer)}
                                    helperText={machineValidator.getErrorMessage(fieldDescriptor.producer)}
                                    onChange={machineValidator.wrapOnChangeInValidator(onChange)}
                                    variant={"outlined"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <GlobalStyledTextField
                                    name={fieldDescriptor.nickname}
                                    label={t(fieldDescriptor.nickname)}
                                    value={localEquipment.nickname}
                                    error={machineValidator.containsError(fieldDescriptor.nickname)}
                                    helperText={machineValidator.getErrorMessage(fieldDescriptor.nickname)}
                                    onChange={machineValidator.wrapOnChangeInValidator(onChange)}
                                    variant={"outlined"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormGroup>
                                    <ResourceStyledFormControlLabel
                                        label={t("equipment.includesDevices") + ""}
                                        control={<Checkbox checked={localEquipment.hasComponents}
                                                           onChange={() => {
                                                               setLocalEquipment({
                                                                   ...localEquipment,
                                                                   hasComponents: !localEquipment.hasComponents
                                                               })
                                                           }}
                                        />}/>
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </ResourceStyledMachinePaper>
                </Grid>
                <Grid item xs={6}>
                    <ResourceStyledMachinePaper>
                        <Grid item xs={12}>
                            <GenericActionBar actions={appointmentActions}/>
                            <Grid item xs={12}>
                                <Typography variant={"h6"} align={"center"}>
                                    {t("equipment.title") + `: ${localEquipment.producer} [ ${localEquipment.type} ]`}
                                </Typography>
                                <GlobalStyledDivider/>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    (props.equipment) ?
                                        <AppointmentList type={ResourceType.EQUIPMENT} resource={props.equipment}
                                                         Id={props.equipment.id}/>
                                        : ""}
                            </Grid>
                        </Grid>
                    </ResourceStyledMachinePaper>
                </Grid>
                {(localEquipment.hasComponents) ? <Grid item container xs={12}>
                    {
                        // @ts-ignore
                        <DnDContextWrapper registeredItems={dndRegister}>
                            <Grid item xs={6}>
                                <ResourceStyledMachinePaper>
                                    <Grid item xs={12}>
                                        <Typography variant={"h6"} align={"center"}>Zugeordnete Geräte</Typography>
                                        <GlobalStyledDivider/>
                                    </Grid>

                                    <Grid xs={12}>
                                        {getDnDListe(localEquipment.components, (newList => {
                                            if (props.isDirtyCallback) {
                                                setIsDirty(true);
                                                props.isDirtyCallback();
                                            }
                                            setLocalEquipment({...localEquipment, components: newList})
                                        }), "Components")}
                                    </Grid>
                                </ResourceStyledMachinePaper>
                            </Grid>

                            <Grid item xs={6}>
                                <ResourceStyledMachinePaper>
                                    <Grid item xs={12}>
                                        <Typography variant={"h6"} align={"center"}>Verfügbare
                                            Geräte</Typography>
                                        <GlobalStyledDivider/>
                                    </Grid>
                                    <Grid xs={12}>
                                        {getDnDListe(assignableEquipment, (newList => {
                                            setAssignableEquipment(newList)
                                        }), "AssignableComponents")}
                                    </Grid>
                                </ResourceStyledMachinePaper>
                            </Grid>

                        </DnDContextWrapper>}
                </Grid> : <div/>}
                {(openAppointment) ? <AppointmentMask open={openAppointment}
                                                      editEnabled={true}
                                                      resource={localEquipment}
                                                      resourceType={ResourceType.EQUIPMENT}
                                                      updateCallback={(termin) => {
                                                          setOpenAppointment(false);
                                                      }}
                                                      deleteCallback={() => setOpenAppointment(false)}
                                                      closeCallback={() => setOpenAppointment(false)}/> : ""}
            </Grid> : <div/>
    )
}

// @ts-ignore
export default EquipmentMask;
