import React from "react";
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { useSnackbar, VariantType, withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

interface DnDItem {
  typeDroppable: string;
  referencedList: any[];
  updateListCallback: (newState: any[]) => void;
}

interface DnDWrapperType {
  registeredItems: Map<string, DnDItem>;
  children: React.ReactNode;
}

const createRegisterCallBack = (
  state: Map<string, DnDItem>,
  setDnDRegisterState: (stateDnD: Map<string, DnDItem>) => void
) => {
  return (key: string, newDnDItem: DnDItem) => {
    state.set(key, newDnDItem);
    setDnDRegisterState(state);
    console.log(key, newDnDItem, "registerd for DnD");
  };
};

const DnDContextWrapper = function (props: DnDWrapperType) {
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const showSnackbar = (variant: VariantType, message: string) => {
    enqueueSnackbar(message, { variant });
  };

  const handleDND = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      showSnackbar("warning", t("dndNoValidTarget"));
      return;
    }

    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index == result.source.index
    ) {
      return;
    }

    const sourceItem = props.registeredItems.get(result.source.droppableId);
    const destinationItem = props.registeredItems.get(
      result.destination.droppableId
    );

    if (sourceItem && destinationItem) {
      if (sourceItem.typeDroppable === destinationItem.typeDroppable) {
        //if we DnD between different
        let srcArray = [...sourceItem.referencedList];
        let destArray = [...destinationItem.referencedList];
        let dndEntity = srcArray[result.source.index];
        if (result.destination.droppableId !== result.source.droppableId) {
          srcArray.splice(result.source.index, 1);
          destArray.splice(result.destination.index, 0, dndEntity);
        } else {
          destArray.splice(result.source.index, 1);
          destArray.splice(result.destination.index, 0, dndEntity);
        }
        //now we save
        //if the id's are different we need to save both source and dest
        if (result.destination.droppableId !== result.source.droppableId) {
          sourceItem.updateListCallback(srcArray);
          destinationItem.updateListCallback(destArray);
        } else {
          destinationItem.updateListCallback(destArray);
        }
      } else {
        showSnackbar("error", "Drag & Drop ist in diesem Fall nicht erlaubt.");
      }
    }
  };

  return (
    <DragDropContext onDragEnd={handleDND}>{props.children}</DragDropContext>
  );
};

// @ts-ignore
export default withSnackbar(DnDContextWrapper);
export { createRegisterCallBack };

export type { DnDWrapperType, DnDItem };
