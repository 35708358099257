import React, {useEffect, useState} from "react";
import ResponsiveRadarChart, {DataDescriptor} from "./Helper/ResponsiveRadarChart";
import {getEmployee} from "../../model/ModelController/Resources/EmployeeController";
import {getVehicle} from "../../model/ModelController/Resources/VehicleController";
import {getEquipment} from "../../model/ModelController/Resources/EquipmentController";
import {getCompanyDetails} from "../../model/ModelController/CompanyDetails";
import {useTranslation} from "react-i18next";
import useTheme from "@mui/material/styles/useTheme";

function LicenseChart() {
    const theme = useTheme();
    const [currentCounts, setCurrentCounts] = useState({employeeCount: 0, vehicleCount: 0, equipmentCount: 0})
    const [allowedCount, setAllowedCounts] = useState({employeeCount: 0, vehicleCount: 0, equipmentCount: 0})
    const {t} = useTranslation();


    useEffect(() => {
        const fetchData = async () => {
            let employees = await getEmployee();
            let vehicles = await getVehicle();
            let equipment = await getEquipment();
            let companyDetails = await getCompanyDetails();

            setCurrentCounts({
                employeeCount: employees.length,
                vehicleCount: vehicles.length,
                equipmentCount: equipment.length
            })

            if (companyDetails) {
                setAllowedCounts({
                    employeeCount: companyDetails.employees,
                    vehicleCount: companyDetails.vehicles,
                    equipmentCount: companyDetails.equipment
                })
            }
        }
        fetchData()
    }, [])


    const Data: DataDescriptor[] = [
        {
            dataSubject: t("employee.title"),
            currentUsage: currentCounts.employeeCount,
            licensedUsage: allowedCount.employeeCount
        },
        {
            dataSubject: t("vehicle.title"),
            currentUsage: currentCounts.vehicleCount,
            licensedUsage: allowedCount.vehicleCount
        },
        {
            dataSubject: t("equipment.title"),
            currentUsage: currentCounts.equipmentCount,
            licensedUsage: allowedCount.equipmentCount
        },
    ]

    return (
        <ResponsiveRadarChart title={t("dashboard.licensing")} data={Data} classes={[

            {
                dataKey: "licensedUsage",
                dataName: t("dashboard.licensedUsage"),
                fill: theme.palette.info.main,
                stroke: theme.palette.info.main,
                opacity: 0.4
            },
            {
                dataKey: "currentUsage",
                dataName: t("dashboard.currentUsage"),
                fill: theme.palette.warning.main,
                stroke: theme.palette.warning.main,
                opacity: 0.8
            }
        ]}/>
    )
}

export default LicenseChart;
