import {Add} from "@mui/icons-material";
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from "@mui/material";
import {t} from "i18next";
import React, {useState} from "react";
import {CustomerContactModel} from "../../../../../model/types/basistypes/ressources/CustomerModel";
import CustomerContactEntry from "./CustomerContactEntry";
import GenericActionBar from "../../../../ActionBar/GenericActionBar";

interface propsType {
	contacts: CustomerContactModel[];
	updateContacts: (contacts: CustomerContactModel[]) => void;
	customerId: number
}

const CustomerContactMask = (props: propsType) => {
	const [tablePage, setTablePage] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(5);
	const handleChangePage = (event: any, newPage: number) => {
		setTablePage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setTablePage(0);
	};

	const getEmptyContact = () => {
		return {
			id: Date.now(),
			email: "",
			position: "",
			telNr: "",
			mobile: "",
			name: "Max Mustermann"
		} as CustomerContactModel;
	};

	const actions = [{
		icon: <Add/>,
		name: "Add",
		tooltip: t("actions.contact"),
		callback: () => props.updateContacts([...props.contacts, getEmptyContact()]),
	}];

	return (
		<Grid item xs={12}>
			<Grid item container xs={12} justifyContent={"flex-start"}>
				<Grid item xs={1}>
					<GenericActionBar actions={actions}/>
				</Grid>
			</Grid>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow sx={{borderBottom: "2px solid", borderBottomColor: "divider"}}>
							<TableCell sx={{borderBottom: "2px solid", borderBottomColor: "divider"}}>
								<Typography variant={"h6"} align={"center"}>
									{t("customer.contact.action")}
								</Typography>
							</TableCell>
							<TableCell sx={{borderBottom: "2px solid", borderBottomColor: "divider"}}>
								<Typography variant={"h6"} align={"center"}>
									{t("customer.contact.name")}
								</Typography>
							</TableCell>
							<TableCell sx={{borderBottom: "2px solid", borderBottomColor: "divider"}}>
								<Typography variant={"h6"} align={"center"}>
									{t("customer.contact.position")}
								</Typography>
							</TableCell>
							<TableCell sx={{borderBottom: "2px solid", borderBottomColor: "divider"}}>
								<Typography variant={"h6"} align={"center"}>
									{t("customer.contact.email")}
								</Typography>
							</TableCell>
							<TableCell sx={{borderBottom: "2px solid", borderBottomColor: "divider"}}>
								<Typography variant={"h6"} align={"center"}>
									{t("customer.contact.mobile")}
								</Typography>
							</TableCell>
							<TableCell sx={{ borderBottom: "2px solid", borderBottomColor: "divider" }}>
								<Typography variant={"h6"} align={"center"}>
									{t("customer.contact.telNr")}
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.contacts.slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage).map((contact, index) => {
							return (
								<CustomerContactEntry
									customerId={props.customerId}
									contact={contact}
									delete={() => {
										let copy = [...props.contacts];
										copy.splice(tablePage * rowsPerPage + index, 1);
										props.updateContacts(copy);
									}}
									updateCustomerContact={(newContact) => {
										let copy = [...props.contacts];
										copy[tablePage * rowsPerPage + index] = newContact;
										props.updateContacts(copy);
									}}
								/>
							);
						})}
					</TableBody>
				</Table>
				<TablePagination
					sx={{borderBottom: "2px solid", borderBottomColor: "divider"}}
					rowsPerPageOptions={[5, 15, 50, 100]}
					count={props.contacts.length}
					rowsPerPage={rowsPerPage}
					page={tablePage}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</TableContainer>
		</Grid>
	);
};

export default CustomerContactMask;
