import EmployeeModel from "../../types/basistypes/ressources/EmployeeModel";
import {getFirebase, getUser} from "../FirebaseWrapper";
import getCompanyAffiliation from "../CompanyAffiliation";
import firebase from "firebase";
import DayPlanModel from "../../types/DayPlanModel";
import {purgeUiOnlyFields, resolveNestedDocumentList, transformArrayToReference} from "../FirebaseConverter";
import {getManagementRoles} from "../Permissions/RoleController";
import Role from "../../types/permissions/Role";
import UserModel from "../../types/permissions/UserModel";


const getEmployeeDocumentId = async (Id: number) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId) {
        return dbConnection
            .collection(companyId)
            .doc("Employee")
            .collection("Employee")
            .doc(Id.toString())
    }
}


const resolveEmployee = async (doc: any) => {
    let employee = doc.data() as EmployeeModel
    let array: firebase.firestore.DocumentReference[] = []
    if (typeof employee.drivingLicenseClasses === typeof array) {
        employee.drivingLicenseClasses = await resolveNestedDocumentList(employee.drivingLicenseClasses);
    }

    //employee.role = await (await resolveNestedDocumentList([employee.role]))[0]
    return employee as EmployeeModel;
}


const addEmployee = async (employee: EmployeeModel) => {
    employee = purgeUiOnlyFields(employee);
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        //const mappedRoles = await transformArrayToReference("Roles", [employee.role]);
        dbConnection
            .collection(companyId)
            .doc("Employee")
            .collection("Employee")
            .doc(employee.id.toString())
            .set({
                ...employee,
                drivingLicenseClasses: await transformArrayToReference(firebase, dbConnection, companyId, "DrivingLicenseClass", employee.drivingLicenseClasses),
                //role: mappedRoles[0],
                usable: true
            });
    }
}

const deleteEmployee = async (employee: EmployeeModel) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        dbConnection.collection(companyId)
            .doc("Employee")
            .collection("Employee")
            .doc(employee.id.toString())
            .update({usable: false});
    }
}

const getConstructionManager = async () => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {

        let assignableAsManager: Role[] = await getManagementRoles();
        //if there are no Roles defined, we cannot perform the query
        if (assignableAsManager.length === 0)
            return []

        let roleReference = await transformArrayToReference(firebase, dbConnection, companyId, "Roles", assignableAsManager)
        let promiseArray: Promise<firebase.firestore.QuerySnapshot>[] = [];
        //We do this because firebase in and contains-any querys can only
        //contain 10 elements at max
        while (roleReference.length > 0) {
            let references = roleReference.splice(0, 10);
            promiseArray.push(dbConnection
                .collection(companyId)
                .doc("Users")
                .collection("Users")
                .where('role', "in", references)
                .where("usable", "==", true).get())

        }

        let queryResults = await Promise.all(promiseArray);

        let result: EmployeeModel[] = [];
        for (let query of queryResults) {
            for (let doc of query.docs) {
                let copy = doc.data() as UserModel;
                let employee = (await resolveNestedDocumentList([copy.employee]))[0] as EmployeeModel;
                result.push(employee)
            }
        }
        return result;
    }
}


const getEmployeeForDayPlan = async (dayPlan: DayPlanModel) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        let employeeIds: number[] = [];
        dayPlan.teams.forEach((team, index) => {
            team.employees.forEach((employee, index) => {
                employeeIds.push(employee.id)
            })
        })
        
        /* We cannot use "not-in" because it's limited to 10 elements
         * since we cannot make a union of not-ins( would result in all documents)
         * we need to fetch all Employees and filter afterwards.
         */
        let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
            .collection(companyId)
            .doc("Employee")
            .collection("Employee")
            .where("usable", "==", true).get()
        let result: EmployeeModel[] = [];
        let promiseArray: Promise<EmployeeModel>[] = [];
        //Minor performance boost through parallelization
        promiseArray = query.docs
            .filter(doc => !employeeIds.find(id => id === doc.data().id))
            .map((value) => {
                return resolveEmployee(value);
            })
        
        result = await Promise.all(promiseArray);
        return result;
    }
    return []
}

const getEmployee = async () => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
            .collection(companyId)
            .doc("Employee")
            .collection("Employee")
            .where("usable", "==", true)
            .get()
        let result: EmployeeModel[] = [];
        for (let doc of query.docs) {
            result.push(await resolveEmployee(doc))
        }
        return result;
    }
    return []
}

const getEmployeeFromLoggedInUserIfApplicableOfBeingAssignedAsConstructionManager = async (): Promise<EmployeeModel | undefined> => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();
    const user = await getUser();
    let companyId = await getCompanyAffiliation();

    if (companyId !== undefined) {
        let userDocument: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> = await dbConnection
            .collection(companyId)
            .doc("Users")
            .collection("Users")
            .doc(user?.uid)
            .get()

        let internalUser: UserModel = userDocument.data() as UserModel;
        //If there exists any internal user
        if (internalUser) {
            const role: Role = (await resolveNestedDocumentList([internalUser.role]))[0] as Role;

            //If the internal user has a role which allows him to be a construction manager
            if (role.assignableAsResponsible) {
                return (await resolveNestedDocumentList([internalUser.employee]))[0] as EmployeeModel;

            }
        }

        return undefined;
    }
}


export {
    addEmployee,
    getEmployee,
    deleteEmployee,
    getConstructionManager,
    getEmployeeForDayPlan,
    getEmployeeDocumentId,
    getEmployeeFromLoggedInUserIfApplicableOfBeingAssignedAsConstructionManager
}
