import {AppointmentModel, ResourceType} from "../../../model/types/basistypes/times/AppointmentModel";
import {localDateToUTCTimestamp} from "../../../utility/dateUtil";
import {getResourceType} from "../AppointmentCalendar";

export const removeDuplicates = (id: string | null, selectedResourceType: string | null, employeeAppointmentList: AppointmentModel[], vehicleAppointmentList: AppointmentModel[], equipmentAppointmentList: AppointmentModel[]) => {
    let distinctList: any = [];

    switch (selectedResourceType) {

        case getResourceType[0] :
            if (id === "selected_appointments") {
                distinctList = Array.from(new Set(employeeAppointmentList.map(value => value.appointmentType?.description)));
            } else if (id === "selected_resources") {
                distinctList = Array.from(new Set(employeeAppointmentList.map(value => value.resource.name)));
            }
            return distinctList;

        case getResourceType[1]:
            if (id === "selected_appointments") {
                distinctList = Array.from(new Set(vehicleAppointmentList.map(value => value.appointmentType?.description)));
            } else if (id === "selected_resources") {
                distinctList = Array.from(new Set(vehicleAppointmentList.map(value => value.resource.licensePlace)));
            }
            return distinctList;
        case getResourceType[2]:
            if (id === "selected_appointments") {
                distinctList = Array.from(new Set(equipmentAppointmentList.map(value => value.appointmentType?.description)));
            } else if (id === "selected_resources") {
                distinctList = Array.from(new Set(equipmentAppointmentList.map(value => value.resource.type)));
            }
            return distinctList;
        default:
            return [];
    }

}


export const getFilteredEvents = (selectedResourceType: string | null, selectedResources: any, selectedAppointments: any, employeeAppointmentList: AppointmentModel[], vehicleAppointmentList: AppointmentModel[], equipmentAppointmentList: AppointmentModel[]) => {
    let resourceType = employeeAppointmentList.map(item => item).concat(vehicleAppointmentList.map(item => item)).concat(equipmentAppointmentList.map(item => item))
    let resourceTypeEvents: any = []
    switch (selectedResourceType) {
        case getResourceType[0]:
            resourceType = employeeAppointmentList.filter(item => item.resourceType === ResourceType.EMPLOYEE)
            if (selectedResources.length) {
                resourceType = resourceType.filter(item => selectedResources.includes(item.resource.name))
            }
            if (selectedAppointments.length) {
                resourceType = resourceType.filter(item => selectedAppointments.includes(item.appointmentType?.description))
            }
            break;
        case getResourceType[1]:
            resourceType = vehicleAppointmentList.filter(item => item.resourceType === ResourceType.VEHICLE)
            if (selectedResources.length) {
                resourceType = resourceType.filter(item => selectedResources.includes(item.resource.licensePlace))
            }
            if (selectedAppointments.length) {
                resourceType = resourceType.filter(item => selectedAppointments.includes(item.appointmentType?.description))
            }
            break;
        case getResourceType[2]:
            resourceType = equipmentAppointmentList.filter(item => item.resourceType === ResourceType.EQUIPMENT)
            if (selectedResources.length) {
                resourceType = resourceType.filter(item => selectedResources.includes(item.resource.type))
            }
            if (selectedAppointments.length) {
                resourceType = resourceType.filter(item => selectedAppointments.includes(item.appointmentType?.description))
            }

            break;
        default:
            break;
    }
    resourceTypeEvents = resourceType.map((value: any) => {
            let date = new Date(localDateToUTCTimestamp(value.date))
            let year = date.getFullYear()
            let month = ((date.getMonth() + 1) > 9) ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1)
            let day = (date.getDate() > 9) ? date.getDate() : "0" + date.getDate()
            if (value.resource.surname) {
                return {
                    title: value.resource.surname + ", " + value.resource.name + " " + value.appointmentType?.description,
                    start: year + "-" + month + "-" + day,
                    backgroundColor: "#6c2121",
                    borderColor: "transparent",
                    id: value.id.toString()
                }
            } else if (value.resource.licensePlace) {
                return {
                    title: value.resource.licensePlace + " " + value.appointmentType?.description,
                    start: year + "-" + month + "-" + day,
                    backgroundColor: "#125675",
                    borderColor: "transparent",
                    id: value.id.toString()
                }
            } else if (value.resource.type) {
                return {
                    title: value.resource.producer + "[" + value.resource.type + "] " + value.appointmentType?.description,
                    start: year + "-" + month + "-" + day,
                    backgroundColor: "#1f3f05",
                    borderColor: "transparent",
                    id: value.id.toString()
                }
            }
        }
    )
    return resourceTypeEvents
}
