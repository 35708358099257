import {getFirebase} from "../FirebaseWrapper";
import getCompanyAffiliation from "../CompanyAffiliation";
import firebase from "firebase";
import ConstructionSiteModel from "../../types/basistypes/ressources/ConstructionSiteModel";
import {purgeUiOnlyFields, resolveNestedDocumentList, transformArrayToReference} from "../FirebaseConverter";
import {getCustomerDocumentId} from "./CustomerController";
import CustomerModel from "../../types/basistypes/ressources/CustomerModel";

const resolveConstructionSite = async (doc: any) => {
	let constructionSite = doc.data() as ConstructionSiteModel;

	if (constructionSite) {
		let array: firebase.firestore.DocumentReference[] = [];
		if (typeof constructionSite.constructionManagers === typeof array) {
			constructionSite.constructionManagers = await resolveNestedDocumentList(constructionSite.constructionManagers);
		}

		if (constructionSite.customer) {
			let customer = await constructionSite.customer.get();
			constructionSite.customer = customer.data() as CustomerModel;
		}

		//employee.role = await (await resolveNestedDocumentList([employee.role]))[0]
		return constructionSite as ConstructionSiteModel;
	}

	return {} as ConstructionSiteModel;
};

const addConstructionSite = async (constructionSite: ConstructionSiteModel) => {
	constructionSite = purgeUiOnlyFields(constructionSite);
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();
	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		dbConnection
			.collection(companyId)
			.doc("ConstructionSite")
			.collection("ConstructionSite")
			.doc(constructionSite.id.toString())
			.set({
				...constructionSite,
				constructionManagers: await transformArrayToReference(firebase, dbConnection, companyId, "Employee", constructionSite.constructionManagers),
				customer: constructionSite.customer ? await getCustomerDocumentId(constructionSite.customer.id) : null,
				usable: true,
			});
	}
};

const getConstructionSiteDocument = async (id: number) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId) {
		return dbConnection.collection(companyId).doc("ConstructionSite").collection("ConstructionSite").doc(id.toString());
	}
};

const deleteConstructionSite = async (constructionSite: ConstructionSiteModel) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		dbConnection.collection(companyId).doc("ConstructionSite").collection("ConstructionSite").doc(constructionSite.id.toString()).update({ usable: false });
	}
};

const getConstructionSiteAsOfDate = async (date: number) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
			.collection(companyId)
			.doc("ConstructionSite")
			.collection("ConstructionSite")
			.where("validTo", ">=", date)
			.where("usable", "==", true)
			.get();

		let result: Promise<ConstructionSiteModel>[] = []
		for (let doc of query.docs) {
			const model = doc.data() as ConstructionSiteModel;

			//this check has to be done on the client-side
			//as firebase doesn't allow for compount queries
			if (model.validFrom <= date) {
				result.push(resolveConstructionSite(doc));
			}
		}

		return (await Promise.all(result));
	}
};

const getConstructionSite = async () => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
			.collection(companyId)
			.doc("ConstructionSite")
			.collection("ConstructionSite")
			.where("usable", "==", true)
			.get();
		let result: ConstructionSiteModel[] = [];

		for (let doc of query.docs) {
			result.push(await resolveConstructionSite(doc));
		}

		return result;
	}
	return [];
};

export { addConstructionSite, getConstructionSite, deleteConstructionSite, getConstructionSiteAsOfDate, getConstructionSiteDocument, resolveConstructionSite };
