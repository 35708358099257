import EquipmentModel from "../../types/basistypes/ressources/EquipmentModel";
import {getFirebase} from "../FirebaseWrapper";
import getCompanyAffiliation from "../CompanyAffiliation";
import firebase from "firebase";
import DayPlanModel from "../../types/DayPlanModel";
import {purgeUiOnlyFields, resolveNestedDocumentList, transformArrayToReference} from "../FirebaseConverter";
import {registerGenericCollectionListener} from "../GenericFirebaseSnapshotListener";

const resolveEquipment = async (doc: any) => {
    let equipment: EquipmentModel = doc.data()
    let array: firebase.firestore.DocumentReference[] = []
    if (typeof equipment.components === typeof array) {
        equipment.components = await resolveNestedDocumentList(equipment.components);
    }
    return equipment as EquipmentModel;
}


let checkCircleRelation = (id: number, equipment: EquipmentModel[]) => {
    for (let eq of equipment) {
        if (id === eq.id) {
            return true as boolean;
        }
        for (let component of eq.components) {
            //first check if the component id matches our id
            //if not check for it's subcomponents.
            if (component.id === id)
                return true;

            let hasCircleRelation: boolean = checkCircleRelation(id, component.components) as boolean
            if (hasCircleRelation) {
                return hasCircleRelation as boolean;
            }
        }
    }
    return false as boolean;
}
const getEquipmentThatIsNotAssignedToAGroup = async (referenceId: number) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();
    let result: EquipmentModel[] = [];

    let companyId = await getCompanyAffiliation();

    if (companyId !== undefined) {
        let query = await dbConnection
            .collection(companyId)
            .doc("Equipment")
            .collection("Equipment")
            .where("hasComponents", "==", true)
            .where("usable", "==", true)
        
            .get()
        let componentsId: number[] = [];
        for (let doc of query.docs) {
            let equipmentDoc = await resolveEquipment(doc);
            if (equipmentDoc.id === referenceId) {
                componentsId.push(equipmentDoc.id);
            }

            let hasCircleRelation = checkCircleRelation(referenceId, equipmentDoc.components)

            if (hasCircleRelation) {
                componentsId.push(equipmentDoc.id)
            }
            for (let component of equipmentDoc.components) {
                componentsId.push(component.id);
            }
        }

        /* We cannot use "not-in" because it's limited to 10 elements
        * since we cannot make a union of not-ins( would result in all documents)
        * we need to fetch all Equipments and filter afterwards.
        */
        let queryAssignableComponents = await dbConnection
            .collection(companyId)
            .doc("Equipment")
            .collection("Equipment")
            .where("usable", "==", true).get()

        let promiseArray: Promise<EquipmentModel>[] = queryAssignableComponents.docs.map(
            async (doc) => {
                return await resolveEquipment(doc)
            })
        result = (await Promise.all(promiseArray)).filter(equipment => !componentsId.find(id => id === equipment.id));

    }
    return result;
}


const addEquipment = async (equipment: EquipmentModel) => {
    equipment = purgeUiOnlyFields(equipment);
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();
    //if a HasComponents changes we clean up the references
    equipment.components = (equipment.hasComponents) ? equipment.components : [];

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        dbConnection
            .collection(companyId)
            .doc("Equipment")
            .collection("Equipment")
            .doc(equipment.id.toString())
            .set({
                ...equipment,
                components: await transformArrayToReference(firebase, dbConnection, companyId, "Equipment", equipment.components),
                usable: true
            });
    }
}

const deleteEquipment = async (equipment: EquipmentModel) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        dbConnection
            .collection(companyId)
            .doc("Equipment")
            .collection("Equipment")
            .doc(equipment.id.toString())
            .update({usable: false});
    }
}

const getEquipmentForDayPlan = async (dayPlan: DayPlanModel) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        let equipmentIds: number[] = [];
        dayPlan.teams.forEach((team, index) => {
            team.equipment.forEach((machine, index) => {
                equipmentIds.push(machine.id)
            })
        })
        let queryCompositeObjects = await dbConnection
            .collection(companyId)
            .doc("Equipment")
            .collection("Equipment")
            .where("hasComponents", "==", true)
            .get()
        //also exclude all Machines that are components of another machine
        //We pulled a sneaky here. We paralellize the creation of seperate number[] arrays
        //containing the ids and then concatenate them. Such beautiful code :)
        let compositeObjectPromises = queryCompositeObjects.docs.map(
            (doc) => {
                return resolveEquipment(doc).then(machineDoc => {
                    return machineDoc.components.map(component => component.id)
                })
            }
        )
        equipmentIds = equipmentIds.concat(...(await Promise.all(compositeObjectPromises)))
        /* We cannot use "not-in" because it's limited to 10 elements
        * since we cannot make a union of not-ins( would result in all documents)
        * we need to fetch all Equipments and filter afterwards.
        */
        let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
            .collection(companyId)
            .doc("Equipment")
            .collection("Equipment")
            .where("usable", "==", true).get()

        let promiseArray: Promise<EquipmentModel>[] = query.docs
            .filter(doc => !equipmentIds.find(id => id === doc.data().id))
            .map((doc) => {
                return resolveEquipment(doc)
            })
        return (await Promise.all(promiseArray))

    }
    return []
}

const registerUnassignedEquipmentListener = async (updateFunction: (objectsToAdd: EquipmentModel[], objectsToModify: EquipmentModel[], objectsToDelete: EquipmentModel[], assignedComponentsId: number[]) => void) => {


    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {


        let query: firebase.firestore.Query = dbConnection
            .collection(companyId)
            .doc("Equipment")
            .collection("Equipment")
            .where("usable", "==", true)


        let unsubscribe = await registerGenericCollectionListener<EquipmentModel>(query, (objectsToAdd, objectsToModify, objectsToDelete) => {
            const update = async () => {

                if (companyId) {
                    let queryCompositeObjects = await dbConnection
                        .collection(companyId)
                        .doc("Equipment")
                        .collection("Equipment")
                        .where("hasComponents", "==", true)
                        .get()
                    //also exclude all Machines that are components of another machine
                    //We pulled a sneaky here. We paralellize the creation of seperate number[] arrays
                    //containing the ids and then concatenate them. Such beautiful code :)
                    let compositeObjectPromises = queryCompositeObjects.docs.map(
                        async (doc) => {
                            let machineDoc = await resolveEquipment(doc);
                            return machineDoc.components.map(component => component.id)
                        }
                    )
                    let equipmentIds: number[] = [];
                    equipmentIds = equipmentIds.concat(...(await Promise.all(compositeObjectPromises)))
                    /* We cannot use "not-in" because it's limited to 10 elements
                    * since we cannot make a union of not-ins( would result in all documents)
                    * we need to fetch all Equipments and filter afterwards.
                    */

                    updateFunction(objectsToAdd, objectsToModify, objectsToDelete, equipmentIds)
                }
            }
            update()
        }, resolveEquipment, "UnassignedEquipmentsListener")


        return unsubscribe;
    }
    return () => {
    };

}


const getEquipment = async () => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
            .collection(companyId)
            .doc("Equipment")
            .collection("Equipment")
            .where("usable", "==", true)
            .get()
        let result: EquipmentModel[] = [];
        for (let doc of query.docs) {
            result.push(await resolveEquipment(doc))
        }
        return result;
    }
    return []
}


const getEquipmentDocumentId = async (id: number) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId) {
        return dbConnection
            .collection(companyId)
            .doc("Equipment")
            .collection("Equipment")
            .doc(id.toString())
    }
}

export {
    addEquipment,
    getEquipment,
    deleteEquipment,
    getEquipmentForDayPlan,
    getEquipmentThatIsNotAssignedToAGroup,
    getEquipmentDocumentId,
    registerUnassignedEquipmentListener
}

