export function getCurrentDateAsUTCTimestamp(): number {
    return new Date(Date.now()).setUTCHours(0, 0, 0, 0);
}

export function getCurrentDateAtStartAsUTCTimestamp(): number {
    return new Date(Date.now()).setUTCHours(0, 0, 0, 0);
}

export function getCurrentDateAtEndAsUTCTimestamp(): number {
    return new Date(Date.now()).setUTCHours(23, 59, 59, 0);
}

export function localDateToUTCTimestamp(date: string | number | Date): number {
    return new Date(date).setUTCHours(0, 0, 0, 0);
}


export function localDateToUTCTimestampStart(date: string | number | Date): number {
    return new Date(date).setUTCHours(0, 0, 0, 0);
}

export function localDateToUTCTimestampEnd(date: string | number | Date): number {
    return new Date(date).setUTCHours(23, 59, 59, 0);
}

export function checkDateInFuture(date: number): boolean {
    return date >= getCurrentDateAsUTCTimestamp();
}

export function checkDateInPast(date: number): boolean {
    return date <= getCurrentDateAsUTCTimestamp();
}

export function utcTimestampToFormattedString(utcTimestamp: number | string): string {
    let date = new Date(utcTimestamp)
    let day = date.getUTCDate()
    let month = date.getUTCMonth() + 1

    let dayString = (day < 10) ? "0" + day.toString() : day.toString();
    let monthString = (month < 10) ? "0" + month.toString() : month.toString();

    return `${dayString}.${monthString}.${date.getUTCFullYear().toString()}`;
}

export function utcTimestampToFormattedISOString(utcTimestamp: number | string): string {
    let date = new Date(utcTimestamp)
    let day = date.getUTCDate()
    let month = date.getUTCMonth() + 1

    let dayString = (day < 10) ? "0" + day.toString() : day.toString();
    let monthString = (month < 10) ? "0" + month.toString() : month.toString();

    return date.getUTCFullYear().toString() + "-" + monthString + "-" + dayString;
}