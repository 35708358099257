import React, {useContext, useState} from "react";
import VehicleModel from "../../../../model/types/basistypes/ressources/VehicleModel";
import VehicleMask from "./VehicleMask";
import {getVehicle} from "../../../../model/ModelController/Resources/VehicleController";
import {useSnackbar, VariantType, withSnackbar} from "notistack";
import {AppBarInfoAreaContext} from "../../../Sidebar/AppBarInfoAreaContext";
import ResourceLayout from "../Helper/ResourcesLayout";
import ResourceList from "../Helper/ResourceList";
import {useTranslation} from "react-i18next";
import {Prompt} from "react-router-dom";

function VehicleOverview(this: any) {
    const appbarContext = useContext(AppBarInfoAreaContext);
    const [registered, setRegistered] = useState(false)
    const [isDirtyIndex, setIsDirtyIndex] = useState(-1)
    const [vehicleList, setVehicleList] = useState<VehicleModel[]>([])
    const [selectedVehicle, setSelectedVehicle] = useState<number>(0)
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();

    appbarContext.updateContent(t("vehicle.manageTitle"));

    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }

    const updateCallback = (index: number) => {
        return (update: VehicleModel) => {
            update = {...update, _isNew: false}
            let newState: VehicleModel[] = [...vehicleList]
            newState[index] = update;
            setVehicleList(newState);
            setIsDirtyIndex(-1);
            showSnackbar("success", t("vehicle.successfullyCreated", {
                licensePlate: update.licensePlace
            }))
        }
    }

    const deleteCallback = (index: number) => {
        return (update: VehicleModel) => {
            let newState: VehicleModel[] = vehicleList.filter(
                ((value, index1) => {
                    return index1 !== index
                })
            )
            setVehicleList(newState);
            setSelectedVehicle(0);
            setIsDirtyIndex(-1);
            showSnackbar("success", t("vehicle.successfullyDeleted", {
                licensePlate: update.licensePlace
            }))
        }
    }

    if (!registered) {
        getVehicle().then(
            (result: VehicleModel[] | undefined) => {
                if (result !== undefined)
                    setVehicleList(result)
            }
        );
        setRegistered(true)
    }


    const getEmptyVehicle = (): VehicleModel => {
        return {
            id: Date.now(),
            producer: t("vehicle.placeholder.producer"),
            drivingLicenseClasses: [],
            vehicleDescription: "",
            nickname: "",
            licensePlace: t("vehicle.placeholder.licensePlate"),
            _isNew: true
        };
    }

    const addVehicle = () => {
        if (discardChangesInputWindowConfirmDialog()) {
            let newVehicleList = [...vehicleList]
            if (vehicleList[selectedVehicle]?._isNew) {
                newVehicleList.splice(selectedVehicle, 1)
            }

            if (vehicleList === undefined) {
                let newState: VehicleModel[] = [getEmptyVehicle()];
                setVehicleList(newState)
                setSelectedVehicle(newState.length - 1)
                setIsDirtyIndex(-1);
            } else {
                newVehicleList.push(getEmptyVehicle());
                setVehicleList(newVehicleList)
                setSelectedVehicle(newVehicleList.length - 1)
                setIsDirtyIndex(-1);
            }
        }
    }

    const setIndex = (resource: any, index: number) => {
        if (selectedVehicle === index) {
            return;
        }
        if (discardChangesInputWindowConfirmDialog()) {
            if (vehicleList[selectedVehicle]?._isNew) {
                let newVehicleList = [...vehicleList];
                newVehicleList.splice(selectedVehicle, 1)
                setSelectedVehicle(index);
                setVehicleList(newVehicleList)
            }
            setSelectedVehicle(index);
            setIsDirtyIndex(-1);
        } else {
            //do nothing -> stay on old selected resource
        }
    }

    const discardChangesInputWindowConfirmDialog = () => {
        if (isDirtyIndex !== -1 || vehicleList[selectedVehicle]?._isNew) {
            return window.confirm(t("discardChangesDialog.text"));
        } else {
            return true;
        }
    }


    return (
        <>
            <Prompt
                when={isDirtyIndex !== -1 || vehicleList[selectedVehicle]?._isNew === true}
                message={t("discardChangesDialog.leaveOverview")}
            />
            <ResourceLayout
                left={
                    <ResourceList resources={vehicleList}
                                  selectedItem={selectedVehicle}
                                  getListItemText={(vehicle: VehicleModel) => {
                                      return vehicle.producer + " " + vehicle.licensePlace + ((vehicle._isNew || vehicleList[isDirtyIndex]?.id === vehicle.id) ? "*" : "")
                                  }}
                                  resourceClickCallBack={setIndex}
                                  addItemCallback={addVehicle}/>
                }
                right={
                    (vehicleList.length > 0) ?
                        <VehicleMask editEnabled={true} vehicle={vehicleList[selectedVehicle]}
                                     updateCallback={updateCallback(selectedVehicle)}
                                     deleteCallback={deleteCallback(selectedVehicle)}
                                     isDirtyCallback={() => setIsDirtyIndex(selectedVehicle)}
                                     asDialog={false}
                                     closeCallback={() => {
                                     }}/>
                        : <div/>


                }/>
        </>
    )
}

export default withSnackbar(VehicleOverview)

