import React from "react";
import {List, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material"
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";

interface propsType {
    item: any,
    hideAll: () => void,
}

const SubMenu = (props: propsType) => {
    const {t} = useTranslation();
    return (
        props.item.subNav.map((item: any, index: number) => {
            return (
                <List key={index} sx={{pl: "3vh"}} disablePadding>
                    <ListItemButton
                        component={Link} to={item.path}
                        onClick={() => {
                            props.hideAll()
                        }}>
                        < ListItemIcon> {item.icon}</ListItemIcon>
                        <ListItemText>
                            <Typography variant={"h6"}>{t(item.title)}</Typography>
                        </ListItemText>
                    </ListItemButton>
                </List>
            )
        }))
};
export default SubMenu;

