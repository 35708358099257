import EmployeeModel from "../model/types/basistypes/ressources/EmployeeModel";
import VehicleModel from "../model/types/basistypes/ressources/VehicleModel";
import EquipmentModel from "../model/types/basistypes/ressources/EquipmentModel";

export function isEmployeeModel(resource: EmployeeModel | VehicleModel | EquipmentModel): resource is EmployeeModel {
    return (resource as EmployeeModel).surname !== undefined;
}

export function isVehicleModel(resource: EmployeeModel | VehicleModel | EquipmentModel): resource is VehicleModel {
    return (resource as VehicleModel).licensePlace !== undefined;
}

export function isEquipmentModel(resource: EmployeeModel | VehicleModel | EquipmentModel): resource is EquipmentModel {
    return (resource as EquipmentModel).producer !== undefined;
}

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export function groupBy(list: any, keyGetter: any) {
    const map = new Map();
    list.forEach((item: any) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}