import {Grid} from "@mui/material";
import {CustomerAddressModel} from "../../../../../model/types/basistypes/ressources/CustomerModel";
import {GlobalStyledTextField} from "../../../../StyledComponents/GlobalStyled/GlobalStyled";
import useValidator, {proxiedPropertiesOf} from "../../../../ValidatorHook";
import {useTranslation} from "react-i18next";
import React from "react";
import CustomerAddressValidator from "../CustomerAddressValidator";

interface propsType {
	customerAddress: CustomerAddressModel;
	updateAddress: (address: CustomerAddressModel) => void;
	customerId: number;
}

const CustomerAddressMask = (props: propsType) => {
	let addressFieldNames = proxiedPropertiesOf(props.customerAddress);
	let customerAddressFieldNames = proxiedPropertiesOf(props.customerAddress);
	let customerAddressValidator = useValidator(CustomerAddressValidator, props.customerAddress, props.customerId);
	const {t} = useTranslation();

	return (
		<Grid item container alignItems={"center"} direction={"row"} xs={12}>
			<Grid item xs={6}>
				<GlobalStyledTextField
					name={addressFieldNames.address}
					required
					label={t("customer.address." + addressFieldNames.address)}
					value={props.customerAddress.address}
					error={customerAddressValidator.containsError(customerAddressFieldNames.address)}
					helperText={customerAddressValidator.getErrorMessage(customerAddressFieldNames.address)}
					onChange={customerAddressValidator.wrapOnChangeInValidator((e) => {
						props.updateAddress({...props.customerAddress, address: e.currentTarget.value});
					})}
					variant={"outlined"}
				/>
			</Grid>
			<Grid item xs={6}>
				<GlobalStyledTextField
					name={addressFieldNames.zipCode}
					required
					label={t("customer.address." + addressFieldNames.zipCode)}
					value={props.customerAddress.zipCode}
					error={customerAddressValidator.containsError(customerAddressFieldNames.zipCode)}
					helperText={customerAddressValidator.getErrorMessage(customerAddressFieldNames.zipCode)}
					onChange={customerAddressValidator.wrapOnChangeInValidator((e) => {
						props.updateAddress({...props.customerAddress, zipCode: e.currentTarget.value});
					})}
					variant={"outlined"}
				/>
			</Grid>
			<Grid item xs={6}>
				<GlobalStyledTextField
					name={addressFieldNames.city}
					required
					label={t("customer.address." + addressFieldNames.city)}
					value={props.customerAddress.city}
					error={customerAddressValidator.containsError(addressFieldNames.city)}
					helperText={customerAddressValidator.getErrorMessage(addressFieldNames.city)}
					onChange={customerAddressValidator.wrapOnChangeInValidator((e) => {
						props.updateAddress({...props.customerAddress, city: e.currentTarget.value});
					})}
					variant={"outlined"}
				/>
			</Grid>
		</Grid>
	);
};

export default CustomerAddressMask;
