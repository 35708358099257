import {FieldValidator, proxiedPropertiesOf, ValidatorType} from "../../../../ValidatorHook";
import TaskTemplateModel from "../../../../../model/types/basistypes/ressources/tasks/TaskTemplateModel";

let taskTemplate: TaskTemplateModel;

// @ts-ignore
const properties = proxiedPropertiesOf(taskTemplate)
const titleError = "validator.taskTemplate.titleError";
const standardDescriptionError = "";

const TaskTemplateValidator: FieldValidator[] = [
    {
        FieldName: properties.title,
        Validation: "^.+$",
        Type: ValidatorType.REGEX,
        ErrorMessage: titleError
    },
    {
        FieldName: properties.title,
        Validation: "",
        Type: ValidatorType.REGEX,
        ErrorMessage: standardDescriptionError
    },
]

export default TaskTemplateValidator;
