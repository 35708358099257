import React, {useEffect, useState} from 'react';
import {AppointmentModel, ResourceType,} from '../../model/types/basistypes/times/AppointmentModel';
import AppointmentTypeModel from '../../model/types/basistypes/times/AppointmentTypeModel';
import {getAppointmentTypForResourceType} from '../../model/ModelController/times/TerminTypController';
import DateField from '../Administration/Resources/DateField';
import useValidator, {proxiedPropertiesOf} from '../ValidatorHook';
import EventDialogValidator from './EventDialogValidator';
import {Button, Checkbox, Dialog, FormControlLabel, Grid, MenuItem, Typography,} from '@mui/material';
import {
  GlobalStyledDialogPaper,
  GlobalStyledDivider,
  GlobalStyledTextField,
} from '../StyledComponents/GlobalStyled/GlobalStyled';

import VehicleModel from '../../model/types/basistypes/ressources/VehicleModel';
import EmployeeModel from '../../model/types/basistypes/ressources/EmployeeModel';
import EquipmentModel from '../../model/types/basistypes/ressources/EquipmentModel';
import {useTranslation} from 'react-i18next';
import {useSnackbar, VariantType} from 'notistack';
import {getCurrentDateAtStartAsUTCTimestamp} from '../../utility/dateUtil';
import {AppointmentSeriesModel} from '../../model/types/basistypes/times/AppointmentSeriesModel';
import {
  createNewSeries,
  deleteAppointmentSeries,
  getAppointmentSeriesById,
  updateAppointmentSeries,
} from '../../model/ModelController/times/AppointmentSeriesController';

interface propsType {
  open: boolean;
  editEnabled: boolean;
  resource: VehicleModel | EmployeeModel | EquipmentModel;
  resourceType: ResourceType;
  appointmentSeriesId?: number; // you can either initiate with a concrete model or the id
  appointmentSeries?: AppointmentSeriesModel;
  updateCallback(appointment: AppointmentModel): void;
  deleteCallback(): void;
  closeCallback(): void;
}

function AppointmentMask(props: propsType) {
  const getEmptyAppointmentSeries = (): AppointmentSeriesModel => {
    return {
      id: Date.now(),
      appointments: [],
      from: getCurrentDateAtStartAsUTCTimestamp(),
      reusable: false,
      to: getCurrentDateAtStartAsUTCTimestamp(),
      appointmentType: undefined,
    };
  };

  const [localAppointmentSeries, setLocalAppointmentSeries] =
    useState<AppointmentSeriesModel>(
      props.appointmentSeries
        ? props.appointmentSeries
        : getEmptyAppointmentSeries()
    );
  const [appointmentTypeList, setAppointmentTypeList] = useState<
    AppointmentTypeModel[]
  >([]);
  const fieldDescriptor = proxiedPropertiesOf(localAppointmentSeries);
  const eventDialogValidator = useValidator(
    EventDialogValidator,
    localAppointmentSeries,
    localAppointmentSeries?.id
  );
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const showSnackbar = (variant: VariantType, message: string) => {
    enqueueSnackbar(message, { variant });
  };

  //initialize TerminTypen
  useEffect(() => {
    let initialize = async () => {
      let appointmentTypes = await getAppointmentTypForResourceType(
        props.resourceType
      );

      if (appointmentTypes !== undefined) {
        setAppointmentTypeList(appointmentTypes);
      }

      if (props.appointmentSeries) {
        setLocalAppointmentSeries(props.appointmentSeries);
      } else {
        if (props.appointmentSeriesId) {
          const fetchedSeries = await getAppointmentSeriesById(
            props.appointmentSeriesId
          );
          if (fetchedSeries) {
            setLocalAppointmentSeries(fetchedSeries);
          } else {
            setLocalAppointmentSeries(getEmptyAppointmentSeries());
          }
        } else {
          setLocalAppointmentSeries(getEmptyAppointmentSeries());
        }
      }
    };

    initialize();
  }, [
    props.resource.id,
    props.appointmentSeries,
    props.appointmentSeriesId,
    props.resourceType,
  ]);

  const saveToFireBase = () => {
    let errors = eventDialogValidator.performFullValidation();
    if (errors.errorCount > 0) {
      alert(t("validator.errorPreventingSave"));
      return;
    }

    if (props.appointmentSeries || props.appointmentSeriesId) {
      if (localAppointmentSeries) {
        updateAppointmentSeries(localAppointmentSeries)
          .then((value) => {
            showSnackbar('success', t('appointment.successfullyCreated'));
            props.updateCallback(localAppointmentSeries.appointments[0]);
          })
          .catch((e) => console.error(e));
      }
    } else {
      if (localAppointmentSeries) {
        //During save we need to negate the check box values.
        createNewSeries(
          localAppointmentSeries,
          props.resource,
          props.resourceType
        )
          .then((value) => {
            showSnackbar('success', t('appointment.successfullyCreated'));
            console.info('Termin angelegt.');
            props.updateCallback(value.appointments[0]);
          })
          .catch((e) => console.error(e));
      }
    }
  };

  const deleteFromFireBase = () => {
    if (localAppointmentSeries !== undefined) {
      deleteAppointmentSeries(localAppointmentSeries)
        .then(() => {
          showSnackbar('success', t('appointment.successfullyDeleted'));
          console.info('Termin gelöscht.');
        })
        .catch((e) => console.log(e));
      props.deleteCallback();
    }
  };

  return localAppointmentSeries ? (
    <Dialog open={props.open} onClose={props.closeCallback}>
      <Grid container xs={12}>
        <GlobalStyledDialogPaper>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography variant={'h6'} align={'center'}>
                {t('appointmentCalendar.chooseAppointmentTitle')}
              </Typography>
              <GlobalStyledDivider />
            </Grid>

            <Grid container xs={12}>
              <Grid item xs={6}>
                <GlobalStyledTextField
                  select
                  required
                  label={t('appointmentCalendar.type')}
                  name={fieldDescriptor.appointmentType}
                  disabled={
                    !!(props.appointmentSeriesId || props.appointmentSeries)
                  }
                  value={localAppointmentSeries.appointmentType?.id || ''}
                  error={eventDialogValidator.containsError(
                    fieldDescriptor.appointmentType
                  )}
                  helperText={eventDialogValidator.getErrorMessage(
                    fieldDescriptor.appointmentType
                  )}
                >
                  {appointmentTypeList.map((typ, index) => {
                    return (
                      <MenuItem
                        key={typ.id}
                        value={typ.id}
                        onClick={() => {
                          setLocalAppointmentSeries({
                            ...localAppointmentSeries,
                            appointmentType: typ,
                          });
                        }}
                      >
                        {' '}
                        {typ.description}{' '}
                      </MenuItem>
                    );
                  })}
                </GlobalStyledTextField>
              </Grid>
            </Grid>

            <Grid container xs={12}>
              <Grid item xs={6}>
                <DateField
                  Date={localAppointmentSeries.from}
                  label={t('appointmentCalendar.from')}
                  error={eventDialogValidator.containsError(
                    fieldDescriptor.from
                  )}
                  helperText={eventDialogValidator.getErrorMessage(
                    fieldDescriptor.from
                  )}
                  disabled={
                    !!(props.appointmentSeriesId || props.appointmentSeries)
                  }
                  onChange={(Date) => {
                    setLocalAppointmentSeries({
                      ...localAppointmentSeries,
                      from: Date,
                    });
                  }}
                />
              </Grid>

              {localAppointmentSeries.appointmentType?.multiday && (
                <Grid item xs={6}>
                  <DateField
                    Date={localAppointmentSeries.to}
                    label={t('appointmentCalendar.to')}
                    error={eventDialogValidator.containsError(
                      fieldDescriptor.to
                    )}
                    helperText={eventDialogValidator.getErrorMessage(
                      fieldDescriptor.to
                    )}
                    disabled={
                      !!(props.appointmentSeriesId || props.appointmentSeries)
                    }
                    onChange={(Date) => {
                      setLocalAppointmentSeries({
                        ...localAppointmentSeries,
                        to: Date,
                      });
                    }}
                  />
                </Grid>
              )}
            </Grid>

            {(props.appointmentSeries != null ||
              props.appointmentSeriesId != null) &&
              localAppointmentSeries?.appointmentType?.usableAfter ===
                false && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={localAppointmentSeries.reusable}
                        onChange={(event) => {
                          let state: AppointmentSeriesModel = {
                            ...localAppointmentSeries,
                            reusable: event.target.checked,
                          };
                          setLocalAppointmentSeries(state);
                        }}
                        name="UsableOn"
                      />
                    }
                    label={t('appointmentCalendar.appointmentSuccessful') + ''}
                  />
                </Grid>
              )}
            {/*Dialog Footer*/}
            <Grid container>
              <Grid item xs={12}>
                {' '}
                <GlobalStyledDivider />
              </Grid>
              <Grid item container xs={4} justifyContent={'center'}>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  onClick={saveToFireBase}
                >
                  {t('actions.save')}
                </Button>
              </Grid>
              <Grid item container xs={4} justifyContent={'center'}>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  onClick={deleteFromFireBase}
                >
                  {t('actions.delete')}
                </Button>
              </Grid>
              <Grid item container xs={4} justifyContent={'center'}>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  onClick={props.closeCallback}
                >
                  {t('actions.back')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </GlobalStyledDialogPaper>
      </Grid>
    </Dialog>
  ) : (
    <div />
  );
}
// @ts-ignore
export default AppointmentMask;
