import React, {useContext, useState} from "react";
import TaskTemplateModel from "../../../../../model/types/basistypes/ressources/tasks/TaskTemplateModel";
import TaskTemplateMask from "./TaskTemplateMask";
import {getTaskTemplates} from "../../../../../model/ModelController/Resources/tasks/TaskTemplateController";
import {useSnackbar, VariantType, withSnackbar} from "notistack";
import {AppBarInfoAreaContext} from "../../../../Sidebar/AppBarInfoAreaContext";
import ResourceLayout from "../../Helper/ResourcesLayout";
import ResourceList from "../../Helper/ResourceList";
import {useTranslation} from "react-i18next";
import {Prompt} from "react-router-dom";


function TaskTemplateOverview(this: any) {
    const appbarContext = useContext(AppBarInfoAreaContext);

    const [registered, setRegistered] = useState(false)
    const [isDirtyIndex, setIsDirtyIndex] = useState(-1)
    const [taskTemplateList, setTaskTemplateList] = useState<TaskTemplateModel[]>([])
    const [selectedTaskTemplate, setSelectedTaskTemplate] = useState<number>(0)
    const {enqueueSnackbar} = useSnackbar();
    const [open, setOpen] = useState(false);
    const {t, i18n} = useTranslation();


    appbarContext.updateContent(t("taskTemplate.manageTitle"));


    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }

    const updateCallback = (index: number) => {
        return (update: TaskTemplateModel) => {
            update = {...update, _isNew: false}
            let newState: TaskTemplateModel[] = [...taskTemplateList]
            newState[index] = update;
            setTaskTemplateList(newState);
            setIsDirtyIndex(-1);
            showSnackbar("success", t("taskTemplate.successfullyCreated", {
                title: update.title
            }))
        }
    }

    const deleteCallback = (index: number) => {
        return (update: TaskTemplateModel) => {
            let newState: TaskTemplateModel[] = taskTemplateList.filter(
                ((value, index1) => {
                    return index1 !== index
                })
            )
            setTaskTemplateList(newState);
            setSelectedTaskTemplate(0);
            setIsDirtyIndex(-1);
            showSnackbar("success", t("taskTemplate.successfullyDeleted", {
                title: update.title
            }))
        }
    }

    if (!registered) {
        getTaskTemplates().then(
            (result: TaskTemplateModel[] | undefined) => {
                if (result !== undefined)
                    setTaskTemplateList(result)
            }
        );
        setRegistered(true)
    }

    const getEmptyTaskTemplate = () => {
        let newTaskTemplate: TaskTemplateModel = {
            id: Date.now(),
            title: t("taskTemplate.placeholder"),
            standardDescription: "",
            _isNew: true
        }
        return newTaskTemplate
    }

    const addTaskTemplate = () => {
        if (discardChangesInputWindowConfirmDialog()) {
            let newTaskTemplateList = [...taskTemplateList];
            if (taskTemplateList[selectedTaskTemplate]?._isNew) {
                newTaskTemplateList.splice(selectedTaskTemplate, 1)
            }

            if (taskTemplateList === undefined) {
                let newState: TaskTemplateModel[] = [getEmptyTaskTemplate()];
                setTaskTemplateList(newState)
                setSelectedTaskTemplate(newState.length - 1)
                setIsDirtyIndex(-1);
            } else {
                newTaskTemplateList.push(getEmptyTaskTemplate());
                setTaskTemplateList(newTaskTemplateList)
                setSelectedTaskTemplate(newTaskTemplateList.length - 1)
                setIsDirtyIndex(-1);
            }
        }
    }

    const setIndex = (resource: any, index: number) => {
        if (selectedTaskTemplate === index) {
            return;
        }
        if (discardChangesInputWindowConfirmDialog()) {
            if (taskTemplateList[selectedTaskTemplate]?._isNew) {
                let newTaskTemplateList = [...taskTemplateList];
                newTaskTemplateList.splice(selectedTaskTemplate, 1)
                setSelectedTaskTemplate(index);
                setTaskTemplateList(newTaskTemplateList)
            }
            setSelectedTaskTemplate(index)
            setIsDirtyIndex(-1)
        } else {
            //do nothing -> stay on old selected resource
        }
    }

    const discardChangesInputWindowConfirmDialog = () => {
        if (isDirtyIndex !== -1 || taskTemplateList[selectedTaskTemplate]?._isNew) {
            return window.confirm(t("discardChangesDialog.text"));
        } else {
            return true;
        }
    }

    return (
        <>
            <Prompt
                when={isDirtyIndex !== -1 || taskTemplateList[selectedTaskTemplate]?._isNew === true}
                message={t("discardChangesDialog.leaveOverview")}
            />
            <ResourceLayout
                left={
                    <ResourceList resources={taskTemplateList}
                                  selectedItem={selectedTaskTemplate}
                                  getListItemText={(template: TaskTemplateModel) => {
                                      return template.title + ((template._isNew || taskTemplateList[isDirtyIndex]?.id === template.id) ? "*" : "");
                                  }}
                                  resourceClickCallBack={setIndex}
                                  addItemCallback={addTaskTemplate}/>
                }
                right={
                    (taskTemplateList.length > 0) ?
                        <TaskTemplateMask editEnabled={true} TaskTemplate={taskTemplateList[selectedTaskTemplate]}
                                          updateCallback={updateCallback(selectedTaskTemplate)}
                                          deleteCallback={deleteCallback(selectedTaskTemplate)}
                                          isDirtyCallback={() => setIsDirtyIndex(selectedTaskTemplate)}
                                          asDialog={false}
                                          closeCallback={() => {
                                          }}/> : <div/>
                }/>
        </>)
}

export default withSnackbar(TaskTemplateOverview)

