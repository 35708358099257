import DrivingLicenseClassModel from "../../types/basistypes/ressources/DrivingLicenseClassModel";
import {getFirebase} from "../FirebaseWrapper";
import getCompanyAffiliation from "../CompanyAffiliation";
import firebase from "firebase";
import {purgeUiOnlyFields} from "../FirebaseConverter";


const getLicenseReference = async (id: number) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId) {
        return dbConnection
            .collection(companyId)
            .doc("DrivingLicenseClass")
            .collection("DrivingLicenseClass")
            .doc(id.toString())
    }
}

const addDrivingLicenseClass = async (drivingLicenseClass: DrivingLicenseClassModel) => {
    drivingLicenseClass = purgeUiOnlyFields(drivingLicenseClass);
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        dbConnection
            .collection(companyId)
            .doc("DrivingLicenseClass")
            .collection("DrivingLicenseClass")
            .doc(drivingLicenseClass.id.toString())
            .set({...drivingLicenseClass, usable: true});
    }
}

const deleteDrivingLicenseClass = async (drivingLicenseClass: DrivingLicenseClassModel) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        dbConnection
            .collection(companyId)
            .doc("DrivingLicenseClass")
            .collection("DrivingLicenseClass")
            .doc(drivingLicenseClass.id.toString())
            .update({usable: false});
    }
}

const getDrivingLicenseClass = async () => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
            .collection(companyId)
            .doc("DrivingLicenseClass")
            .collection("DrivingLicenseClass")
            .where("usable", "==", true)
            .get()
        let result: DrivingLicenseClassModel[] = [];
        query.docs.forEach(
            doc => {
                result.push(<DrivingLicenseClassModel>doc.data())
            }
        )
        console.log(result)
        return result;
    }
    return [];
}

export {
    addDrivingLicenseClass,
    getDrivingLicenseClass,
    deleteDrivingLicenseClass,
    getLicenseReference,
}

