import {getFirebase, getUser, isAuthenticated} from "./FirebaseWrapper";
import UserModel from "../types/basistypes/UserModel";

let company: UserModel | undefined = undefined;

let companyAffiliation: string | null = null;
const getCompanyAffiliation = async () => {
    // Nur angemeldete UserModel werden abgefragt
    if (isAuthenticated()) {
        if (company !== undefined) {
            return company.company;
        }

        const dbCon = getFirebase().firestore();
        let user = getUser();
        if (user !== null) {
            //Die Firmenzuordnung für das UserModel lesen
            let snapshot = await dbCon.collection("Users").doc(user.uid).get()
            let docData = snapshot.data();

            if (docData !== undefined) {
                let userToFirma: UserModel = JSON.parse(JSON.stringify(docData));
                company = userToFirma;
                companyAffiliation = userToFirma.company
                return userToFirma.company;
            }
        }
        return undefined;
    }
}


export default getCompanyAffiliation;

