import {getFirebase} from "../../FirebaseWrapper";
import getCompanyAffiliation from "../../CompanyAffiliation";
import firebase from "firebase";
import TaskTemplateModel from "../../../types/basistypes/ressources/tasks/TaskTemplateModel";
import {purgeUiOnlyFields} from "../../FirebaseConverter";


const addTaskTemplate = async (taskTemplate: TaskTemplateModel) => {
    taskTemplate = purgeUiOnlyFields(taskTemplate);
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        dbConnection
            .collection(companyId)
            .doc("TaskTemplate")
            .collection("TaskTemplate")
            .doc(taskTemplate.id.toString())
            .set({...taskTemplate, usable: true});
    }
}

const getTaskTemplateDocument = async (id: number) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId) {
        return dbConnection
            .collection(companyId)
            .doc("TaskTemplate")
            .collection("TaskTemplate")
            .doc(id.toString())
    }
}


const deleteTaskTemplate = async (taskTemplate: TaskTemplateModel) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        dbConnection
            .collection(companyId)
            .doc("TaskTemplate")
            .collection("TaskTemplate")
            .doc(taskTemplate.id.toString())
            .update({usable: false});
    }
}

const getTaskTemplates = async () => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
            .collection(companyId)
            .doc("TaskTemplate")
            .collection("TaskTemplate")
            .where("usable", "==", true)
            .get()
        let result: TaskTemplateModel[] = [];
        query.docs.forEach(
            doc => {
                result.push(<TaskTemplateModel>doc.data())
            }
        )
        return result;
    }
}

export {
    addTaskTemplate,
    getTaskTemplates,
    deleteTaskTemplate,
    getTaskTemplateDocument,
}
