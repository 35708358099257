import React, {useEffect, useState} from "react";
import TaskTemplateModel from "../../../../../model/types/basistypes/ressources/tasks/TaskTemplateModel";
import {
    addTaskTemplate,
    deleteTaskTemplate
} from "../../../../../model/ModelController/Resources/tasks/TaskTemplateController";
import useValidator, {proxiedPropertiesOf} from "../../../../ValidatorHook";
import TaskTemplateValidator from "./TaskTemplateValidator";
import {Grid, Typography} from "@mui/material";
import {ArrowBackIos, Delete, Save,} from "@mui/icons-material";
import {
    GlobalStyledDivider,
    GlobalStyledPaper,
    GlobalStyledTextField
} from "../../../../StyledComponents/GlobalStyled/GlobalStyled";
import {useTranslation} from "react-i18next";
import GenericActionBar from "../../../../ActionBar/GenericActionBar";
import {Action} from "../../../../ActionBar/GenericActionBarIconButton";

interface propsType {
    editEnabled: boolean
    asDialog: boolean
    TaskTemplate: TaskTemplateModel

    updateCallback(TaskTemplate: TaskTemplateModel): void

    deleteCallback(TaskTemplate: TaskTemplateModel): void

    isDirtyCallback?(): void

    closeCallback(): void

}

function TaskTemplateMask(props: propsType) {
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [localTaskTemplate, setTaskTemplate] = useState<TaskTemplateModel>(props.TaskTemplate)
    const [open, setOpen] = useState(false);
    const [openAppointment, setOpenAppointment] = useState(false);
    const fieldDescriptor = proxiedPropertiesOf(localTaskTemplate)
    const taskTemplateValidator = useValidator(TaskTemplateValidator, localTaskTemplate, localTaskTemplate.id);
    const [appointmentShouldRender, setAppointmentShouldRender] = useState(false);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        if (props.TaskTemplate._isNew) {
            setIsDirty(true);
        } else {
            setIsDirty(false);
        }
    }, [props.TaskTemplate])

    if (props.TaskTemplate == undefined && localTaskTemplate != undefined) {
        setTaskTemplate(props.TaskTemplate)
    }

    if (props.TaskTemplate == undefined || localTaskTemplate == undefined) {
        return (<div/>)
    }

    if (props.TaskTemplate.id !== localTaskTemplate.id) {
        setTaskTemplate(props.TaskTemplate)
        setAppointmentShouldRender(true)
    }

    const wrapCallback = (callback: any, windowmode: boolean) => {
        return (windowmode) ?
            () => {
                callback()
                props.closeCallback();
            } : callback
    }

    const saveToFireBase = () => {
        let errors = taskTemplateValidator.performFullValidation()
        if (errors.errorCount > 0) {
            alert(t("validator.errorPreventingSave"));
            return;
        }
        addTaskTemplate(localTaskTemplate)
            .then(value => {
                setIsDirty(false);
                console.info("TaskTemplate angelegt.");
            })
            .catch(e => console.error(e))
        props.updateCallback(localTaskTemplate)
    }

    const deleteFromFireBase = () => {
        deleteTaskTemplate(localTaskTemplate)
            .then(value => console.info("TaskTemplate angelegt."))
            .catch(e => console.error(e))
        props.deleteCallback(localTaskTemplate)
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName: string = e.currentTarget.name;
        const newValue = e.currentTarget.value
        let newState = {...localTaskTemplate};
        if (props.isDirtyCallback) {
            setIsDirty(true);
            props.isDirtyCallback();
        }
        newState[fieldName] = newValue;
        setTaskTemplate(newState)
    }

    const taskTemplateActions = [
        {
            icon: <Save/>,
            name: 'Save',
            tooltip: t("actions.save"),
            callback: wrapCallback(saveToFireBase, props.asDialog),
            disabled: !isDirty,
            actionKey: "s"
        } as Action,
        {
            icon: <Delete/>,
            name: 'Delete',
            tooltip: t("actions.delete"),
            callback: wrapCallback(deleteFromFireBase, props.asDialog)
        },
    ];

    if (props.asDialog) {
        taskTemplateActions.push({
            icon: <ArrowBackIos/>,
            name: 'Back',
            tooltip: t("actions.back"),
            callback: props.closeCallback
        })
    }

    return (
        <Grid container xs={12}>
            <Grid item container xs={12}>
                <GlobalStyledPaper>
                    <Grid item container
                          alignItems={"flex-start"}
                          xs={12}>
                        <GenericActionBar actions={taskTemplateActions}/>
                        <Grid item xs={12}>
                            <Typography variant={"h6"}
                                        align={"center"}>{t("taskTemplate.title") + ": " + localTaskTemplate.title}</Typography>
                            <GlobalStyledDivider/>
                        </Grid>
                        <Grid item xs={6}>
                            <GlobalStyledTextField
                                name={fieldDescriptor.title}
                                label={t(fieldDescriptor.title)}
                                required
                                value={localTaskTemplate.title}
                                error={taskTemplateValidator.containsError(fieldDescriptor.title)}
                                helperText={taskTemplateValidator.getErrorMessage(fieldDescriptor.title)}
                                onChange={taskTemplateValidator.wrapOnChangeInValidator(onChange)}
                                variant={"outlined"}/>
                        </Grid>
                        <Grid item xs={6}>
                            <GlobalStyledTextField
                                multiline={true}
                                type={"F"}
                                name={fieldDescriptor.standardDescription}
                                label={t(fieldDescriptor.standardDescription)}
                                value={localTaskTemplate.standardDescription}
                                error={taskTemplateValidator.containsError(fieldDescriptor.standardDescription)}
                                helperText={taskTemplateValidator.getErrorMessage(fieldDescriptor.standardDescription)}
                                onChange={taskTemplateValidator.wrapOnChangeInValidator(onChange)}
                                variant={"outlined"}/>
                        </Grid>
                    </Grid>
                </GlobalStyledPaper>
            </Grid>
        </Grid>
    )
}

// @ts-ignore
export default TaskTemplateMask;

