import {Button, Grid, Icon, Typography} from "@mui/material";

import WarningIcon from '@mui/icons-material/Warning';
import React from "react";
import {GlobalStyledDialogPaper} from "./StyledComponents/GlobalStyled/GlobalStyled";
import {useTranslation} from "react-i18next";
import {useSnackbar, VariantType, withSnackbar} from "notistack";
import firebase from "firebase";

const NotVerifiedScreen = () => {
    const {enqueueSnackbar} = useSnackbar();
    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }

    const {t} = useTranslation();
    return <Grid container
                 alignItems={"center"}
                 justifyContent={"center"}
                 xs={12}
                 sx={{height: "100vh"}}
    >
        <GlobalStyledDialogPaper>
            <Grid container xs={12} alignItems={"center"} justifyContent={"center"}>
                <Grid item container xs={12} alignItems={"center"} justifyContent={"center"}>
                    <Icon sx={{height: "150px", width: "150px", color: "orange"}}>
                        <WarningIcon sx={{height: "100%", width: "100%", color: "orange"}}/>
                    </Icon>

                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>
                        {t("notVerified.text")}
                    </Typography>
                </Grid>
                <Grid container item xs={12} alignItems={"center"} justifyContent={"flex-end"}>
                    <Grid item container xs={6} alignItems={"center"} justifyContent={"flex-end"}>
                        <Button variant={"contained"} color={"primary"} onClick={
                            () => {
                                let email = firebase.auth()?.currentUser?.email;
                                if (email)
                                    firebase.auth()
                                        ?.currentUser?.sendEmailVerification()
                                        .then(() => showSnackbar("success", t("notVerified.sent") + ""))
                            }
                        }>
                            {t("notVerified.resend")}
                        </Button>
                    </Grid>
                </Grid>


            </Grid>


        </GlobalStyledDialogPaper>
    </Grid>
}


export default withSnackbar(NotVerifiedScreen);