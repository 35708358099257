import React, {useContext, useState} from "react";
import AppointmentTypeMask from "./AppointmentTypeMask";
import {useSnackbar, VariantType, withSnackbar} from "notistack";
import AppointmentTypeModel from "../../model/types/basistypes/times/AppointmentTypeModel";
import {getAppointmentType} from "../../model/ModelController/times/TerminTypController";
import {AppBarInfoAreaContext} from "../Sidebar/AppBarInfoAreaContext";
import ResourceLayout from "../Administration/Resources/Helper/ResourcesLayout";
import ResourceList from "../Administration/Resources/Helper/ResourceList";
import {useTranslation} from "react-i18next";
import {Prompt} from "react-router-dom";

function AppointmentTypeOverview(this: any) {
    const [registered, setRegistered] = useState(false)
    const [appointmentTypeList, setAppointmentTypeList] = useState<AppointmentTypeModel[]>([])
    const [isDirtyIndex, setIsDirtyIndex] = useState(-1)
    const [selectedAppointmentType, setSelectedAppointmentType] = useState<number>(0)
    const {enqueueSnackbar} = useSnackbar();
    const [open, setOpen] = useState(false);
    const appbarContext = useContext(AppBarInfoAreaContext);
    const {t, i18n} = useTranslation();


    appbarContext.updateContent(t("appointment.manageTitle"));

    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }

    const updateCallback = (index: number) => {
        return (update: AppointmentTypeModel) => {
            update = {...update, _isNew: false}
            let newState: AppointmentTypeModel[] = [...appointmentTypeList]
            newState[index] = update;
            setAppointmentTypeList(newState);
            setIsDirtyIndex(-1);
            showSnackbar("success", t("appointmentTypeMask.successfullyCreated", {
                title: update.description
            }))
        }
    }

    const deleteCallback = (index: number) => {
        return (update: AppointmentTypeModel) => {
            let newState: AppointmentTypeModel[] = appointmentTypeList.filter(
                ((value, index1) => {
                    return index1 !== index
                })
            )
            setAppointmentTypeList(newState);
            setSelectedAppointmentType(0);
            setIsDirtyIndex(-1);
            showSnackbar("success", t("appointmentTypeMask.successfullyDeleted", {
                title: update.description
            }))
        }
    }

    if (!registered) {
        getAppointmentType().then(
            (result: AppointmentTypeModel[] | undefined) => {
                if (result !== undefined)
                    setAppointmentTypeList(result)
            }
        );
        setRegistered(true)
    }

    const getEmptyAppointmentType = () => {
        let newAppointmentType: AppointmentTypeModel = {
            id: Date.now(),
            description: t("appointment.newAppointmentTypeDescription"),
            available: [],
            multiday: false,
            usableOn: false,
            usableAfter: false,
            _isNew: true
        }
        return newAppointmentType
    }

    const addAppointmentType = () => {
        if (discardChangesInputWindowConfirmDialog()) {
            let newAppointmentTypeList = [...appointmentTypeList];
            if (appointmentTypeList[selectedAppointmentType]?._isNew) {
                newAppointmentTypeList.splice(selectedAppointmentType, 1)
            }

            if (appointmentTypeList === undefined) {
                let newState: AppointmentTypeModel[] = [getEmptyAppointmentType()];
                setAppointmentTypeList(newState)
                setSelectedAppointmentType(newState.length - 1)
                setIsDirtyIndex(-1);
            } else {
                newAppointmentTypeList.push(getEmptyAppointmentType());
                setAppointmentTypeList(newAppointmentTypeList)
                setSelectedAppointmentType(newAppointmentTypeList.length - 1)
                setIsDirtyIndex(-1)
            }
        }
    }


    const setIndex = (resource: any, index: number) => {
        if (selectedAppointmentType === index) {
            return;
        }

        if (discardChangesInputWindowConfirmDialog()) {
            if (appointmentTypeList[selectedAppointmentType]?._isNew) {
                let newAppointmentTypeList = [...appointmentTypeList];
                newAppointmentTypeList.splice(selectedAppointmentType, 1)
                setSelectedAppointmentType(index);
                setAppointmentTypeList(newAppointmentTypeList)
            }
            setSelectedAppointmentType(index);
            setIsDirtyIndex(-1);
        } else {
            //do nothing -> stay on old selected resource
        }
    }

    const discardChangesInputWindowConfirmDialog = () => {
        if (isDirtyIndex !== -1 || appointmentTypeList[selectedAppointmentType]?._isNew) {
            return window.confirm(t("discardChangesDialog.text"));
        } else {
            return true;
        }
    }

    return (
        <>
            <Prompt
                when={isDirtyIndex !== -1 || appointmentTypeList[selectedAppointmentType]?._isNew === true}
                message={t("discardChangesDialog.leaveOverview")}
            />
            <ResourceLayout
                left={
                    <ResourceList
                        resources={appointmentTypeList}
                        selectedItem={selectedAppointmentType}
                        getListItemText={(eventType: AppointmentTypeModel) => {
                            return eventType.description + ((eventType._isNew || appointmentTypeList[isDirtyIndex]?.id === eventType.id) ? "*" : "")
                        }}
                        resourceClickCallBack={setIndex}
                        addItemCallback={addAppointmentType}/>
                }
                right={
                    (appointmentTypeList.length > 0) ?
                        <AppointmentTypeMask
                            editEnabled={true} appointmentType={appointmentTypeList[selectedAppointmentType]}
                            updateCallback={updateCallback(selectedAppointmentType)}
                            deleteCallback={deleteCallback(selectedAppointmentType)}
                            isDirtyCallback={() => setIsDirtyIndex(selectedAppointmentType)}
                            asDialog={false}
                            closeCallback={() => {
                            }}/> : <div/>
                }/>
        </>)
}

export default withSnackbar(AppointmentTypeOverview)

