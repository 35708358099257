import {IconButton, Tooltip} from "@mui/material";
import {ResourceStyledFab} from "../StyledComponents/ComponentStyled/ResourceStyled";
import React, {ReactNode} from "react";

export interface Action {
    name: string;
    callback: () => any;
    icon: ReactNode;
    tooltip: string;
    disabled?: boolean;
    actionKey?: string;
}


export default function GenericActionBarIconButton(props: Action) {
    return (
        <Tooltip title={`${props.tooltip} ${props.actionKey ? `(Strg + ${props.actionKey.toUpperCase()})` : ""}`}>
            <IconButton
                onClick={props.callback}
                key={props.name}
                disabled={props?.disabled}
            >
                <ResourceStyledFab color={"secondary"} size={"small"} disabled={props?.disabled}>
                    {props.icon}
                </ResourceStyledFab>
            </IconButton>
        </Tooltip>
    )
}