import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

interface propsType {
  open: boolean;
  title: string;
  text: string;
  confirmButtonLabel: string;
  cancelButtonLabel: string;
  actionOnConfirm: () => void;
  actionOnCancel: () => void;
}

const PopupToConfirm = (props: propsType) => {
  return (
    <Dialog open={props.open}>
      <DialogTitle>
        <Typography>{props.title}</Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Typography>{props.text}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={props.actionOnConfirm}
            >
              {props.confirmButtonLabel}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={props.actionOnCancel}
            >
              {props.cancelButtonLabel}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PopupToConfirm;
