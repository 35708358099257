import {Draggable} from "react-beautiful-dnd";
import React from "react";
import {makeStyles} from "@material-ui/core";

interface propsType {
    Id: number
    index: number
    children: React.ReactNode
}

const useStyles = makeStyles({
    item: {
        width: "100%",
    },
    container: {
        width: "100%",
    }
});

const getItemStyle = (isDragging: any, draggableStyle: any, snapshot: any) => {
    if (!snapshot.isDropAnimating) {
        return draggableStyle;
    }

    return {
        ...draggableStyle,
        transitionDuration: "1ms",
        transitionDelay: "1ms",
    }
}

export default function DraggableJSXwrapper(props: propsType) {
    const classes = useStyles()

    return (
        <div className={classes.item}>
            {(props.children) ? <Draggable key={props.Id} draggableId={props.Id.toString()} index={props.index}>
                {(provided: any, snapshot: any) => {
                    return <div
                        className={classes.item}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            snapshot
                        )}>
                        {(props.children) ? props.children : <div/>}
                    </div>
                }}
            </Draggable> : <div/>}
        </div>
    )
}
