import AppointmentTypeModel from "../../types/basistypes/times/AppointmentTypeModel";
import {getFirebase} from "../FirebaseWrapper";
import getCompanyAffiliation from "../CompanyAffiliation";
import firebase from "firebase";
import {ResourceType} from "../../types/basistypes/times/AppointmentModel";
import {purgeUiOnlyFields} from "../FirebaseConverter";

const getAppointmentTypDocument = async (id: number) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId) {
        return dbConnection
            .collection(companyId)
            .doc("AppointmentType")
            .collection("AppointmentType")
            .doc(id.toString())
    }
}


const getAppointmentTypesThatBlockResources = async () => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
            .collection(companyId)
            .doc("AppointmentType")
            .collection("AppointmentType")
            .where("usableAfter", "==", false)
            .where("usable", "==", true)
            .get()
        let result: AppointmentTypeModel[] = [];
        query.docs.forEach(
            doc => {
                result.push(doc.data() as AppointmentTypeModel)
            }
        )
        return result;
    }
    return []

}
const addAppointmentType = async (appointmentType: AppointmentTypeModel) => {
    appointmentType = purgeUiOnlyFields(appointmentType);
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        dbConnection
            .collection(companyId)
            .doc("AppointmentType")
            .collection("AppointmentType")
            .doc(appointmentType.id.toString())
            .set({...appointmentType, usable: true});
    }
}

const deleteAppointmentTyp = async (appointmentType: AppointmentTypeModel) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        dbConnection
            .collection(companyId)
            .doc("AppointmentType")
            .collection("AppointmentType")
            .doc(appointmentType.id.toString())
            .update({usable: false});
    }
}


const getAppointmentType = async () => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
            .collection(companyId)
            .doc("AppointmentType")
            .collection("AppointmentType")
            .where("usable", "==", true)
            .get()
        let result: AppointmentTypeModel[] = [];
        query.docs.forEach(
            doc => {
                result.push(doc.data() as AppointmentTypeModel)
            }
        )
        return result;
    }
}

const getAppointmentTypForResourceType = async (resourceType: ResourceType) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    let result: AppointmentTypeModel[] = [];
    if (companyId !== undefined) {
        let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
            .collection(companyId)
            .doc("AppointmentType")
            .collection("AppointmentType")
            .where("available", "array-contains", resourceType)
            .where("usable", "==", true).get()
        query.docs.forEach(
            doc => {
                result.push(doc.data() as AppointmentTypeModel)
            }
        )
    }
    return result
}

export {
    addAppointmentType,
    getAppointmentType,
    deleteAppointmentTyp,
    getAppointmentTypForResourceType,
    getAppointmentTypDocument,
    getAppointmentTypesThatBlockResources
}

