import {getFirebase} from "../FirebaseWrapper";
import getCompanyAffiliation from "../CompanyAffiliation";
import UserModel from "../../types/permissions/UserModel";
import EmployeeModel from "../../types/basistypes/ressources/EmployeeModel";
import {transformArrayToReference} from "../FirebaseConverter";
import Role from "../../types/permissions/Role";
import firebase from "firebase";
import {registerGenericCollectionListener} from "../GenericFirebaseSnapshotListener";


function uuidv4() {
    //@ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

const getUser = async (userId: string) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();
    const companyAffiliation = await getCompanyAffiliation()
    if (companyAffiliation) {
        const user = await dbConnection
            .collection(companyAffiliation)
            .doc("Users")
            .collection("Users")
            .doc(userId)
            .get()

        var userData = user.data() as any;
        let roleDoc = await userData.role.get();
        userData.role = roleDoc.data();
        return userData as UserModel;
    }
}


const addUser = async (newUser: UserModel, password: string) => {

    const firebase = getFirebase()
    const createUser = firebase.functions().httpsCallable(
        "createUserCallable"
    )
    const dbConnection = firebase.firestore();

    const companyAffiliation = await getCompanyAffiliation()

    if (companyAffiliation) {

        try {
            const uid = await createUser({
                    displayName: newUser.employee.name + " " + newUser.employee.surname,
                    password: password,
                    email: newUser.loginName
                }
            )

            try {
                //register the user and the company he belongs to on the top level
                let success = await dbConnection.collection(companyAffiliation)
                    .doc("Users")
                    .collection("Users")
                    .doc(uid.data.uid)
                    .set({
                        id: uid.data.uid,
                        loginName: newUser.loginName,
                        usable: true,
                        employee: (await transformArrayToReference(firebase, dbConnection, companyAffiliation, "Employee", [newUser.employee]))[0],
                        role: (await transformArrayToReference(firebase, dbConnection, companyAffiliation, "Roles", [newUser.role]))[0],
                        loginEnabled: newUser.loginEnabled
                    })

                return uid.data.uid;

            } catch (e) {
                //if we fail to write to the local user path we must delete the user we just created
                //otherwise we run into inconsistencies
                await deleteUser(uid.data.uid)
                console.log(e)
            }

        } catch (e) {
            console.log("User couldn't be created", e.message);

        }
    }
}

const updateUser = async (id: string, role: Role, loginEnabled: boolean) => {

    const firebase = getFirebase()
    const dbConnection = firebase.firestore();
    const companyAffiliation = await getCompanyAffiliation()

    if (companyAffiliation) {
        try {
            let success = await dbConnection.collection(companyAffiliation)
                .doc("Users")
                .collection("Users")
                .doc(id)
                .update({
                    role: (await transformArrayToReference(firebase, dbConnection, companyAffiliation, "Roles", [role]))[0],
                    loginEnabled: loginEnabled
                })
        } catch (e) {
            console.log(e)
        }
    }
}

const deleteUser = async (user: UserModel) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();
    const companyAffiliation = await getCompanyAffiliation()

    if (companyAffiliation) {
        const firebase = getFirebase()
        const deleteUser = firebase.functions("europe-west1").httpsCallable(
            "api/deleteUser"
        )

        try {
            await deleteUser({uid: user.id})
            await dbConnection.collection(companyAffiliation)
                .doc("Users")
                .collection("Users")
                .doc(user.id).delete()
        } catch (e) {
            console.log(e)
        }
    }

}


const registerUserSnapshotListener = async (employees: EmployeeModel[], roles: Role[], updateFunction: (objectsToAdd: UserModel[], objectsToModify: UserModel[], objectsToDelete: UserModel[]) => void) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();
    const companyAffiliation = await getCompanyAffiliation()
    if (companyAffiliation) {
        const employeeReference = await transformArrayToReference(firebase, dbConnection, companyAffiliation, "Employee", employees);
        const unsubscribeFunctions: (() => void)[] = [];

        const resolverFunction = async (doc: firebase.firestore.QueryDocumentSnapshot) => {
            const employeeId = doc.data().employee.path.split("/").pop()
            const roleId = doc.data().role.path.split("/").pop()
            let copy: UserModel = doc.data() as UserModel;
            copy.employee = employees[employees.findIndex(employee => employee.id == employeeId)]
            copy.role = roles[roles.findIndex(role => role.id === roleId)]
            return copy;

        }
        let i = 0;
        while (employeeReference.length > 0) {
            const references = employeeReference.splice(0, 10);
            const query = dbConnection
                .collection(companyAffiliation)
                .doc("Users")
                .collection("Users")
                .where("employee", "in", references)

            const unsubscribeFunction = await registerGenericCollectionListener<UserModel>(query, updateFunction, resolverFunction, "UserModelListener_Batch_" + i)
            unsubscribeFunctions.push(unsubscribeFunction);
            i++
        }

        return () => {
            unsubscribeFunctions.forEach(unsubscribe => unsubscribe())
        }

    }
}

const getUsers = async (employees: EmployeeModel[], roles: Role[]) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();
    const companyAffiliation = await getCompanyAffiliation()
    let users: UserModel[] = [];
    if (companyAffiliation) {

        let employeeReference = await transformArrayToReference(firebase, dbConnection, companyAffiliation, "Employee", employees);
        //We do this because firebase in and contains-any querys can only
        //contain 10 elements at max
        let promiseArray: Promise<firebase.firestore.QuerySnapshot>[] = [];
        while (employeeReference.length > 0) {
            let references = employeeReference.splice(0, 10);
            promiseArray.push(dbConnection
                .collection(companyAffiliation)
                .doc("Users")
                .collection("Users")
                .where("employee", "in", references).get())

        }

        let queryResults = await Promise.all(promiseArray);
        console.info("User Query", queryResults)
        for (let queryResult of queryResults) {
            for (let doc of queryResult.docs) {
                const employeeId = doc.data().employee.path.split("/").pop()
                const roleId = doc.data().role.path.split("/").pop()
                let copy: UserModel = doc.data() as UserModel;
                copy.employee = employees[employees.findIndex(employee => employee.id == employeeId)]
                copy.role = roles[roles.findIndex(role => role.id === roleId)]
                users.push(copy);
            }
        }
    }
    return users;
}

export {addUser, getUsers, getUser, updateUser, deleteUser, registerUserSnapshotListener}