import React from "react";
import {Grid,} from "@mui/material";
import {GlobalStyledHalfHeightPaper, GlobalStyledPaper} from "../StyledComponents/GlobalStyled/GlobalStyled";
import {addDays} from "date-fns"
import EventDashboard from "./EventDashboard";
import LicenseChart from "./LicenseChart";
import AvailabilityChart from "./AvailabilityChart";
import AvailabilityOverTime from "./AvailabilityOverTime";
import DayPlanDashBoard from "./DayPlanDashboard";
import {getCurrentDateAsUTCTimestamp, localDateToUTCTimestamp} from "../../utility/dateUtil";

export default function Dashboard() {
    let fromDate = getCurrentDateAsUTCTimestamp();
    let toDate = localDateToUTCTimestamp(addDays(fromDate, 15));

    return (
        <Grid container xs={12}>
            <Grid item xs={3}>
                <GlobalStyledPaper>
                    <EventDashboard/>
                </GlobalStyledPaper>
            </Grid>
            <Grid item container xs={9}>
                <Grid item xs={2}>
                    <GlobalStyledHalfHeightPaper sx={{overflowY: "auto"}}>
                        <DayPlanDashBoard fromDate={fromDate} toDate={toDate}/>
                    </GlobalStyledHalfHeightPaper>
                </Grid>
                <Grid item xs={5}>
                    <GlobalStyledHalfHeightPaper>
                        <AvailabilityChart/>
                    </GlobalStyledHalfHeightPaper>
                </Grid>
                <Grid item xs={5}>
                    <GlobalStyledHalfHeightPaper>
                        <LicenseChart/>
                    </GlobalStyledHalfHeightPaper>
                </Grid>
                <Grid item xs={12}>
                    <GlobalStyledHalfHeightPaper>
                        <AvailabilityOverTime/>
                    </GlobalStyledHalfHeightPaper>
                </Grid>
            </Grid>
        </Grid>
    )
}
