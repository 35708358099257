import React, {useEffect, useState} from "react";
import DrivingLicenseClassModel from "../../../../model/types/basistypes/ressources/DrivingLicenseClassModel";
import useValidator, {proxiedPropertiesOf} from "../../../ValidatorHook";
import DrivingLicenseClassValidator from "./DrivingLicenseClassValidator";
import {
    addDrivingLicenseClass,
    deleteDrivingLicenseClass
} from "../../../../model/ModelController/Resources/DrivingLicenseClassController";
import {Grid, Typography} from "@mui/material";
import {ArrowBackIos, Delete, Save} from "@mui/icons-material";
import {
    GlobalStyledDivider,
    GlobalStyledPaper,
    GlobalStyledTextField
} from "../../../StyledComponents/GlobalStyled/GlobalStyled";
import {useTranslation} from "react-i18next";
import {Action} from "../../../ActionBar/GenericActionBarIconButton";
import GenericActionBar from "../../../ActionBar/GenericActionBar";

interface propsType {
    editEnabled: boolean
    asDialog: boolean
    drivingLicenseClass: DrivingLicenseClassModel

    updateCallback(drivingLicenseClass: DrivingLicenseClassModel): void

    deleteCallback(drivingLicenseClass: DrivingLicenseClassModel): void

    isDirtyCallback?(): void

    closeCallback(): void
}

function DrivingLicenseClassMask(props: propsType) {
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [localDrivingLicenseClass, setLocalDrivingLicenseClass] = useState<DrivingLicenseClassModel>(props.drivingLicenseClass)
    const validator = useValidator(DrivingLicenseClassValidator, localDrivingLicenseClass, localDrivingLicenseClass.id);
    const fieldDescriptor = proxiedPropertiesOf(localDrivingLicenseClass);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        if (props.drivingLicenseClass._isNew) {
            setIsDirty(true);
        } else {
            setIsDirty(false);
        }
    }, [props.drivingLicenseClass])

    if (props.drivingLicenseClass == undefined && localDrivingLicenseClass != undefined) {
        setLocalDrivingLicenseClass(props.drivingLicenseClass);
    }

    if (props.drivingLicenseClass == undefined || localDrivingLicenseClass == undefined) {
        return (<div/>);
    }

    if (props.drivingLicenseClass.id !== localDrivingLicenseClass.id) {
        setLocalDrivingLicenseClass(props.drivingLicenseClass);
    }

    const wrapCallback = (callback: any, windowmode: boolean) => {
        return (windowmode) ?
            () => {
                callback()
                props.closeCallback();
            } : callback;
    }

    const saveToFireBase = () => {
        let errors = validator.performFullValidation();
        if (errors.errorCount > 0) {
            alert(t("pleaseCorrectAllErrors"));
            return;
        }

        addDrivingLicenseClass(localDrivingLicenseClass)
            .then(value => {
                setIsDirty(false);
            })
            .catch(e => console.log(e))
        props.updateCallback(localDrivingLicenseClass)
    }

    const deleteFromFireBase = () => {
        deleteDrivingLicenseClass(localDrivingLicenseClass).catch(e => console.log(e))
        props.deleteCallback(localDrivingLicenseClass)
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName: string = e.currentTarget.name;
        const newValue = e.currentTarget.value
        let newState: DrivingLicenseClassModel = {...localDrivingLicenseClass};
        if (props.isDirtyCallback) {
            setIsDirty(true);
            props.isDirtyCallback();
        }
        newState[fieldName] = newValue;
        setLocalDrivingLicenseClass(newState)
    }

    const drivingLicenseClassActions = [
        {
            icon: <Save/>,
            name: 'Save',
            tooltip: t("actions.save"),
            callback: wrapCallback(saveToFireBase, props.asDialog),
            disabled: !isDirty,
            actionKey: "s"
        } as Action,
        {
            icon: <Delete/>,
            name: 'Delete',
            tooltip: t("actions.delete"),
            callback: wrapCallback(deleteFromFireBase, props.asDialog)
        },
    ];

    if (props.asDialog) {
        drivingLicenseClassActions.push({
            icon: <ArrowBackIos/>,
            name: 'Back',
            tooltip: t("actions.back"),
            callback: props.closeCallback
        });
    }

    return (
        <Grid container xs={12}>
            <Grid item container xs={12}>
                <GlobalStyledPaper>
                    <Grid item container
                          alignItems={"flex-start"}
                          justifyContent={"flex-start"}
                          xs={12}>
                        <GenericActionBar actions={drivingLicenseClassActions}/>
                        <Grid item xs={12}>
                            <Typography variant={"h6"}
                                        align={"center"}>{t("drivingLicense.class")}: {localDrivingLicenseClass.description}</Typography>
                            <GlobalStyledDivider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={12} container justifyContent={"center"}>
                                <GlobalStyledTextField
                                    name={fieldDescriptor.description}
                                    required={true}
                                    label={t(fieldDescriptor.description)}
                                    value={localDrivingLicenseClass.description}
                                    error={validator.containsError(fieldDescriptor.description)}
                                    helperText={validator.getErrorMessage(fieldDescriptor.description)}
                                    onChange={validator.wrapOnChangeInValidator(onChange)}
                                    variant={"outlined"}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </GlobalStyledPaper>
            </Grid>
        </Grid>
    )
}

// @ts-ignore
export default DrivingLicenseClassMask;

