import {FieldValidator, proxiedPropertiesOf, ValidatorType} from "../../../ValidatorHook";
import TaskModel from "../../../../model/types/basistypes/ressources/tasks/TaskModel";

let createTaskProperties: TaskModel;
// @ts-ignore
const properties = proxiedPropertiesOf(createTaskProperties)

const nonEmptyString = "^(?!\\s*$).+";
const CreateTaskAtConstructionSiteDialogValidator: FieldValidator[] = [
    {
        FieldName: properties.title,
        Validation: nonEmptyString,
        Type: ValidatorType.REGEX,
        ErrorMessage: "validator.nonEmpty"
    },
]

export default CreateTaskAtConstructionSiteDialogValidator;
