import React, {createContext} from "react";
import UserModel from "../../model/types/permissions/UserModel";

interface UserContextType {
    user: null | UserModel,
    setCurrentUser: React.Dispatch<React.SetStateAction<UserModel | null>> | null
}

const UserContext = createContext<UserContextType>({user: null, setCurrentUser: null});

export {UserContext};
export type {UserContextType};