import { getFirebase } from "../../FirebaseWrapper";
import getCompanyAffiliation from "../../CompanyAffiliation";
import firebase from "firebase";
import TaskModel from "../../../types/basistypes/ressources/tasks/TaskModel";
import ConstructionSiteModel from "../../../types/basistypes/ressources/ConstructionSiteModel";
import { getDayPlansForDay, getDayPlansForRange } from "../../Planning/DayPlanController";
import TeamModel from "../../../types/TeamModel";
import { getConstructionSiteDocument, resolveConstructionSite } from "../ConstructionSiteController";
import { getTaskTemplateDocument } from "./TaskTemplateController";
import { resolveNestedDocumentList, transformArrayToReference } from "../../FirebaseConverter";
import { registerGenericCollectionListener } from "../../GenericFirebaseSnapshotListener";

const resolveTask = async (doc: any) => {
	let task: any = doc.data();
	let constructionSite = await task.constructionSite.get();
	task.constructionSite = await resolveConstructionSite(constructionSite);
	if (task.taskTemplate) {
		task.taskTemplate = (await resolveNestedDocumentList([task.taskTemplate]))[0];
	}
	return task as TaskModel;
};

const addTask = async (Task: TaskModel) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		let fbDoc: any = Task.taskTemplate
			? {
					...Task,
					constructionSite: await getConstructionSiteDocument(Task.constructionSite.id),
					taskTemplate: await getTaskTemplateDocument(Task.taskTemplate.id),
			  }
			: {
					...Task,
					constructionSite: await getConstructionSiteDocument(Task.constructionSite.id),
			  };
		dbConnection
			.collection(companyId)
			.doc("Task")
			.collection("Task")
			.doc(Task.id.toString())
			.set({ ...fbDoc, usable: true });
	}
};

const registerTaskListenerForConstructionSite = async (
	constructionSite: ConstructionSiteModel,
	updateTaskModelState: (tasksToAdd: TaskModel[], tasksToModify: TaskModel[], tasksToDelete: TaskModel[]) => void
) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		let query = dbConnection
			.collection(companyId)
			.doc("Task")
			.collection("Task")
			.where("constructionSite", "==", await getConstructionSiteDocument(constructionSite.id))
			.where("usable", "==", true);

		return await registerGenericCollectionListener<TaskModel>(query, updateTaskModelState, resolveTask, "ConstructionSite" + constructionSite.id);
	}
};

const deleteTask = async (Task: TaskModel) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		dbConnection.collection(companyId).doc("Task").collection("Task").doc(Task.id.toString()).update({ usable: false });
	}
};

const getValidTasksAtDate = async (date: Date) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		const propertiesOf =
			<TObj>(_obj: TObj | undefined = undefined) =>
			<T extends keyof TObj>(name: T): T =>
				name;
		const myInterfaceProperties = propertiesOf<TaskModel>();
		let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
			.collection(companyId)
			.doc("Task")
			.collection("Task")
			.where("plannedFromDate", "<=", date.getUTCDate())
			.where("plannedToDate", ">=", date.getUTCDate())
			.where("usable", "==", true)
			.get();
		let result: TaskModel[] = [];
		for (let doc of query.docs) {
			result.push(await resolveTask(doc));
		}
		return result;
	}
};

const getValidTasksAtDateForConstructionSite = async (date: number, constructionSites: ConstructionSiteModel[]) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		//Determine all of our Assigned Tasks for the date based on the assignments made
		//in the tagesplan
		let dayPlan = await getDayPlansForDay(date);
		let assignedTasks: TaskModel[] = [];

		if (dayPlan) {
			dayPlan.forEach((tagesPlanModel, index) => {
				tagesPlanModel.teams.forEach((team, index) => {
					team.tasks.forEach((task, index) => {
						assignedTasks.push(task);
					});
				});
			});
		}

		if (constructionSites.length === 0) {
			return [];
		}

		let references = await transformArrayToReference(firebase, dbConnection, companyId, "ConstructionSite", constructionSites);
		let batchReads: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>[] = [];
		//Firebase can only handle 10 arrays with a size of 10 in an "in" operator
		while (references.length > 0) {
			let batchOfTen = references.splice(0, 10);

			batchReads.push(
				dbConnection
					.collection(companyId)
					.doc("Task")
					.collection("Task")
					.where("plannedToDate", ">=", date)
					.where("constructionSite", "in", batchOfTen)
					.where("usable", "==", true)
					.where("resolved", "==", false)
					.get()
			);
		}
		//Query the tasks from firebase
		//that are not already assigned in todays Plan

		let documents = await Promise.all(batchReads);
		let tasks: Promise<TaskModel>[] = [];
		for (let query of documents) {
			for (let doc of query.docs) {
				tasks.push(resolveTask(doc));
			}
		}
		return (await Promise.all(tasks)).filter((task) => !assignedTasks.find((assignedTask) => assignedTask.id === task.id));
	}
	return [];
};

interface TaskToTeamAssignment {
	Task: TaskModel;
	Team: TeamModel;
}

const getSummaryOfTaskAssignmentToTeamsOnDays = async (constructionSite: ConstructionSiteModel) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let returnMap = new Map<number, TaskToTeamAssignment[]>();

	let companyId = await getCompanyAffiliation();
	if (companyId) {
		//retreive all Tagesplane that are within the date range of the construction site
		let affectedDayPlans = await getDayPlansForRange(constructionSite.validFrom, constructionSite.validTo);

		affectedDayPlans.forEach((dayPlan, index) => {
			//Iterate over all Teams
			dayPlan.teams.forEach((team, index) => {
				//Iterate over all Tasks of a Team
				team.tasks.forEach((task: TaskModel, index) => {
					if (task?.constructionSite?.id === constructionSite.id) {
						let arrayForDay = returnMap.get(dayPlan.date);
						if (arrayForDay) {
							returnMap.set(dayPlan.date, [...arrayForDay, { Team: team, Task: task }]);
						} else {
							returnMap.set(dayPlan.date, [{ Team: team, Task: task }]);
						}
					}
				});
			});
		});
		return returnMap;
	}
	return returnMap;
};

const getTasks = async () => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let getCompanyId = await getCompanyAffiliation();
	if (getCompanyId !== undefined) {
		let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
			.collection(getCompanyId)
			.doc("Task")
			.collection("Task")
			.where("usable", "==", true)
			.get();
		let result: TaskModel[] = [];
		query.docs.forEach((doc) => {
			result.push(doc.data() as TaskModel);
		});
		return result;
	}
};

export {
	addTask,
	getTasks,
	deleteTask,
	getValidTasksAtDate,
	registerTaskListenerForConstructionSite,
	getValidTasksAtDateForConstructionSite,
	getSummaryOfTaskAssignmentToTeamsOnDays,
	resolveTask,
};
export type { TaskToTeamAssignment };
