import {useEffect, useState} from "react";
import {getEmployee} from "../model/ModelController/Resources/EmployeeController";
import {getVehicle} from "../model/ModelController/Resources/VehicleController";
import {getEquipment} from "../model/ModelController/Resources/EquipmentController";
import {
    getAppointmentsForRange,
    getEventsThatAreBlockedAndNotUsableAgain
} from "../model/ModelController/times/AppointmentController";
import {AppointmentModel, ResourceType} from "../model/types/basistypes/times/AppointmentModel";
import EmployeeModel from "../model/types/basistypes/ressources/EmployeeModel";
import EquipmentModel from "../model/types/basistypes/ressources/EquipmentModel";
import VehicleModel from "../model/types/basistypes/ressources/VehicleModel";
import {utcTimestampToFormattedISOString} from "../utility/dateUtil";


export default function useAvailability(fromDate: number, toDate: number) {
    const [eventMap, setEventMap] = useState<Map<string, Map<number, AppointmentModel[]>>>(new Map<string, Map<number, AppointmentModel[]>>())
    const [employees, setEmployees] = useState<EmployeeModel[]>([]);
    const [equipment, setEquipment] = useState<EquipmentModel[]>([]);
    const [vehicles, setVehicles] = useState<VehicleModel[]>([]);

    const getEventForResource = (Id: number, EventMap: Map<number, AppointmentModel[]> | undefined) => {
        if (!EventMap)
            return []
        let events = EventMap.get(Id);
        return (events) ? events : [];
    }

    const resourceIsAvailable = (Id: number, dueDate: number) => {
        let events = getEventForResource(Id, eventMap.get(utcTimestampToFormattedISOString(dueDate)));
        //if no entry is found that blocks the Resource
        //the loop won't terminate and we return true
        for (let eventEntry of events) {
            if (!eventEntry.usableOn || (!eventEntry.usableAfter.usable && !eventEntry.usableAfter.reusable)) {
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        const fetchData = async () => {
            setEmployees(await getEmployee());
            setVehicles(await getVehicle());
            setEquipment(await getEquipment());

            let events = await getAppointmentsForRange(ResourceType.EMPLOYEE, fromDate, toDate)

            events = events.concat(await getAppointmentsForRange(ResourceType.VEHICLE, fromDate, toDate))
                .concat(await getAppointmentsForRange(ResourceType.EQUIPMENT, fromDate, toDate))
                .concat(await getEventsThatAreBlockedAndNotUsableAgain(toDate));

            let newEventMap = new Map<string, Map<number, AppointmentModel[]>>();

            events.forEach((localEvent: AppointmentModel, index) => {

                let mapForDay = newEventMap.get(utcTimestampToFormattedISOString(localEvent.date))
                if (!mapForDay) {
                    mapForDay = new Map<number, AppointmentModel[]>();
                }

                //first check whether there already is another event for that resource
                let currentEntry = mapForDay.get(localEvent.resource.id);

                if (currentEntry) {
                    mapForDay.set(localEvent.resource.id, [...currentEntry, localEvent])
                } else {
                    mapForDay.set(localEvent.resource.id, [localEvent]);
                }

                newEventMap.set(utcTimestampToFormattedISOString(localEvent.date), mapForDay);
            })
            setEventMap(newEventMap)
        }
        fetchData()
    }, [fromDate, toDate]);


    const getTotals = (dueDate: number) => {
        let employeeAvailable = employees.filter((employee) => {
            return resourceIsAvailable(employee.id, dueDate)
        })
        let vehicleAvailable = vehicles.filter((vehicles) => {
            return resourceIsAvailable(vehicles.id, dueDate)
        })
        let machineAvailable = equipment.filter((machine) => {
            return resourceIsAvailable(machine.id, dueDate)
        })

        return {
            employees: {
                available: employeeAvailable.length,
                total: employees.length
            },
            vehicles: {
                available: vehicleAvailable.length,
                total: vehicles.length,

            },
            machines: {
                available: machineAvailable.length,
                total: equipment.length,
            }
        }
    }
    return {getTotals, resourceIsAvailable}
}
