import React, {useState} from 'react';
import {getDrivingLicenseClass} from "../../../../model/ModelController/Resources/DrivingLicenseClassController";
import DrivingLicenseClassModel from "../../../../model/types/basistypes/ressources/DrivingLicenseClassModel";
import {Dialog, Divider, Grid, List, ListItemButton, ListItemText, Typography} from "@mui/material"
import {GlobalStyledDialogPaper, GlobalStyledDivider} from "../../../StyledComponents/GlobalStyled/GlobalStyled";
import {useTranslation} from "react-i18next";

interface propsType {
    open: boolean,
    classes: DrivingLicenseClassModel[],

    callBack(fuehrescheinKlasse: DrivingLicenseClassModel): void,

    closeCallback(): void
}

export default function DrivingLicenseClassSelectionDialog(props: propsType) {
    const [registered, setRegistered] = useState(false)
    const [drivingLicenseClassList, setDrivingLicenseClassList] = useState<DrivingLicenseClassModel[]>([])
    const [selectedDrivingLicenseClass, setSelectedDrivingLicenseClass] = useState<DrivingLicenseClassModel | undefined>(undefined)
    const {t, i18n} = useTranslation();

    if (!registered) {
        getDrivingLicenseClass().then(
            (result: DrivingLicenseClassModel[] | undefined) => {
                if (result !== undefined) {
                    setDrivingLicenseClassList(result)
                    setSelectedDrivingLicenseClass(result[0])
                }
            }
        );
        setRegistered(true)
    }

    const filteredDriversLicenceList = drivingLicenseClassList.filter(val => !props.classes.some(item => item.id === val.id));

    const handleListItemClick = (index: number) => {
        setSelectedDrivingLicenseClass(filteredDriversLicenceList[index])
        props.callBack(filteredDriversLicenceList[index]);
        props.closeCallback();
    };

    return (
        <Dialog onClose={props.closeCallback} aria-labelledby="simple-dialog-title" open={props.open}>
            <Grid container xs={12}>
                <GlobalStyledDialogPaper>
                    <Grid item xs={12}>
                        <Typography variant={"h6"} align={"center"}>{t("drivingLicense.chooseLicense")}</Typography>
                        <GlobalStyledDivider/>
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            {filteredDriversLicenceList.map((value, index) => (
                                <Grid container xs={12}>
                                    <Grid item xs={12}>
                                        <ListItemButton onClick={() => handleListItemClick(index)} key={index}>
                                            <ListItemText>
                                                <Typography>{value.description}</Typography>
                                            </ListItemText>
                                        </ListItemButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                </Grid>
                            ))}
                        </List>
                    </Grid>
                </GlobalStyledDialogPaper>
            </Grid>
        </Dialog>
    );
}
