import React, {useEffect, useState} from "react";

import ResponsiveRadarChart, {DataDescriptor} from "./Helper/ResponsiveRadarChart";
import {getEmployee} from "../../model/ModelController/Resources/EmployeeController";
import {getVehicle} from "../../model/ModelController/Resources/VehicleController";
import {getEquipment} from "../../model/ModelController/Resources/EquipmentController";
import {
    getAppointmentsForRange,
    getEventsThatAreBlockedAndNotUsableAgain
} from "../../model/ModelController/times/AppointmentController";
import {AppointmentModel, ResourceType} from "../../model/types/basistypes/times/AppointmentModel";
import {useTranslation} from "react-i18next";
import {
    getCurrentDateAtStartAsUTCTimestamp,
    localDateToUTCTimestamp,
    localDateToUTCTimestampEnd
} from "../../utility/dateUtil";
import EmployeeModel from "../../model/types/basistypes/ressources/EmployeeModel";
import VehicleModel from "../../model/types/basistypes/ressources/VehicleModel";
import EquipmentModel from "../../model/types/basistypes/ressources/EquipmentModel";
import {addDays, subDays} from "date-fns";
import useTheme from "@mui/material/styles/useTheme";


function AvailabilityChart() {
    const theme = useTheme();
    const [possibleCount, setPossibleCount] = useState({employeeCount: 0, vehicleCount: 0, equipmentCount: 0})
    const [availableCount, setAvailableCount] = useState({employeeCount: 0, vehicleCount: 0, equipmentCount: 0})
    const {t} = useTranslation();


    useEffect(() => {
        const fetchData = async () => {
            let promises: Promise<EmployeeModel[] | VehicleModel[] | EquipmentModel[] | AppointmentModel[]>[] = [];
            promises.push(getEmployee());//index 0
            promises.push(getVehicle());//index 1
            promises.push(getEquipment());//index 2

            let fromDate = getCurrentDateAtStartAsUTCTimestamp()
            let startDate = localDateToUTCTimestamp(subDays(fromDate, 15));
            let endDate = localDateToUTCTimestampEnd(addDays(fromDate, 30));


            promises.push(getAppointmentsForRange(ResourceType.EMPLOYEE, startDate, endDate)); //index 3
            promises.push(getAppointmentsForRange(ResourceType.VEHICLE, startDate, endDate)); //index 4
            promises.push(getAppointmentsForRange(ResourceType.EQUIPMENT, fromDate, endDate)); //index 5
            promises.push(getEventsThatAreBlockedAndNotUsableAgain(endDate)) //index 6

            let allResolvedPromises = await Promise.all(promises);
            let employees: EmployeeModel[] = allResolvedPromises[0] as EmployeeModel[];
            let vehicles: VehicleModel[] = allResolvedPromises[1] as VehicleModel[];
            let equipment: EquipmentModel[] = allResolvedPromises[2] as EquipmentModel[];
            let events: AppointmentModel[] = [];

            events = events.concat(
                allResolvedPromises[3] as AppointmentModel[],
                allResolvedPromises[4] as AppointmentModel[],
                allResolvedPromises[5] as AppointmentModel[],
                allResolvedPromises[6] as AppointmentModel[]);

            let newEventMap = new Map<number, AppointmentModel[]>();
            events.forEach((localEvent: AppointmentModel, index) => {
                //first check whether there already is another event for that resource
                let currentEntry = newEventMap.get(localEvent.resource.id);
                if (currentEntry) {
                    newEventMap.set(localEvent.resource.id, [...currentEntry, localEvent])
                } else {
                    newEventMap.set(localEvent.resource.id, [localEvent]);
                }
            })

            const getEventForResource = (Id: number) => {
                let events = newEventMap.get(Id);
                return (events) ? events : [];
            }

            const resourceIsAvailable = (Id: number) => {
                let events = getEventForResource(Id);
                //if no entry is found that blocks the Resource
                //the loop won't terminate and we return true
                for (let eventEntry of events) {
                    if (!eventEntry.usableOn || (!eventEntry.usableAfter.usable && !eventEntry.usableAfter.reusable)) {
                        return false;
                    }
                }
                return true;
            }

            let employeeAvailable = employees.filter((employee) => {
                return resourceIsAvailable(employee.id)
            })

            let vehicleAvailable = vehicles.filter((vehicles) => {
                return resourceIsAvailable(vehicles.id)
            })

            let equipmentAvailable = equipment.filter((equipment) => {
                return resourceIsAvailable(equipment.id)
            })

            setAvailableCount({
                employeeCount: employeeAvailable.length,
                vehicleCount: vehicleAvailable.length,
                equipmentCount: equipmentAvailable.length
            })

            setPossibleCount({
                employeeCount: employees.length,
                vehicleCount: vehicles.length,
                equipmentCount: equipment.length
            })
        }

        fetchData()
    }, [])

    const Data: DataDescriptor[] = [
        {
            dataSubject: t("employee.title"),
            Possible: possibleCount.employeeCount,
            Available: availableCount.employeeCount
        },
        {
            dataSubject: t("vehicle.title"),
            Possible: possibleCount.vehicleCount,
            Available: availableCount.vehicleCount
        },
        {
            dataSubject: t("equipment.title"),
            Possible: possibleCount.equipmentCount,
            Available: availableCount.equipmentCount
        },
    ]

    return (
        <ResponsiveRadarChart title={t("dashboard.todaysAvailability")} data={Data} classes={[
            {
                dataKey: "Possible",
                dataName: t("dashboard.total"),
                fill: theme.palette.info.main,
                stroke: theme.palette.info.main,
                opacity: 0.4
            },
            {
                dataKey: "Available",
                dataName: t("dashboard.currentlyAvailable"),
                fill: theme.palette.warning.main,
                stroke: theme.palette.warning.main,
                opacity: 0.8
            }
        ]}/>
    )
}

export default AvailabilityChart;
