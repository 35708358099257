import ConstructionSiteModel from "../ConstructionSiteModel";
import TaskTemplateModel from "./TaskTemplateModel";

enum TaskDurationUnit{
    Hours = "HOURS",
    Minutes = "MINUTES",
    Days = "DAYS",
}

interface TaskModel {
    id: number,
    title: string,
    taskTemplate?: TaskTemplateModel,
    constructionSite: ConstructionSiteModel,
    description: string,
    resolved: boolean,
    plannedFromDate: number,
    plannedToDate: number,
    plannedDuration: number,
    plannedDurationUnit: TaskDurationUnit

    [key: string]: any;
}



export default TaskModel
export {TaskDurationUnit}

