import React, {useEffect, useRef, useState} from "react";
import AppointmentTypeModel from "../../model/types/basistypes/times/AppointmentTypeModel";
import {addAppointmentType, deleteAppointmentTyp} from "../../model/ModelController/times/TerminTypController";
import useValidator, {proxiedPropertiesOf} from "../ValidatorHook";
import EventTypValidator from "./EventTypValidator";
import {Checkbox, Grid, Typography} from "@mui/material";

import {ArrowBackIos, Delete, Save} from "@mui/icons-material"

import {ResourceStyledFormControlLabel} from "../StyledComponents/ComponentStyled/ResourceStyled"

import {
    GlobalStyledDivider,
    GlobalStyledPaper,
    GlobalStyledTextField
} from "../StyledComponents/GlobalStyled/GlobalStyled";
import {useTranslation} from "react-i18next";
import {Action} from "../ActionBar/GenericActionBarIconButton";
import GenericActionBar from "../ActionBar/GenericActionBar";

interface propsType {
    editEnabled: boolean
    asDialog: boolean
    appointmentType: AppointmentTypeModel

    updateCallback(appointmentType: AppointmentTypeModel): void

    deleteCallback(appointmentType: AppointmentTypeModel): void

    isDirtyCallback?(): void

    closeCallback(): void
}

function AppointmentTypeMask(props: propsType) {
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [localAppointmentType, setLocalAppointmentType] = useState<AppointmentTypeModel>(props.appointmentType)
    const [checked, setChecked] = React.useState({
        checkedEmployee: false,
        checkedVehicle: false,
        checkedEquipment: false,
    });
    const eventTypeRef = useRef(localAppointmentType);
    const updateAppointmentType = (eventType: AppointmentTypeModel) => {
        eventTypeRef.current = eventType;
        setLocalAppointmentType(eventType)
    };
    const fieldDescriptor = proxiedPropertiesOf(localAppointmentType)
    const appointmentTypeValidator = useValidator(EventTypValidator, eventTypeRef.current, eventTypeRef.current.id);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        let localChecked = {...checked, [event.target.name]: event.target.checked}
        setChecked(localChecked);

        let enhancedAppointmentType = {...localAppointmentType}
        enhancedAppointmentType.available = [];
        if (localChecked.checkedEmployee) {
            enhancedAppointmentType.available.push(1)
        }
        if (localChecked.checkedVehicle) {
            enhancedAppointmentType.available.push(2)
        }
        if (localChecked.checkedEquipment) {
            enhancedAppointmentType.available.push(3)
        }
        updateAppointmentType(enhancedAppointmentType);

        if (props.isDirtyCallback) {
            setIsDirty(true);
            props.isDirtyCallback();
        }
    };
    const {t} = useTranslation();


    useEffect(() => {
        if (props.appointmentType) {
            if (props.appointmentType._isNew) {
                setIsDirty(true);
            } else {
                setIsDirty(false);
            }


            let keys = Object.keys(props.appointmentType.available);
            let employee = false;
            let vehicle = false;
            let equipment = false;

            for (let keyIndex in keys) {
                let availability = props.appointmentType.available[parseInt(keys[keyIndex])];
                if (availability === 1)
                    employee = true
                if (availability === 2)
                    vehicle = true
                if (availability === 3)
                    equipment = true
            }

            setChecked({checkedEmployee: employee, checkedVehicle: vehicle, checkedEquipment: equipment})
            updateAppointmentType(props.appointmentType);
        }
    }, [props.appointmentType]);

    if (props.appointmentType === undefined || localAppointmentType === undefined) {
        return (<div/>)
    }

    const wrapCallback = (callback: any, windowmode: boolean) => {
        return (windowmode) ?
            () => {
                callback()
                props.closeCallback();
            } : callback
    }

    const saveToFireBase = () => {
        let errors = appointmentTypeValidator.performFullValidation()
        if (errors.errorCount > 0) {
            const errorMessages = appointmentTypeValidator.getAllErrorMessages(errors.stateCopy);
            alert(errorMessages.join("\n"))
            return;
        }

        addAppointmentType(eventTypeRef.current)
            .then(value => {
                    setIsDirty(false)
                    console.info("TerminTyp angelegt.")
                }
            )
            .catch(e => console.error(e))
        props.updateCallback(eventTypeRef.current)
    }

    const deleteFromFireBase = () => {
        deleteAppointmentTyp(localAppointmentType)
            .then(value =>
                console.info("TerminTyp angelegt.")
            )
            .catch(e => console.error(e))
        props.deleteCallback(localAppointmentType)
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName: string = e.currentTarget.name;
        const newValue = e.currentTarget.value
        let newState = {...localAppointmentType};
        if (props.isDirtyCallback) {
            setIsDirty(true);
            props.isDirtyCallback();
        }
        newState[fieldName] = newValue;
        updateAppointmentType(newState);
    }

    const appointmentTypeActions = [
        {
            icon: <Save/>,
            name: 'Save',
            tooltip: t("actions.save"),
            callback: wrapCallback(saveToFireBase, props.asDialog),
            disabled: !isDirty,
            actionKey: "s"
        } as Action,
        {
            icon: <Delete/>,
            name: 'Delete',
            tooltip: t("actions.delete"),
            callback: wrapCallback(deleteFromFireBase, props.asDialog)
        },
    ];

    if (props.asDialog) {
        appointmentTypeActions.push({
            icon: <ArrowBackIos/>,
            name: 'Back',
            tooltip: t("actions.back"),
            callback: props.closeCallback
        })

    }

    return (
        <Grid container xs={12}>
            <Grid item container xs={12}>
                <GlobalStyledPaper>
                    <Grid item container
                          alignItems={"flex-start"}
                          xs={12}>
                        <GenericActionBar actions={appointmentTypeActions}/>
                        <Grid item xs={12}>
                            <Typography variant={"h6"}
                                        align={"center"}>{t("appointmentTypeMask.appointment") + ": " + localAppointmentType.description}</Typography>
                            <GlobalStyledDivider/>
                        </Grid>

                        <Grid item xs={12} container justifyContent={"center"}>
                            <GlobalStyledTextField
                                name={fieldDescriptor.description}
                                required={true}
                                label={t("description")}
                                value={localAppointmentType?.description}
                                error={appointmentTypeValidator.containsError(fieldDescriptor.description)}
                                helperText={appointmentTypeValidator.getErrorMessage(fieldDescriptor.description)}
                                onChange={appointmentTypeValidator.wrapOnChangeInValidator(onChange)}
                                variant={"outlined"}/>
                        </Grid>
                        <Grid item xs={4}>
                            <ResourceStyledFormControlLabel
                                control={
                                    <Checkbox
                                        checked={localAppointmentType.multiday}
                                        onChange={(event) => {
                                            if (props.isDirtyCallback) {
                                                setIsDirty(true);
                                                props.isDirtyCallback();
                                            }
                                            updateAppointmentType({
                                                ...eventTypeRef.current,
                                                multiday: event.currentTarget.checked
                                            })
                                        }
                                        }
                                        name="checkedMultiday"
                                    />
                                }
                                label={t("appointmentTypeMask.multiday") + ""}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ResourceStyledFormControlLabel
                                control={
                                    <Checkbox
                                        checked={localAppointmentType.usableAfter}
                                        onChange={(event) => {
                                            if (props.isDirtyCallback) {
                                                setIsDirty(true);
                                                props.isDirtyCallback();
                                            }
                                            updateAppointmentType({
                                                ...eventTypeRef.current,
                                                usableAfter: event.currentTarget.checked
                                            })
                                        }
                                        }
                                        name="checkedUsableAfter"
                                    />
                                }
                                label={t("appointmentTypeMask.usableAfter") + ""}
                            />
                        </Grid>
                        <Grid xs={12} sx={{mt: 4}}>

                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"h6"}
                                        align={"center"}> {t("appointmentTypeMask.availableFor") + ": "}</Typography>
                            <GlobalStyledDivider/>
                        </Grid>

                        <Grid item xs={4}>
                            <ResourceStyledFormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked.checkedEmployee}
                                        onChange={handleChange}
                                        name="checkedEmployee"
                                    />
                                }
                                label={t("employee.titlePlural") + ""}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ResourceStyledFormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked.checkedVehicle}
                                        onChange={handleChange}
                                        name="checkedVehicle"
                                    />
                                }
                                label={t("vehicle.titlePlural") + ""}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ResourceStyledFormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked.checkedEquipment}
                                        onChange={handleChange}
                                        name="checkedEquipment"
                                    />
                                }
                                label={t("equipment.titlePlural") + ""}
                            />

                        </Grid>
                    </Grid>
                </GlobalStyledPaper>
            </Grid>
        </Grid>
    )
}

// @ts-ignore
export default AppointmentTypeMask;

