import EquipmentModel from "../../../../model/types/basistypes/ressources/EquipmentModel";
import {Accordion, AccordionDetails, List, ListItem, ListItemButton, ListItemText, Typography} from "@mui/material";
import React, {ReactNode} from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {SidebarStyledAccordionSummary} from "../../../StyledComponents/ComponentStyled/SidebarStyled";


interface propsType {
    equipment: EquipmentModel,
    selected: number,
    setSelected: (equipment: EquipmentModel) => void,
    getListItemText: (resource: any) => ReactNode,
}

const DeepEquipmentListItem = (props: propsType) => {
    return <ListItem key={props.equipment.id} disablePadding={true}>

        <Accordion
            sx={{width: "100%", m: 0.5, "&.Mui-expanded": {m: 0.5, "&:last-of-type": {m: 0.5}}}}
            disableGutters={true}>

            <SidebarStyledAccordionSummary
                expandIcon={props.equipment.components.length > 0 ? <ExpandMoreIcon/> : <div/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{p: 0,}}
            >
                <List disablePadding={true}>
                    <ListItemButton key={props.equipment.id}
                                    sx={{p: 1}}
                                    selected={(props.selected === props.equipment.id)}
                                    disableGutters={true}
                                    onClick={() => {
                                        props.setSelected(props.equipment)
                                    }}>
                        <ListItemText>
                            <Typography>{props.getListItemText(props.equipment) + " (" + props.equipment.components.length + ")"} </Typography>
                        </ListItemText>
                    </ListItemButton>
                </List>
            </SidebarStyledAccordionSummary>
            {
                (props.equipment.hasComponents && props.equipment.components.length > 0) ?
                    <AccordionDetails sx={{pl: 0.5, pr: 0.5, pt: 0, pb: 0.5, backgroundColor: "#25255"}}>
                        <List disablePadding={true}>
                            {props.equipment.components.map((equipment: EquipmentModel) => {
                                return <DeepEquipmentListItem equipment={equipment}
                                                              selected={props.selected}
                                                              setSelected={props.setSelected}
                                                              getListItemText={props.getListItemText}
                                />

                            })
                            }
                        </List>
                    </AccordionDetails> : <></>
            }
        </Accordion>

    </ListItem>


}


export default DeepEquipmentListItem;
