import React, {useEffect, useState} from "react";
import {GlobalStyledTextField} from "../../StyledComponents/GlobalStyled/GlobalStyled";
import {localDateToUTCTimestamp, utcTimestampToFormattedISOString} from "../../../utility/dateUtil";

interface propsType {
    Date: number,
    label: string,
    name?: string,
    onChange: (Date: number) => void,
    error?: boolean,
    helperText?: string,
    disabled?: boolean,

}

export default function DateField(props: propsType) {
    let [date, setDate] = useState(utcTimestampToFormattedISOString(props.Date));

    useEffect(() => {
        setDate(utcTimestampToFormattedISOString(props.Date))
    }, [props.Date])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = e.currentTarget.value
        setDate(newValue);
    }

    return (
        <GlobalStyledTextField
            type={"Date"}
            label={props.label}
            name={props.name}
            value={date}
            disabled={props.disabled}
            required
            error={props.error}
            helperText={props.helperText}
            onChange={onChange}
            onBlur={() => props.onChange(localDateToUTCTimestamp(date))}
            variant={"outlined"}/>
    )
}
