import React, {useContext, useState} from "react";
import DrivingLicenseClassModel from "../../../../model/types/basistypes/ressources/DrivingLicenseClassModel";
import DrivingLicenseClassMask from "./DrivingLicenseClassMask";
import {getDrivingLicenseClass} from "../../../../model/ModelController/Resources/DrivingLicenseClassController";
import {useSnackbar, VariantType, withSnackbar} from "notistack";
import {AppBarInfoAreaContext} from "../../../Sidebar/AppBarInfoAreaContext";
import ResourceLayout from "../Helper/ResourcesLayout";
import ResourceList from "../Helper/ResourceList";
import {useTranslation} from "react-i18next";
import {Prompt} from "react-router-dom";

function DrivingLicenseClassOverview(this: any) {
    const appbarContext = useContext(AppBarInfoAreaContext);

    const [registered, setRegistered] = useState(false)
    const [isDirtyIndex, setIsDirtyIndex] = useState(-1)
    const [drivingLicenseClassList, setDrivingLicenseClassList] = useState<DrivingLicenseClassModel[]>([])
    const [selectedDrivingClass, setSelectedDrivingClass] = useState<number>(0)
    const {enqueueSnackbar} = useSnackbar();
    const [open, setOpen] = useState(false);
    const {t, i18n} = useTranslation();

    appbarContext.updateContent(t("ManageDrivingLicenseClasses"));

    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }

    const updateCallback = (index: number) => {
        return (update: DrivingLicenseClassModel) => {
            update = {...update, _isNew: false}
            let newState: DrivingLicenseClassModel[] = [...drivingLicenseClassList]
            newState[index] = update;
            setDrivingLicenseClassList(newState);
            setIsDirtyIndex(-1);
            showSnackbar("success", t("drivingClassLicense.successfullyCreated", {
                description: update.description
            }))
        }
    }
    const deleteCallback = (index: number) => {
        return (update: DrivingLicenseClassModel) => {
            let newState: DrivingLicenseClassModel[] = drivingLicenseClassList.filter(
                ((value, index1) => {
                    return index1 !== index
                })
            )
            setDrivingLicenseClassList(newState);
            setSelectedDrivingClass(0);
            setIsDirtyIndex(-1);
            showSnackbar("success", t("drivingClassLicense.successfullyDeleted", {
                description: update.description
            }))
        }
    }


    if (!registered) {
        getDrivingLicenseClass().then(
            (result: DrivingLicenseClassModel[] | undefined) => {
                if (result !== undefined)
                    setDrivingLicenseClassList(result)
            }
        );
        setRegistered(true)
    }


    const getEmptyDrivingLicenseClass = (): DrivingLicenseClassModel => {
        return {
            id: Date.now(),
            description: t("drivingClassLicense.placeholder"),
            _isNew: true
        }
    }

    const addDrivingLicenseClass = () => {
        if (discardChangesInputWindowConfirmDialog()) {
            let newDrivingClassList = [...drivingLicenseClassList];
            if (drivingLicenseClassList[selectedDrivingClass]?._isNew) {
                newDrivingClassList.splice(selectedDrivingClass, 1)
            }

            if (drivingLicenseClassList === undefined) {
                let newState: DrivingLicenseClassModel[] = [getEmptyDrivingLicenseClass()];
                setDrivingLicenseClassList(newState)
                setSelectedDrivingClass(newState.length - 1)
                setIsDirtyIndex(-1);
            } else {
                newDrivingClassList.push(getEmptyDrivingLicenseClass());
                setDrivingLicenseClassList(newDrivingClassList)
                setSelectedDrivingClass(newDrivingClassList.length - 1)
                setIsDirtyIndex(-1);
            }
        }
    }

    const setIndex = (resource: any, index: number) => {
        if (selectedDrivingClass === index) {
            return;
        }
        if (discardChangesInputWindowConfirmDialog()) {
            if (drivingLicenseClassList[selectedDrivingClass]?._isNew) {
                let newEmployeeList = [...drivingLicenseClassList];
                newEmployeeList.splice(selectedDrivingClass, 1)
                setSelectedDrivingClass(index);
                setDrivingLicenseClassList(newEmployeeList)
            }
            setSelectedDrivingClass(index);
            setIsDirtyIndex(-1);
        } else {
            //do nothing -> stay on old selected resource
        }
    }

    const discardChangesInputWindowConfirmDialog = () => {
        if (isDirtyIndex !== -1 || drivingLicenseClassList[selectedDrivingClass]?._isNew) {
            return window.confirm(t("discardChangesDialog.text"));
        } else {
            return true;
        }
    }

    return (
        <>
            <Prompt
                when={isDirtyIndex !== -1 || drivingLicenseClassList[selectedDrivingClass]?._isNew === true}
                message={t("discardChangesDialog.leaveOverview")}
            />
            <ResourceLayout
                left={
                    <ResourceList resources={drivingLicenseClassList}
                                  selectedItem={selectedDrivingClass}
                                  getListItemText={(license: DrivingLicenseClassModel) => {
                                      return license.description + ((license._isNew || drivingLicenseClassList[isDirtyIndex]?.id === license.id) ? "*" : "");
                                  }}
                                  resourceClickCallBack={setIndex}
                                  addItemCallback={addDrivingLicenseClass}/>
                }
                right={
                    (drivingLicenseClassList.length > 0) ?
                        <DrivingLicenseClassMask editEnabled={true}
                                                 drivingLicenseClass={drivingLicenseClassList[selectedDrivingClass]}
                                                 updateCallback={updateCallback(selectedDrivingClass)}
                                                 deleteCallback={deleteCallback(selectedDrivingClass)}
                                                 isDirtyCallback={() => setIsDirtyIndex(selectedDrivingClass)}
                                                 asDialog={false}
                                                 closeCallback={() => setOpen(false)}/> : <div/>
                }/>
        </>)
}

export default withSnackbar(DrivingLicenseClassOverview)

