import React, {useContext, useState} from 'react';
import {config} from "./Config";
import SubMenu from './SubMenu';
import useTheme from "@mui/material/styles/useTheme";
import {ArrowBackIosNewRounded, ExpandLess, ExpandMore, Menu,} from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AppBar,
    Avatar,
    Box,
    Grid,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    SwipeableDrawer,
    Switch,
    Toolbar,
    Typography,
    useMediaQuery
} from '@mui/material';
import {AppBarInfoAreaContext} from "./AppBarInfoAreaContext";
import {SidebarStyledAccordionSummary} from "../StyledComponents/ComponentStyled/SidebarStyled";
import {useTranslation} from "react-i18next";
import {getFirebase} from "../../model/ModelController/FirebaseWrapper";
import {ThemeModeContext} from "../Theme/ThemeModeContext";
import {UserContext} from "../Sidebar/UserContext";


const Sidebar = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));
    const [sidebar, setSidebar] = useState(false)
    const showSidebar = () => setSidebar(true)
    const hideSidebar = () => setSidebar(false)
    const [expanded, setExpanded] = React.useState('panel');
    const {t, i18n} = useTranslation();
    const [anchorEl, setAnchor] = useState<Element | null>(null);
    const [popoverOpen, setPopoverOverOpen] = useState(false);
    const context = useContext(AppBarInfoAreaContext)
    const themeContext = useContext(ThemeModeContext)
    const userContext = useContext(UserContext)
    const iOS =
        typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const handleChange = (panel: any) => (event: any, newExpanded: any) => {
        setExpanded(newExpanded ? panel : false);
    };

    function hideAll() {
        hideSidebar();
    }

    return (
        <Box sx={{display: 'flex'}}>
            <AppBar position="sticky" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <Toolbar disableGutters>
                    <Grid container xs={12}>
                        <Grid container xs={2} justifyContent={"center"} alignItems={"center"} direction={"row"}>
                            <Grid item xs={4}>
                                {(!sidebar ?
                                        <IconButton
                                            size={"large"}
                                            onClick={showSidebar}>
                                            <Menu/>
                                        </IconButton> :
                                        <IconButton
                                            size={"large"}
                                            color={"secondary"}
                                            onClick={hideSidebar}>
                                            <ArrowBackIosNewRounded/>
                                        </IconButton>
                                )}
                            </Grid>
                            <Grid item xs={4}>
                                <img
                                    //src={process.env.PUBLIC_URL + "Logo_Final_2022.svg"}
                                    src={"/Logo_Final_2022.png"}
                                    style={{width: "200px"}}/>
                            </Grid>
                        </Grid>
                        <Grid container xs={8}
                              justifyContent={"center"}
                              alignItems={"center"}>
                            <Typography variant={((matchesXs) ? ((matchesXl) ? "h4" : "h6") : "subtitle2")}>
                                {context.content}
                            </Typography>
                        </Grid>
                        <Grid container xs={2}
                              justifyContent={"center"}
                              alignItems={"center"}>
                            <IconButton
                                onClick={(e) => {
                                    setAnchor(e.currentTarget)
                                    setPopoverOverOpen(true)
                                }}>
                                <Avatar sx={{backgroundColor: "secondary.main"}} alt="Remy Sharp" src={""}/>
                            </IconButton>
                            <Popover
                                open={popoverOpen}
                                anchorEl={anchorEl}
                                onClose={() => {
                                    setPopoverOverOpen(false)
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}>
                                <Grid item container xs={12} sx={{mt: 2, mr: 2, ml: 2}}>
                                    <Typography align={"center"}
                                                variant={"subtitle1"}>
                                        {t("sidebar.signedinAs", {loginName: userContext.user?.loginName})}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ListItemButton key={"1"}
                                                    onClick={() => {
                                                        getFirebase().auth().signOut().then(value => console.log("loggedOut successfully"))
                                                    }}>
                                        <ListItemText>
                                            <Typography align={"center"}>
                                                {t("sidebar.signOut")}
                                            </Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                </Grid>
                                <Grid item container xs={12} justifyContent={"center"} sx={{mb: 1}}>
                                    <Typography>En</Typography>
                                    <Switch checked={i18n.language === "de"}
                                            onChange={event => {
                                                if (i18n.language === "de") {
                                                    localStorage.setItem('language', "en");
                                                    i18n.changeLanguage("en")
                                                } else {
                                                    localStorage.setItem('language', "de");
                                                    i18n.changeLanguage("de")
                                                }
                                            }}
                                            inputProps={{'aria-label': 'ant design'}}/>
                                    <Typography>De</Typography>
                                </Grid>
                                <Grid item container xs={12} justifyContent={"center"} sx={{mb: 1}}>
                                    <Typography>{t("theme.light")}</Typography>
                                    <Switch checked={themeContext.mode === "dark"}
                                            onChange={event => {
                                                if (themeContext.mode === "dark") {
                                                    localStorage.setItem('theme-mode', "light");
                                                    themeContext.updateContent("light")
                                                } else {
                                                    localStorage.setItem('theme-mode', "dark");
                                                    themeContext.updateContent("dark")
                                                }
                                            }}
                                            inputProps={{'aria-label': 'ant design'}}/>
                                    <Typography>{t("theme.dark")}</Typography>
                                </Grid>
                            </Popover>
                            <Typography
                                variant={"subtitle2"}> {getFirebase().auth().currentUser?.displayName}
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                anchor={'left'}
                open={sidebar}
                PaperProps={{sx: {backgroundColor: "paper.drawer"}}}
                onOpen={() => {
                }}
                onClose={hideSidebar}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
            >
                <Toolbar/>
                <Box sx={{mt: 3}}>
                    {config.map((item, index) => {
                        if (item.subNav) {
                            return (
                                <Accordion
                                    disableGutters={true}
                                    square expanded={expanded === `panel_${index}`}
                                    onChange={handleChange(`panel_${index}`)}
                                    key={index}
                                    sx={{backgroundColor: "paper.drawer"}}
                                >
                                    <SidebarStyledAccordionSummary>
                                        <List disablePadding={true}
                                        >
                                            <ListItemButton>
                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                <ListItemText>
                                                    <Typography
                                                        variant={((matchesXl) ? "h5" : "h6")}>{t(item.title)}</Typography>
                                                </ListItemText>
                                                {expanded === `panel_${index}` ?
                                                    <ExpandLess/> :
                                                    <ExpandMore/>}
                                            </ListItemButton>
                                        </List>
                                    </SidebarStyledAccordionSummary>
                                    <AccordionDetails>
                                        <SubMenu item={item}
                                                 hideAll={hideAll}/>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }

                    })}
                </Box>
            </SwipeableDrawer>
        </Box>
    )
        ;
};
export default Sidebar;
