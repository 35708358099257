import {FieldValidator, proxiedPropertiesOf, ValidatorType} from "../../../ValidatorHook";
import {CustomerAddressModel} from "../../../../model/types/basistypes/ressources/CustomerModel";

let customerAddress: CustomerAddressModel;

// @ts-ignore
const customerAddressProperties = proxiedPropertiesOf(customerAddress);
const CustomerAddressValidator: FieldValidator[] = [
	{
		FieldName: customerAddressProperties.address,
		Validation: "^[a-zA-Z ]+([0-9]+[a-zA-Z]?)?$",
		Type: ValidatorType.REGEX,
		ErrorMessage: "validator.customer.street",
	},
	{
		FieldName: customerAddressProperties.zipCode,
		Validation: "\\b((?:0[1-46-9]\\d{3})|(?:[1-357-9]\\d{4})|(?:[4][0-24-9]\\d{3})|(?:[6][013-9]\\d{3}))\\b",
		Type: ValidatorType.REGEX,
		ErrorMessage: "validator.customer.zipCode",
	},
	{
		FieldName: customerAddressProperties.city,
		Validation: "^[a-zA-Z ]+$",
		Type: ValidatorType.REGEX,
		ErrorMessage: "validator.customer.address",
	},

];

export default CustomerAddressValidator;
