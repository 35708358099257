import {getFirebase} from "./FirebaseWrapper";
import getCompanyAffiliation from "./CompanyAffiliation";
import {CompanyDetails} from "../types/CompanyDetails";

const getCompanyDetails = async () => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        let doc = await dbConnection.collection("Companies")
            .doc(companyId)
            .get()
        return doc.data() as CompanyDetails;
    }
}

export {getCompanyDetails}