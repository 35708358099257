import AppointmentTypeModel from "./AppointmentTypeModel";
import VehicleModel from "../ressources/VehicleModel";
import EmployeeModel from "../ressources/EmployeeModel";
import EquipmentModel from "../ressources/EquipmentModel";

enum ResourceType {
    EMPLOYEE = 1,
    VEHICLE = 2,
    EQUIPMENT = 3,
}

interface AppointmentModel {
    id: string
    date: number,
    resource: VehicleModel | EmployeeModel | EquipmentModel,
    resourceType: ResourceType,
    appointmentType?: AppointmentTypeModel,
    reusable: boolean,
    appointmentSeriesId: number

    [key: string]: any;
}

// @ts-ignore
export {ResourceType};
// @ts-ignore
export type {AppointmentModel};
