import React from "react";
import {Dialog, Grid} from "@mui/material";

interface mobile {
    mobileActive: boolean,
    open: boolean,
    handleDialogClose: () => void
}

interface propsType {
    left: React.ReactNode,
    right: React.ReactNode,
    mobile?: mobile
}

const ResourceLayout = (props: propsType) => {
    return (
        (!props.mobile?.mobileActive) ?
            <Grid container xs={12}>
                <Grid container item xs={3}>
                    {props.left}
                </Grid>
                <Grid container item xs={9}>
                    {props.right}
                </Grid>
            </Grid> : <Grid container xs={12}>
                {props.left}
                < Dialog open={props.mobile.open} onClose={props.mobile.handleDialogClose}>
                    {props.right}
                </Dialog>
            </Grid>
    )
};

export default ResourceLayout;
