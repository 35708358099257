import {styled} from "@mui/material/styles";
import {bodyHeight,} from "../GlobalStyled/GlobalStyled";
import {Paper} from "@mui/material";

const DayPlanStyledPaper = styled(Paper)(({theme}) => ({
    overflowY: "auto",
    overflowX: "hidden",
    width: `calc(100% - 2*${theme.spacing(1)} )`,
    margin: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: `calc(${bodyHeight} - 4*${theme.spacing(1)})`,

}));

const DailyPlanStyledHeaderPaper = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.paper.dark,
    margin: theme.spacing(1),
}));

export {
    DayPlanStyledPaper,
    DailyPlanStyledHeaderPaper,
}
