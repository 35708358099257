import {
    Legend,
    PolarAngleAxis,
    PolarGrid,
    PolarRadiusAxis,
    Radar,
    RadarChart,
    ResponsiveContainer,
    Tooltip
} from "recharts";
import React from "react";
import {Typography} from "@mui/material";

interface DataDescriptor {
    dataSubject: string,

    [key: string]: any;
}

interface DataClasses {
    dataName: string,
    dataKey: string,
    stroke: string,
    fill: string,
    opacity: number
}

interface propsType {
    data: DataDescriptor[],
    classes: DataClasses[],
    title: string
}


const determineHighValue = (dataDescriptor: DataDescriptor) => {
    let highValue = 0;
    for (let key of Object.keys(dataDescriptor)) {
        if (key === "dataSubject")
            continue;
        if (dataDescriptor[key] > highValue) {
            highValue = dataDescriptor[key]
        }
    }
    return highValue;
}

function ResponsiveRadarChart(props: propsType) {
    let renderValue = 5;
    if (props.data.length !== 0) {
        let maxValue = props.data.reduce((previousValue, currentValue) => {
            let previousHighValue = determineHighValue(previousValue)
            let currentHighValue = determineHighValue(currentValue);

            return (previousHighValue > currentHighValue) ? previousValue : currentValue
        });
        let highValue = determineHighValue(maxValue)
        renderValue = Math.ceil((highValue + 1) / 5) * 5;
    }

    return (
        <div style={{height: "auto", width: "auto"}}>
            <Typography variant={"h6"} align={"center"}>{props.title}</Typography>
            <ResponsiveContainer aspect={1.4}>
                <RadarChart outerRadius={"80%"} data={props.data}>
                    <PolarGrid/>
                    <PolarAngleAxis dataKey="dataSubject"/>
                    <PolarRadiusAxis angle={0} domain={[0, renderValue]}/>
                    {
                        props.classes.map(
                            (dataClass, index) => {
                                return <Radar name={dataClass.dataName} dataKey={dataClass.dataKey}
                                              stroke={dataClass.stroke} fill={dataClass.fill}
                                              opacity={dataClass.opacity}/>
                            }
                        )
                    }
                    <Legend/>
                    <Tooltip/>
                </RadarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default ResponsiveRadarChart;
export type  {DataDescriptor, DataClasses}
