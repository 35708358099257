import {proxiedPropertiesOf, ValidatorType} from "../../../ValidatorHook";
import TaskModel from "../../../../model/types/basistypes/ressources/tasks/TaskModel";

let ConstructionSiteProperties: TaskModel;
const nonEmptyString = "^(?!\\s*$).+";
// @ts-ignore
const properties = proxiedPropertiesOf(ConstructionSiteProperties)

const ConstructionSiteValidator = [
    {
        FieldName: properties.title,
        Validation: nonEmptyString,
        Type: ValidatorType.REGEX,
        ErrorMessage: "validator.nonEmpty"
    },

]

export default ConstructionSiteValidator;
