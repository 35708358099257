import {AppointmentModel, ResourceType} from "../../model/types/basistypes/times/AppointmentModel";
import React, {useEffect, useRef, useState} from "react";
import {registerAppointmentsForResourceListener} from "../../model/ModelController/times/AppointmentController";
import AppointmentMask from "./AppointmentMask";
import VehicleModel from "../../model/types/basistypes/ressources/VehicleModel";
import EquipmentModel from "../../model/types/basistypes/ressources/EquipmentModel";
import EmployeeModel from "../../model/types/basistypes/ressources/EmployeeModel";
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import {Grid} from "@mui/material";
import {localDateToUTCTimestamp} from "../../utility/dateUtil";


interface propsType {
    type: ResourceType
    resource: VehicleModel | EquipmentModel | EmployeeModel
    Id: number
}

function AppointmentList(props: propsType) {
    const [fromDate, setFromDate] = useState<number>(0)
    const [toDate, setToDate] = useState<number>(0)
    const [selected, setSelected] = useState<AppointmentModel>()
    const [open, setOpen] = useState(false)
    const [selectedAppointment, setSelectedAppointment] = useState<AppointmentModel[]>([])
    const appointmentsRef = useRef(selectedAppointment)
    const updateAppointmentRef = (appointments: AppointmentModel[]) => {
        appointmentsRef.current = appointments;
        setSelectedAppointment(appointments);
    }

    useEffect(() => {

        let unsubscribe: any;
        const registerListener = async () => {
            unsubscribe = await registerAppointmentsForResourceListener(props.type, props.resource, fromDate, toDate, (objectsToAdd: AppointmentModel[],
                                                                                                                       ojectsToUpdate: AppointmentModel[],
                                                                                                                       objectsToDelete: AppointmentModel[]) => {
                let copiedAppointments = [...appointmentsRef.current]

                copiedAppointments = copiedAppointments.concat(objectsToAdd);
                for (let updatedObj of ojectsToUpdate) {
                    copiedAppointments = copiedAppointments.map(value => (value.id === updatedObj.id) ? updatedObj : value);
                }
                for (let deletedObj of objectsToDelete) {
                    copiedAppointments = copiedAppointments.filter(value => (value.id !== deletedObj.id))
                }

                updateAppointmentRef(copiedAppointments)
            })
        }
        registerListener();

        return function cleanup() {
            if (unsubscribe) {
                unsubscribe()
                console.log("unsubscribed listener")
            }

            updateAppointmentRef([])
        }

    }, [fromDate, toDate, props.resource.id, props.type, props.resource])

    const handleDateChange = (newFromDate: number, newToDate: number) => {
        if (fromDate !== newFromDate)
            setFromDate(newFromDate)
        if (toDate !== newToDate)
            setToDate(newToDate)
    }

    const getEvents = () => {
        return appointmentsRef.current.map((value) => {
            let date = new Date(localDateToUTCTimestamp(value.date))
            let year = date.getFullYear()
            let month = ((date.getMonth() + 1) > 9) ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1)
            let day = (date.getDate() > 9) ? date.getDate() : "0" + date.getDate()

            return {
                title: value.appointmentType?.description,
                start: year + "-" + month + "-" + day,
                id: value.id.toString()

            }
        })
    }

    const getClickedEvent = (id: string) => {
        for (let localEvent of selectedAppointment) {
            if (localEvent.id === id) {
                return localEvent;
            }
        }
    }

    return (
        <Grid item container xs={12} justifyContent={"center"}
              sx={{
                  width: "100%",
                  ".fc": {
                      width: "100%"
                  },
                  "& .fc .fc-list-event:hover td": {
                      cursor: "pointer",
                      backgroundColor: "action.hover"
                  },
                  ".fc-theme-standard .fc-list-day-cushion": {
                      backgroundColor: "secondary.main"
                  },
                  ".fc .fc-list-event-dot": {
                      borderColor: "white"
                  },
                  ".fc-theme-standard .fc-list": {
                      borderColor: "divider"
                  },
                  ".fc-theme-standard td, .fc-theme-standard th": {
                      borderColor: "divider"
                  },

              }}>
            <FullCalendar
                contentHeight={"auto"}
                plugins={[listPlugin]}
                initialView="listMonth"
                datesSet={arg => {
                    handleDateChange(arg.start.getTime(), arg.end.getTime())
                }}
                events={getEvents()}
                eventClick={arg => {
                    let clickedEvent = getClickedEvent(arg.event.id)
                    if (clickedEvent) {
                        setOpen(true);
                        setSelected(clickedEvent);
                    }
                }}
            />
            {(open && selected) ?
                <AppointmentMask
                    open={open}
                    editEnabled={true}
                    resource={selected.resource}
                    resourceType={selected.resourceType}
                    appointmentSeriesId={selected.appointmentSeriesId}
                    updateCallback={() => setOpen(false)}
                    deleteCallback={() => setOpen(false)}
                    closeCallback={() => setOpen(false)}/> : <div/>}
        </Grid>
    )
}

export default AppointmentList;
