import UserModel from "../model/types/permissions/UserModel";
import Role from "../model/types/permissions/Role";
import Permission from "../model/types/permissions/Permission";

export function getRole(user: UserModel | null, role: String): Role | null {
    if (user == null) {
        return null;
    }
    return (user.role.id === role) ? user.role : null;
}

export function getPermissionFor(role: Role | null | undefined, permissionForEntity: String): Permission {
    const dummyPermission: Permission = {
        entity: "dummy",
        readAccess: false,
        createAccess: false,
        updateAccess: false,
        deleteAccess: false,
    }
    if (role == null) {
        return dummyPermission;
    }
    let index = role.permissions.findIndex(permission => permission.entity === permissionForEntity);
    if (index !== -1) {
        return role.permissions[index];
    }
    return dummyPermission;
}


