import ConstructionSiteModel from "../../../model/types/basistypes/ressources/ConstructionSiteModel";
import EmployeeModel from "../../../model/types/basistypes/ressources/EmployeeModel";
import EquipmentModel from "../../../model/types/basistypes/ressources/EquipmentModel";
import TaskModel from "../../../model/types/basistypes/ressources/tasks/TaskModel";
import VehicleModel from "../../../model/types/basistypes/ressources/VehicleModel";
import TeamModel from "../../../model/types/TeamModel";

const getItemsToAdd = <Type>(array: Type[], resourceDisplayThreshold: number, counter: number) => {
	let localCopy = [...array];
	let difference = counter + localCopy.length - resourceDisplayThreshold;

	if (difference > 0) {
		let addedItems = localCopy.splice(0, array.length - difference);
		return [localCopy as Type[], addedItems as Type[]];
	} else {
		return [[] as Type[], array];
	}
};

interface counts {
	foreman: number;
	employee: number;
	vehicle: number;
	constructionSite: number;
	equipment: number;
}

const handleTeam = (team: TeamModel, resourceDisplayThreshold: number, currentCounts: counts) => {
	const [remainingForemans, foremansToAdd] = getItemsToAdd<EmployeeModel>(team.foremans, resourceDisplayThreshold, currentCounts.foreman);

	const [remainingEmployees, emyployeesToAdd] = getItemsToAdd<EmployeeModel>(team.employees, resourceDisplayThreshold, currentCounts.employee);

	const [remainingVehicles, vehiclesToAdd] = getItemsToAdd<VehicleModel>(team.vehicles, resourceDisplayThreshold, currentCounts.vehicle);

	const [remainingConstructionSite, constructionSitesToAdd] = getItemsToAdd<ConstructionSiteModel>(
		team.constructionSites,
		resourceDisplayThreshold,
		currentCounts.constructionSite
	);

	const [remainingEquipments, equipmentsToAdd] = getItemsToAdd<EquipmentModel>(team.equipment, resourceDisplayThreshold, currentCounts.equipment);

	return [
		{
			id: team.id,
			name: team?.name,
			vehicles: remainingVehicles,
			employees: remainingEmployees,
			equipment: remainingEquipments,
			constructionSites: remainingConstructionSite,
			foremans: remainingForemans,
		} as TeamModel, //remaining Team
		{
			id: team.id,
			name: team?.name,
			vehicles: vehiclesToAdd,
			employees: emyployeesToAdd,
			equipment: equipmentsToAdd,
			constructionSites: constructionSitesToAdd,
			foremans: foremansToAdd,
		} as TeamModel, //addable Team
	];
};

const incrementCounts = (currentCounts: counts, team: TeamModel) => {
	let maxValue = Math.max(team.foremans.length, team.constructionSites.length, team.employees.length, team.equipment.length, team.vehicles.length);
	currentCounts.foreman += maxValue;
	currentCounts.constructionSite += maxValue;
	currentCounts.employee += maxValue;
	currentCounts.equipment += maxValue;
	currentCounts.vehicle += maxValue;

	return currentCounts;
};

const isTeamEmpty = (team: TeamModel) => {
	if (team.constructionSites.length === 0 && team.employees.length === 0 && team.equipment.length === 0 && team.foremans.length === 0 && team.vehicles.length === 0) return true;
	else return false;
};

const getEmptyCounts = () => {
	let counts: counts = {
		foreman: 0,
		employee: 0,
		vehicle: 0,
		constructionSite: 0,
		equipment: 0,
	};

	return counts;
};

function sliceTeams(teams: TeamModel[], resourceDisplayThreshold: number) {
	let slices: Map<number, TeamModel[]> = new Map<number, TeamModel[]>();
	let sliceCount = 0;
	let counts = getEmptyCounts();

	for (let team of teams) {
		let localTeam = { ...team };

		while (!isTeamEmpty(localTeam)) {
			let currentSlice = slices.get(sliceCount);
			if (!currentSlice) {
				currentSlice = [];
			}

			let [remainingTeam, addableTeam] = handleTeam(localTeam, resourceDisplayThreshold, counts);
			if (!isTeamEmpty(addableTeam)) {
				currentSlice.push(addableTeam);
			}
			counts = incrementCounts(counts, addableTeam);
			slices.set(sliceCount, currentSlice);

			localTeam = remainingTeam;
			if (!isTeamEmpty(remainingTeam)) {
				sliceCount++;
				counts = getEmptyCounts();
			}
		}
	}

	return slices;
}

export default sliceTeams;
