import Role from "../../types/permissions/Role";
import {getFirebase} from "../FirebaseWrapper";
import getCompanyAffiliation from "../CompanyAffiliation";
import {purgeUiOnlyFields} from "../FirebaseConverter";


const addRole = async (role: Role) => {
    role = purgeUiOnlyFields(role);

    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    const companyAffiliation = await getCompanyAffiliation()

    if (companyAffiliation) {

        try {
            let success = dbConnection.collection(companyAffiliation).doc("Roles").collection("Roles").doc(role.id).set({
                ...role,
                usable: true
            })

            role.permissions.forEach((value => {
                dbConnection.collection(companyAffiliation).doc("Roles").collection("Roles").doc(role.id).collection("Permissions").doc(value.entity).set(value);

            }))
        } catch (e) {
            console.log(e)
        }


    }
}

const getManagementRoles = async () => {

    let roles: Role[] = [];


    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    const companyAffiliation = await getCompanyAffiliation()

    if (companyAffiliation) {

        try {
            let queryResult = await dbConnection.collection(companyAffiliation)
                .doc("Roles")
                .collection("Roles")
                .where("usable", "==", true)
                .where("assignableAsResponsible", "==", true)
                .get();
            queryResult.docs.forEach(
                doc => {
                    roles.push(<Role>doc.data())
                }
            )

        } catch (e) {
            console.log(e)
        }


    }

    return roles;


}


const getRoles = async () => {

    let roles: Role[] = [];


    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    const companyAffiliation = await getCompanyAffiliation()

    if (companyAffiliation) {

        try {
            let queryResult = await dbConnection.collection(companyAffiliation).doc("Roles").collection("Roles").where("usable", "==", true).get();
            queryResult.docs.forEach(
                doc => {
                    roles.push(<Role>doc.data())
                }
            )

        } catch (e) {
            console.log(e)
        }


    }

    return roles;


}


const deleteRole = async (role: Role) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    const companyAffiliation = await getCompanyAffiliation()

    if (companyAffiliation) {

        try {
            let success = dbConnection.collection(companyAffiliation).doc("Roles").collection("Roles").doc(role.id).update({
                usable: false
            })
        } catch (e) {
            console.log(e)
        }


    }

}


export {addRole, getRoles, deleteRole, getManagementRoles}