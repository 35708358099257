import React, {useContext, useEffect, useRef, useState} from "react";
import EquipmentModel from "../../../../model/types/basistypes/ressources/EquipmentModel";
import EquipmentMask from "./EquipmentMask";
import {useSnackbar, VariantType, withSnackbar} from "notistack";
import {AppBarInfoAreaContext} from "../../../Sidebar/AppBarInfoAreaContext";
import ResourceLayout from "../Helper/ResourcesLayout";
import ResourceList from "../Helper/ResourceList";
import {useTranslation} from "react-i18next";
import {getCurrentDateAsUTCTimestamp} from "../../../../utility/dateUtil";
import {registerUnassignedEquipmentListener} from "../../../../model/ModelController/Resources/EquipmentController";
import {Prompt} from "react-router-dom";

function Equipment(this: any) {
    const appbarContext = useContext(AppBarInfoAreaContext);
    const [isDirtyIndex, setIsDirtyIndex] = useState(-1)
    const [isDirtyEquipment, setIsDirtyEquipment] = useState<EquipmentModel | null>()
    const [equipmentList, setEquipmentList] = useState<EquipmentModel[]>([])
    const [selectedEquipment, setSelectedEquipment] = useState<EquipmentModel>()
    const [selectedEquipmentIndex, setSelectedEquipmentIndex] = useState<number>(0)
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();

    appbarContext.updateContent(t("equipment.manageTitle"));

    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }

    let equipmentRef = useRef(equipmentList);
    const updateEquipmentRef = (equipments: EquipmentModel[]) => {
        equipmentRef.current = equipments;
        setEquipmentList(equipments);
    }

    const updateObjectInList = (list: EquipmentModel[], object: EquipmentModel) => {
        let index = list.findIndex(equipment => equipment.id === object.id);
        if (index >= 0) {
            if (list[index].components.length > object.components.length) {
                for (let equipmentOld of list[index].components) {
                    let index = object.components.find(equipment => equipment.id === equipmentOld.id);
                    if (!index) {
                        // we pass the components that are available again to the global state
                        // as they become available on the top level. We do this without calling
                        // updateEquipmentRef, because we are in the middle of the operation that
                        // reorganizes our tree. For simplicity we don't update the state here
                        // as this will happen afterwards. The state update will take place after
                        // the reorganization has finished.
                        equipmentRef.current.push(equipmentOld);
                    }
                }
            }
            list[index] = object;
        }
        return [list as EquipmentModel[], index];
    }

    const deleteObjectInList = (list: EquipmentModel[], object: EquipmentModel) => {
        let index = list.findIndex(equipment => equipment.id === object.id);
        if (index >= 0) {
            // we pass the components that are available again to the global state
            // as they become available on the top level. We do this without calling
            // updateEquipmentRef, because we are in the middle of the operation that
            // reorganizes our tree. For simplicity we don't update the state here
            // as this will happen afterwards. The state update will take place after
            // the reorganization has finished.
            equipmentRef.current = equipmentRef.current.concat(list[index].components)
            list.splice(index, 1);
        }


        return [list as EquipmentModel[], index];
    }

    const traverseEquipment = async (equipment: EquipmentModel, updateList: EquipmentModel[], deleteList: EquipmentModel[]) => {
        if (!equipment.hasComponents) {
            return equipment;
        }
        let i = 0;
        for (let updatedObject of updateList) {
            let [list, index] = updateObjectInList(equipment.components, updatedObject)
            equipment.components = list as EquipmentModel[];
            if (index) {
                updateList.splice(i, 1);
                i--
            }
            i++;
        }
        i = 0;
        for (let deletedObject of deleteList) {
            let [list, index] = deleteObjectInList(equipment.components, deletedObject)
            equipment.components = list as EquipmentModel[];
            if (index) {
                updateList.splice(i, 1);
                i--;
            }
            i++;
        }

        let promises: Promise<EquipmentModel>[] = equipment.components.map(async (equipment) => {
            return await traverseEquipment(equipment, updateList, deleteList)
        })
        equipment.components = await Promise.all(promises);

        return equipment;

    }
    const buildEquipmentList = (createdObjects: EquipmentModel[], updateObjects: EquipmentModel[], deletedObjects: EquipmentModel[], assignedComponentsId: number[]) => {

        let updateList = async () => {
            equipmentRef.current = equipmentRef.current.concat(createdObjects)

            for (let updateObject of updateObjects) {
                const [list] = updateObjectInList(equipmentRef.current, updateObject);
                equipmentRef.current = list as EquipmentModel[];
            }

            for (let deletedObject of deletedObjects) {
                const [list] = deleteObjectInList(equipmentRef.current, deletedObject);
                equipmentRef.current = list as EquipmentModel[];
            }
            equipmentRef.current = equipmentRef.current.filter(equipment => !assignedComponentsId.find(id => id === equipment.id))

            //We do this because during the reorganization some of the elements might need to be added to our top
            //level. Since traverse Equipment cannot operate on the top level list directly we hand in a copy
            //and merge the arrays afterwards.
            let copy: EquipmentModel[] = equipmentRef.current;
            equipmentRef.current = [];
            let promises: Promise<EquipmentModel>[] = copy.map(async (equipment) => {
                return await traverseEquipment(equipment, updateObjects, deletedObjects)
            })

            updateEquipmentRef((await Promise.all(promises)).concat(equipmentRef.current));
        }
        updateList();

    }

    useEffect(() => {
        let unsubscribe: any;
        let registerListener = async () => {
            unsubscribe = await registerUnassignedEquipmentListener(buildEquipmentList)

        }
        registerListener()

        return function cleanup() {
            if (unsubscribe)
                unsubscribe();
        }

    }, [])

    const updateCallback = (update: EquipmentModel) => {
        resetDirtyFlags();
        showSnackbar("success", t("equipment.successfullyCreated", {
            producer: update.producer,
            type: update.type
        }))
    }


    const deleteCallback = (update: EquipmentModel) => {
        resetDirtyFlags();
        showSnackbar("success", t("equipment.successfullyDeleted", {
            producer: update.producer,
            type: update.type
        }))
    }


    const getEmptyEquipment = (): EquipmentModel => {
        return {
            id: Date.now(),
            producer: t("equipment.placeholder.producer"),
            nickname: "",
            type: t("equipment.placeholder.type"),
            components: [],
            hasComponents: false,
            investmentNumber: "",
            purchaseDate: getCurrentDateAsUTCTimestamp(),
            serialNumber: "",
            _isNew: true
        }
    }

    const addEquipment = () => {
        if (discardChangesInputWindowConfirmDialog()) {
            let newEquipmentList = [...equipmentList];
            if (equipmentList[selectedEquipmentIndex]?._isNew) {
                newEquipmentList.splice(selectedEquipmentIndex, 1)
            }

            if (equipmentList === undefined) {
                let newState: EquipmentModel[] = [getEmptyEquipment()];
                setEquipmentList(newState)
                setSelectedEquipment(newState[newState.length - 1])
                setSelectedEquipmentIndex(newState.length - 1)
                resetDirtyFlags();
            } else {
                newEquipmentList.push(getEmptyEquipment());
                setEquipmentList(newEquipmentList)
                setSelectedEquipment(newEquipmentList[newEquipmentList.length - 1])
                setSelectedEquipmentIndex(newEquipmentList.length - 1)
                resetDirtyFlags();
            }
        }
    }

    function resetDirtyFlags() {
        setIsDirtyIndex(-1);
        setIsDirtyEquipment(null)
    }

    const setIndex = (resource: EquipmentModel, index: number) => {
        if (selectedEquipmentIndex === index && resource.id === selectedEquipment?.id) {
            return;
        }

        if (discardChangesInputWindowConfirmDialog()) {
            if (equipmentList[selectedEquipmentIndex]?._isNew) {
                let newEqupimentList = [...equipmentList];
                newEqupimentList.splice(selectedEquipmentIndex, 1)
                setSelectedEquipmentIndex(index);
                setSelectedEquipment(newEqupimentList[index])
                setEquipmentList(newEqupimentList)
            }
            setSelectedEquipmentIndex(index);
            setSelectedEquipment(resource)
            resetDirtyFlags();
        } else {
            //do nothing -> stay on old selected resource
        }
    }

    const discardChangesInputWindowConfirmDialog = () => {
        if (isDirtyIndex !== -1 || equipmentList[selectedEquipmentIndex]?._isNew) {
            return window.confirm(t("discardChangesDialog.text"));
        } else {
            return true;
        }
    }

    return (
        <>
            <Prompt
                when={isDirtyIndex !== -1 || equipmentList[selectedEquipmentIndex]?._isNew === true}
                message={t("discardChangesDialog.leaveOverview")}
            />
            <ResourceLayout
                left={
                    <ResourceList resources={equipmentList}
                                  selectedItem={selectedEquipment?.id || selectedEquipmentIndex}
                                  getListItemText={(equipment: EquipmentModel) => {
                                      return equipment.producer + "[" + equipment.type + "]" + ((equipment._isNew || equipmentList[isDirtyIndex]?.id === equipment.id || isDirtyEquipment?.id === equipment.id) ? "*" : "")
                                  }}
                                  isEquipment={true}
                                  resourceClickCallBack={setIndex}
                                  addItemCallback={addEquipment}/>
                }
                right={
                    (selectedEquipment) ?
                        <EquipmentMask editEnabled={true} equipment={selectedEquipment}
                                       updateCallback={updateCallback}
                                       deleteCallback={deleteCallback}
                                       isDirtyCallback={() => {
                                           setIsDirtyIndex(selectedEquipmentIndex)
                                           setIsDirtyEquipment(selectedEquipment)
                                       }}
                                       asDialog={false}
                                       closeCallback={() => {
                                       }}/> : <div/>
                }/>
        </>)
}

export default withSnackbar(Equipment)

