import {CustomerContactModel} from "../../../../../model/types/basistypes/ressources/CustomerModel";
import {FieldValidator, proxiedPropertiesOf, ValidatorType} from "../../../../ValidatorHook";

let customerContact: CustomerContactModel;

//@ts-ignore
const customerAddressProperties = proxiedPropertiesOf(customerContact);
const nonEmptyString = "^(?!\\s*$).+";

const CustomerContactEntryValidator: FieldValidator[] = [
	{
		FieldName: customerAddressProperties.name,
		Validation: nonEmptyString,
		Type: ValidatorType.REGEX,
		ErrorMessage: "validator.nonEmpty",
	},
];

export default CustomerContactEntryValidator;
