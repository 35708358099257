import React, {useContext, useEffect, useState} from "react";
import Role from "../../../../model/types/permissions/Role";
import {getRoles} from "../../../../model/ModelController/Permissions/RoleController";
import {AppBarInfoAreaContext} from "../../../Sidebar/AppBarInfoAreaContext";
import ResourceLayout from "../Helper/ResourcesLayout";
import ResourceList from "../Helper/ResourceList";
import {useSnackbar, VariantType, withSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import RoleMask from "./RoleMask";
import Entities from "../../../../model/types/permissions/Entities";
import getPermissionEntities from "../../../../model/ModelController/Permissions/EntitiesController";
import Permission from "../../../../model/types/permissions/Permission";
import {Prompt} from "react-router-dom";

const RoleOverview = () => {
    const appbarContext = useContext(AppBarInfoAreaContext);
    const [roles, setRoles] = useState<Role[]>([]);
    const [isDirtyIndex, setIsDirtyIndex] = useState(-1)
    const [selectedRole, setSelectedRole] = useState<number>(0)
    const [permissionEntities, setPermissionEntities] = useState<Entities>({entities: []})
    const [open, setOpen] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const {t, i18n} = useTranslation();

    appbarContext.updateContent(t("roles.manageRoles"));

    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }

    useEffect(() => {

        getRoles().then((roles: Role[]) => {
            setRoles(roles)
        })

        getPermissionEntities().then(entities => setPermissionEntities(entities))
    }, [])

    const getEmptyRole = (): Role => {
        return {
            id: t("roles.placeholder"),
            assignableAsResponsible: false,
            permissions: permissionEntities.entities.map((value => {
                return {
                    entity: value,
                    createAccess: false,
                    readAccess: false,
                    deleteAccess: false,
                    updateAccess: false
                } as Permission
            })),
            _isNew: true
        }
    }

    const addRole = () => {
        if (discardChangesInputWindowConfirmDialog()) {
            let newRoles = [...roles];
            if (roles[selectedRole]?._isNew) {
                newRoles.splice(selectedRole, 1)
            }

            if (roles.length === 0) {
                let newState: Role[] = [getEmptyRole()];
                setRoles(newState)
                setSelectedRole(newState.length - 1)
                setIsDirtyIndex(-1);
            } else {
                newRoles.push(getEmptyRole());
                setRoles(newRoles)
                setSelectedRole(newRoles.length - 1)
                setIsDirtyIndex(-1);
            }
        }
    }

    const setIndex = (resource: any, index: number) => {
        if (selectedRole === index) {
            return;
        }
        if (discardChangesInputWindowConfirmDialog()) {
            if (roles[selectedRole]?._isNew) {
                let newRoles = [...roles];
                newRoles.splice(selectedRole, 1)
                setSelectedRole(index);
                setRoles(newRoles)
            }
            setSelectedRole(index);
            setIsDirtyIndex(-1);
        } else {
            //do nothing -> stay on old selected resource
        }
    }

    const updateCallback = (index: number) => {
        return (update: Role) => {
            update = {...update, _isNew: false}
            let newState = [...roles]
            newState.splice(index, 1, update)
            setRoles(newState)
            setIsDirtyIndex(-1);
            showSnackbar("success", t("roles.successfullyUpdated", {
                id: update.id
            }))
        }
    }
    const deleteCallback = (index: number) => {
        return (deleted: Role) => {

            let newState = [...roles]
            newState.splice(index, 1)
            setRoles(newState)
            showSnackbar("success", t("roles.successfullyDeleted", {
                id: deleted.id
            }))
            setSelectedRole(0);
            setIsDirtyIndex(-1);
        }
    }

    const discardChangesInputWindowConfirmDialog = () => {
        if (isDirtyIndex !== -1 || roles[selectedRole]?._isNew) {
            return window.confirm(t("discardChangesDialog.text"));
        } else {
            return true;
        }
    }

    return (
        <>
            <Prompt
                when={isDirtyIndex !== -1 || roles[selectedRole]?._isNew === true}
                message={t("discardChangesDialog.leaveOverview")}
            />
            <ResourceLayout
                left={
                    <ResourceList resources={roles}
                                  selectedItem={selectedRole}
                                  getListItemText={(role: Role) => {
                                      return role.id + ((role._isNew || roles[isDirtyIndex]?.id === role.id) ? "*" : "")
                                  }}
                                  resourceClickCallBack={setIndex}
                                  addItemCallback={addRole}/>
                }
                right={
                    (roles.length > 0) ?
                        <RoleMask editEnabled={true} role={roles[selectedRole]}
                                  updateCallback={updateCallback(selectedRole)}
                                  deleteCallback={deleteCallback(selectedRole)}
                                  isDirtyCallback={() => setIsDirtyIndex(selectedRole)}
                                  asDialog={false}
                                  entities={permissionEntities}
                                  closeCallback={() => setOpen(false)}/> : <div/>
                }/>
        </>)
}

export default withSnackbar(RoleOverview)

