import {FieldValidator, proxiedPropertiesOf, ValidatorType} from "../ValidatorHook";
import AppointmentTypeModel from "../../model/types/basistypes/times/AppointmentTypeModel";
import i18n from 'i18next';
import {AppointmentSeriesModel} from "../../model/types/basistypes/times/AppointmentSeriesModel";

let eventSeries: AppointmentSeriesModel;

// @ts-ignore
const properties = proxiedPropertiesOf(eventSeries)
const dateError = i18n.t("validator.eventDialog.dateError");
const classError = i18n.t("validator.eventDialog.classError");


const AppointmentDialog: FieldValidator[] = [
    {
        FieldName: properties.from,
        Validation: "",
        Type: ValidatorType.Function,
        ErrorMessage: dateError
    },
    {
        FieldName: properties.appointmentType,
        Validation: (appointmentType: AppointmentTypeModel) => {
            return (appointmentType !== undefined)
        },
        Type: ValidatorType.Function,
        ErrorMessage: classError
    },
]

export default AppointmentDialog;
