import {Droppable} from "react-beautiful-dnd"
import React, {useEffect} from "react";
import {DnDItem} from "./DndContextWrapper";
import useTheme from "@mui/material/styles/useTheme";


interface propsType {
    Id: string
    children: React.ReactNode
    referencedDnDArray: any[],
    referencedUpdateCallback: (newState: any[]) => void,
    dndType: string,
    registerCallBack: (Id: string, DnDItem: DnDItem) => void;
}

export default function DroppableJSXwrapper(props: propsType) {

    const theme = useTheme();

    const getListStyle = (isDraggingOver: any) => ({
        background: isDraggingOver ? theme.palette.secondary.dark : "transparent",
        minWidth: "100%",
        minHeight: "5vh",
        flexGrow: 1
    });

    useEffect(() => {
        props.registerCallBack(props.Id, {
            updateListCallback: props.referencedUpdateCallback,
            typeDroppable: props.dndType,
            referencedList: [...props.referencedDnDArray]
        })
    }, [props.referencedDnDArray])

    return <Droppable droppableId={props.Id}>
        {(provided, snapshot) => (
            <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}>
                {// @ts-ignore
                    props.children}
                {provided.placeholder}
            </div>
        )}
    </Droppable>
}
