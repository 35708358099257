import React, {createContext} from "react";

interface AppBarInfoAreaContextType {
    content: string,
    updateContent: (newText: string) => void,
}

const initialValue: AppBarInfoAreaContextType = {
    content: "",
    updateContent: (content) => {
    }
}
const AppBarInfoAreaContext = createContext<AppBarInfoAreaContextType>(initialValue);

export {AppBarInfoAreaContext};
export type {AppBarInfoAreaContextType};