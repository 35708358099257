import {getFirebase, getFireStore} from "../FirebaseWrapper";
import firebase from "firebase";
import {AppointmentModel, ResourceType} from "../../types/basistypes/times/AppointmentModel";
import {getAppointmentTypDocument, getAppointmentTypesThatBlockResources} from "./TerminTypController";
import {resolveNestedDocumentList} from "../FirebaseConverter";
import {getEmployeeDocumentId} from "../Resources/EmployeeController";
import {getVehicleDocumentId} from "../Resources/VehicleController";
import {getEquipmentDocumentId} from "../Resources/EquipmentController";
import getCompanyAffiliation from "../CompanyAffiliation";
import {registerGenericCollectionListener} from "../GenericFirebaseSnapshotListener";
import VehicleModel from "../../types/basistypes/ressources/VehicleModel";
import EmployeeModel from "../../types/basistypes/ressources/EmployeeModel";
import EquipmentModel from "../../types/basistypes/ressources/EquipmentModel";


const addAppointment = async (appointment: AppointmentModel) => {
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();

    if (companyId !== undefined) {
        let appointmentFirebase: any = (appointment.appointmentType?.id) ? {
            ...appointment,
            appointmentType: await getAppointmentTypDocument(appointment.appointmentType?.id)
        } : {...appointment};
        let Document: any;
        switch (appointment.resourceType) {
            case ResourceType.EMPLOYEE:
                Document = await getEmployeeDocumentId(appointmentFirebase.resource.id);
                break;
            case ResourceType.VEHICLE:
                Document = await getVehicleDocumentId(appointmentFirebase.resource.id);
                break;
            case ResourceType.EQUIPMENT:
                Document = await getEquipmentDocumentId(appointmentFirebase.resource.id);
        }
        appointmentFirebase = {...appointmentFirebase, resource: Document}
        let id = dbConnection
            .collection(companyId)
            .doc("Appointment")
            .collection("Appointment")
            .doc(appointment.id.toString()).set(appointmentFirebase);

    }
}

const resolveAppointment = async (document: any) => {
    let appointment = document.data() as AppointmentModel;
    if (appointment.appointmentType) {
        appointment.appointmentType = (await resolveNestedDocumentList([appointment.appointmentType]))[0]
        appointment.resource = (await resolveNestedDocumentList([appointment.resource]))[0]
    }
    return appointment;
}

const registerAppointmentsForRangeSnapshotListener = async (resourceType: ResourceType,
                                                            from: number,
                                                            to: number,
                                                            updateCallback: (objectsToAdd: AppointmentModel[],
                                                                             ojectsToUpdate: AppointmentModel[],
                                                                             objectsToDelete: AppointmentModel[]) => void) => {

    const dbConnection = getFireStore()

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        let query: firebase.firestore.Query = await dbConnection.collection(companyId)
            .doc("Appointment")
            .collection("Appointment")
            .where("resourceType", "==", resourceType)
            .where("date", ">=", from)
            .where("date", "<=", to)

        let unsubscribe = registerGenericCollectionListener<AppointmentModel>(query,
            updateCallback,
            resolveAppointment, "Appointmentlistener_" + from + "_" + to)
        return unsubscribe;
    }
}


const registerAppointmentsForResourceListener = async (resourceType: ResourceType,
                                                       resource: VehicleModel | EmployeeModel | EquipmentModel,
                                                       from: number,
                                                       to: number,
                                                       updateCallback: (objectsToAdd: AppointmentModel[],
                                                                        ojectsToUpdate: AppointmentModel[],
                                                                        objectsToDelete: AppointmentModel[]) => void) => {
    const dbConnection = getFireStore()

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        let resourceReference = await ((resourceType === ResourceType.EMPLOYEE) ? getEmployeeDocumentId(resource.id) : (resourceType === ResourceType.VEHICLE) ? getVehicleDocumentId(resource.id) : getEquipmentDocumentId(resource.id))
        let query: firebase.firestore.Query = await dbConnection.collection(companyId)
            .doc("Appointment")
            .collection("Appointment")
            .where("resourceType", "==", resourceType)
            .where("date", ">=", from)
            .where("date", "<=", to)
            .where("resource", "==", resourceReference)
        let unsubscribe = registerGenericCollectionListener<AppointmentModel>(query,
            updateCallback,
            resolveAppointment, "Appointmentlistener_" + from + "_" + to)
        return unsubscribe;
    }
}

const registerBlockedResourceListener = async (resourceType: ResourceType,
                                               date: number,
                                               updateCallback: (objectsToAdd: AppointmentModel[],
                                                                ojectsToUpdate: AppointmentModel[],
                                                                objectsToDelete: AppointmentModel[]) => void) => {
    const dbConnection = getFireStore()

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        const blockingAppointmentTypes = (await getAppointmentTypesThatBlockResources())
        const blockingRefPromises: Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined>[] = []
        for (let blockingType of blockingAppointmentTypes) {
            blockingRefPromises.push(getAppointmentTypDocument(blockingType.id))
        }

        let blockingRef = await Promise.all(blockingRefPromises)

        //We need to subscribe multiple listeners because firebase can only handle 10
        //objects in an "in" clause

        let unsubscribeFunctions: any = [];
        let i: number = 0;
        while (blockingRef.length > 0) {
            let batchOfTen = blockingRef.splice(0, 10);
            let query: firebase.firestore.Query = await dbConnection.collection(companyId)
                .doc("Appointment")
                .collection("Appointment")
                .where("date", "<=", date)
                .where("reusable", "==", false)
                .where("appointmentType", "in", batchOfTen)
            let unsubscribe = registerGenericCollectionListener<AppointmentModel>(query,
                updateCallback,
                resolveAppointment, "AppointmentBlockedlistener_" + "Chunk: " + i + "Date:" + date)
            unsubscribeFunctions.push(unsubscribe);

        }
        return () => {
            for (let unsubscribe of unsubscribeFunctions) {
                try {
                    unsubscribe()
                } catch (e) {
                }
            }
            ;
        }
    }
}

const getAppointmentsForRange = async (resourceType: ResourceType, from: number, to: number) => {
    const dbConnection = getFireStore()

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection.collection(companyId)
            .doc("Appointment")
            .collection("Appointment")
            .where("resourceType", "==", resourceType)
            .where("date", ">=", from)
            .where("date", "<=", to).get()
        let result: AppointmentModel[] = [];
        for (let doc of query.docs) {
            result.push(await resolveAppointment(doc));
        }
        return result;
    }
    return [];
}

const getEventsThatAreBlockedAndNotUsableAgain = async (date: number) => {
    const dbConnection = getFireStore()

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        //retreive all events that flag a resource to be not usable after the due date and that were not marked as
        //reusable again
        const blockingAppointmentTypes = (await getAppointmentTypesThatBlockResources())
        const blockingRef = []
        for (let blockingType of blockingAppointmentTypes) {
            blockingRef.push(await getAppointmentTypDocument(blockingType.id))
        }
        
        let batchReads: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>[] = [];
        //We do this because firebase in and contains-any querys can only
        //contain 10 elements at max
        while (blockingRef.length > 0) {
            let batchOfTen = blockingRef.splice(0, 10);
            batchReads.push(dbConnection.collection(companyId)
                .doc("Appointment")
                .collection("Appointment")
                .where("date", "<=", date)
                .where("reusable", "==", false)
                .where("appointmentType", "in", batchOfTen).get())
        }

        let documents = (await Promise.all(batchReads))
        let result: Promise<AppointmentModel>[] = [];
        for (let query of documents) {
            for (let doc of query.docs) {
                result.push(resolveAppointment(doc))
            }
        }

        return await Promise.all(result);
    }
    return [];
}

const getAppointmentDocument = async (id: string) => {
    const dbConnection = getFireStore()

    let companyId = await getCompanyAffiliation();
    if (companyId) {
        return dbConnection
            .collection(companyId)
            .doc("Appointment")
            .collection("Appointment")
            .doc(id.toString())
    }
}

export {
    addAppointment,
    getAppointmentsForRange,
    getEventsThatAreBlockedAndNotUsableAgain,
    getAppointmentDocument,
    registerAppointmentsForRangeSnapshotListener,
    registerAppointmentsForResourceListener,
    registerBlockedResourceListener
}

