import React, {useContext, useState} from "react";
import EmployeeModel from "../../../../model/types/basistypes/ressources/EmployeeModel";
import EmployeeMask from "./EmployeeMask";
import {getEmployee} from "../../../../model/ModelController/Resources/EmployeeController";
import {useSnackbar, VariantType, withSnackbar} from "notistack";
import {AppBarInfoAreaContext} from "../../../Sidebar/AppBarInfoAreaContext";
import ResourceLayout from "../Helper/ResourcesLayout";
import ResourceList from "../Helper/ResourceList";
import {useTranslation} from "react-i18next";
import {getCurrentDateAsUTCTimestamp} from "../../../../utility/dateUtil";
import {Prompt} from "react-router-dom";


function EmployeeOverview(this: any) {
    const appbarContext = useContext(AppBarInfoAreaContext);
    const [registered, setRegistered] = useState(false)
    const [isDirtyIndex, setIsDirtyIndex] = useState(-1)
    const [employeeList, setEmployeeList] = useState<EmployeeModel[]>([])
    const [selectedEmployee, setSelectedEmployee] = useState<number>(0)
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();


    appbarContext.updateContent(t("employee.manageTitle"));

    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }

    const updateCallback = (index: number) => {
        return (update: EmployeeModel) => {
            update = {...update, _isNew: false}
            let newState: EmployeeModel[] = [...employeeList]
            newState[index] = update;
            setEmployeeList(newState);
            setIsDirtyIndex(-1);
            showSnackbar("success",
                t("employee.successfullyCreated", {
                    surname: update.surname,
                    name: update.surname
                }))
        }
    }

    const deleteCallback = (index: number) => {
        return (update: EmployeeModel) => {
            let newState: EmployeeModel[] = employeeList.filter(
                ((value, index1) => {
                    return index1 !== index
                })
            )
            setEmployeeList(newState);
            setSelectedEmployee(0);
            setIsDirtyIndex(-1);
            showSnackbar("success", t("employee.successfullyDeleted", {
                surname: update.surname,
                name: update.surname
            }))
        }

    }

    if (!registered) {
        getEmployee().then(
            (result: EmployeeModel[] | undefined) => {
                if (result !== undefined)
                    setEmployeeList(result)
            }
        );
        setRegistered(true)
    }


    const getEmptyEmployee = (): EmployeeModel => {
        return {
            id: Date.now(),
            active: true,
            drivingLicenseClasses: [],
            birthday: getCurrentDateAsUTCTimestamp(),
            surname: t("employee.placeholder.surname"),
            name: t("employee.placeholder.name"),
            _isNew: true
        }
    }

    const addEmployee = () => {
        if (discardChangesInputWindowConfirmDialog()) {
            let newEmployeeList = [...employeeList];
            if (employeeList[selectedEmployee]?._isNew) {
                newEmployeeList.splice(selectedEmployee, 1)
            }

            if (employeeList === undefined) {
                let newState: EmployeeModel[] = [getEmptyEmployee()];
                setEmployeeList(newState)
                setSelectedEmployee(newState.length - 1)
                setIsDirtyIndex(-1);
            } else {
                newEmployeeList.push(getEmptyEmployee());
                setEmployeeList(newEmployeeList)
                setSelectedEmployee(newEmployeeList.length - 1)
                setIsDirtyIndex(-1);
            }

        }
    }

    const setIndex = (resource: any, index: number) => {
        if (selectedEmployee === index) {
            return;
        }
        if (discardChangesInputWindowConfirmDialog()) {
            if (employeeList[selectedEmployee]?._isNew) {
                let newEmployeeList = [...employeeList];
                newEmployeeList.splice(selectedEmployee, 1)
                setSelectedEmployee(index);
                setEmployeeList(newEmployeeList)
            }
            setSelectedEmployee(index);
            setIsDirtyIndex(-1);
        } else {
            //do nothing -> stay on old selected resource
        }
    }

    const discardChangesInputWindowConfirmDialog = () => {
        if (isDirtyIndex !== -1 || employeeList[selectedEmployee]?._isNew) {
            return window.confirm(t("discardChangesDialog.text"));
        } else {
            return true;
        }
    }


    return (
        <>
            <Prompt
                when={isDirtyIndex !== -1 || employeeList[selectedEmployee]?._isNew === true}
                message={t("discardChangesDialog.leaveOverview")}
            />
            <ResourceLayout
                left={
                    <ResourceList resources={employeeList}
                                  selectedItem={selectedEmployee}
                                  getListItemText={(employee: EmployeeModel) => {
                                      return employee.surname + ", " + employee.name + ((employee._isNew || employeeList[isDirtyIndex]?.id === employee.id) ? "*" : "")
                                  }}
                                  resourceClickCallBack={setIndex}

                                  addItemCallback={addEmployee}/>
                }
                right={
                    (employeeList.length > 0) ?
                        <EmployeeMask editEnabled={true} employee={employeeList[selectedEmployee]}
                                      updateCallback={updateCallback(selectedEmployee)}
                                      deleteCallback={deleteCallback(selectedEmployee)}
                                      isDirtyCallback={() => setIsDirtyIndex(selectedEmployee)}
                                      asDialog={false}
                                      closeCallback={() => {
                                      }}/> : <div/>

                }/>
        </>)
}

export default withSnackbar(EmployeeOverview)
