import {bodyHeight} from "../GlobalStyled/GlobalStyled";
import {styled} from "@mui/material/styles";
import {Chip, Divider, Fab, FormControlLabel, List, Paper} from "@mui/material";

const ResourceStyledMachinePaper = styled(Paper)(({theme}) => ({
    overflowY: "auto",
    width: `calc(100% - 4*${theme.spacing(1)} )`,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    height: `calc( ( ${bodyHeight} / 2 ) - ( 4 * ${theme.spacing(1)} ) )`,
}));

const ResourceStyledPickList = styled(List)(({theme}) => ({
    width: `calc(100% - 2*${theme.spacing(1)})`
}));

const ResourceStyledFab = styled(Fab)({
    opacity: "100%",
    zIndex: 1000,
});

const ResourceStyledDriversLicenceChip = styled(Chip)(({theme}) => ({
    margin: theme.spacing(1),
}));

const ResourceStyledDivider = styled(Divider)(({theme}) => ({
    marginBottom: theme.spacing(1),
}));

const ResourceStyledFormControlLabel = styled(FormControlLabel)(({theme}) => ({
    marginLeft: theme.spacing(1),
}));

export {
    ResourceStyledPickList,
    ResourceStyledFab,
    ResourceStyledDriversLicenceChip,
    ResourceStyledDivider,
    ResourceStyledFormControlLabel,
    ResourceStyledMachinePaper
};

