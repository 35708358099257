import React from "react";
import {
	BusinessRounded,
	CalendarTodayRounded,
	DateRangeRounded,
	DirectionsBusRounded,
	EventAvailableRounded,
	GroupsRounded,
	HandymanRounded,
	InsertChart,
	SettingsRounded,
	StickyNote2Rounded,
	TaskRounded,
	TodayRounded,
} from "@mui/icons-material";
import { getCurrentDateAsUTCTimestamp } from "../../utility/dateUtil";

export const config = [
	{
		title: "sidebar.readOnly.title",
		icon: <TodayRounded />,
		subNav: [
			{
				title: "sidebar.readOnly.dayplan",
				path: "/work-schedule/view/" + new Date(getCurrentDateAsUTCTimestamp()).toISOString().split("T")[0],
				icon: <TodayRounded />,
				state: false,
			},
		],
	},
	{
		title: "sidebar.workPlanning.title",
		icon: <CalendarTodayRounded />,
		subNav: [
			{
				title: "sidebar.workPlanning.dashboard",
				path: "/dashboard",
				icon: <InsertChart />,
				state: false,
			},
			{
				title: "sidebar.workPlanning.dayplan",
				path: "/work-schedule/daily",
				icon: <TodayRounded />,
				state: false,
			},
			/*{
                    title: "sidebar.workPlanning.weeklyService",
                    path: "/work-schedule/weekly",
                    icon: <DateRangeRounded/>,
                    state: false,
                },*/
			{
				title: "sidebar.workPlanning.appointmentCalendar",
				path: "/work-schedule/calendar",
				icon: <DateRangeRounded />,
				state: false,
			},
		],
	},
	{
		title: "sidebar.ressources.title",
		icon: <BusinessRounded />,
		subNav: [
			{
				title: "sidebar.ressources.employees",
				path: "/resources/employees",
				icon: <GroupsRounded />,
				state: false,
			},
			{
				title: "sidebar.ressources.vehicles",
				path: "/resources/vehicles",
				icon: <DirectionsBusRounded />,
				state: false,
			},
			{
				title: "sidebar.ressources.equipment",
				path: "/resources/equipments",
				icon: <HandymanRounded />,
				state: false,
			},
		],
	},
	{
		title: "sidebar.constructionSite.title",
		icon: <EventAvailableRounded />,
		subNav: [
			{
				title: "sidebar.constructionSite.manageConstructionSites",
				path: "/construction-site/construction-site-type",
				icon: <HandymanRounded />,
				state: false,
			},
		],
	},
	{
		title: "sidebar.administration.title",
		icon: <EventAvailableRounded />,
		subNav: [
			{
				title: "sidebar.administration.manageCustomer",
				path: "/administration/customer",
				icon: <TaskRounded />,
				state: false,
			},
			{
				title: "sidebar.administration.manageTasks",
				path: "/administration/task-types",
				icon: <TaskRounded />,
				state: false,
			},
			{
				title: "sidebar.administration.manageAppointmentTypes",
				path: "/administration/appointment-types",
				icon: <SettingsRounded />,
				state: false,
			},
			{
				title: "sidebar.administration.manageDrivingLicenseClasses",
				path: "/administration/drivers-licences",
				icon: <StickyNote2Rounded />,
				state: false,
			},
			{
				title: "sidebar.administration.manageUsers",
				path: "/administration/users",
				icon: <StickyNote2Rounded />,
				state: false,
			},
			{
				title: "sidebar.administration.manageRoles",
				path: "/administration/roles",
				icon: <StickyNote2Rounded />,
				state: false,
			},
		],
	},
];
