import React from 'react';
import {PDFDownloadLink} from "@react-pdf/renderer";
import {useTranslation} from "react-i18next";
import GenericActionBarIconButton, {Action} from "../ActionBar/GenericActionBarIconButton";

interface propsType {
    printDocument: React.ReactElement,
    printFileName: string,
    icon: React.ReactNode;
    action: Action;
}


export default function PDFSpeedDialAction(props: propsType) {
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <label>
                <PDFDownloadLink style={{height: "1.5em", width: "1.5em"}}
                                 document={props.printDocument} fileName={props.printFileName}>
                    <GenericActionBarIconButton name={props.action.name} callback={props.action.callback}
                                                icon={props.action.icon}
                                                disabled={props.action.disabled}
                                                actionKey={props.action.actionKey}
                                                tooltip={props.action.tooltip}/>
                </PDFDownloadLink>
            </label>
        </React.Fragment>
    );
}

