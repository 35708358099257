import {getFirebase} from "../FirebaseWrapper";
import Entities from "../../types/permissions/Entities";


const getPermissionEntities = async () => {
    let permissionEntities: Entities = {entities: []};
    const firebase = getFirebase()
    const dbConnection = firebase.firestore();


    try {


        let doc = await dbConnection.collection("Permissions").doc("Entities").get();
        permissionEntities = doc.data() as Entities;
        return permissionEntities

    } catch (e) {
        console.log(e);

    }

    return permissionEntities;


}


export default getPermissionEntities;