import firebase from "firebase";


const peach = require('parallel-each');

// Call this method first when storing data to firebase to get rid of all ui only fields
// Ui only fields are strictly prefixed with "_"
// No ui only field should ever enter the firebase
const purgeUiOnlyFields: any = (resource: any) => {
    let resourceCopy: any = {...resource}
    let keysOfResource: Array<string> = Object.keys(resource);
    let uiOnlyKeys: Array<string> = keysOfResource.filter((key: string) => key.startsWith("_"));

    uiOnlyKeys.forEach((uiOnlyKey: string) => delete resourceCopy[uiOnlyKey]);
    return resourceCopy;
}

const transformArrayToReference = async (firebase: any, dbConnection: any, companyId: string, path: string, list: any[]) => {
    let documents: firebase.firestore.DocumentReference[] = [];
    if (companyId !== undefined) {
        list.forEach((item) => {
            if (companyId)
                documents.push(dbConnection
                    .collection(companyId)
                    .doc(path)
                    .collection(path)
                    .doc(item.id.toString()))
        })
    }
    return documents;
}

let lastCallArray = new Map<firebase.firestore.DocumentReference, number>()
let threshold = 10000

const resolveNestedDocumentList = async (documents: any[]) => {
    let result: any[] = [];
    let type: firebase.firestore.DocumentReference;
    await peach(documents, async (document: any, index: any) => {
        let servedFrom: string = "server";
        if (document.id) {
            let lastCall = lastCallArray.get(document.id);

            if (lastCall) {
                if ((Date.now() - lastCall) > threshold) {
                    servedFrom = "server";
                } else {
                    servedFrom = "cache";
                }
            } else {
                servedFrom = "server"
            }
            lastCallArray.set(document.id, Date.now())
        }

        let itemFetched = await document.get({source: servedFrom});
        if (!itemFetched && servedFrom === "cached") {
            itemFetched = await document.get();
        }

        const objData = itemFetched.data();
        if (objData) {
            let keys = Object.keys(objData);

            await peach(keys, async (key: any, index: any) => {
                let value = objData[key];
                if (typeof value === typeof document) {
                    if (value?.length > 0) {
                        try {
                            objData[key] = await resolveNestedDocumentList(value)
                        } catch (e) {
                            objData[key] = value;
                        }
                    } else {
                        try {
                            // @ts-ignore
                            if (value.length === 0) {
                                objData[key] = value;
                            } else {
                                objData[key] = (await resolveNestedDocumentList([value]))[0];
                            }
                        } catch (e) {
                            objData[key] = value;
                        }
                    }
                    return;
                }
                if (typeof value === typeof type) {
                    objData[key] = await value.get();
                }
            }, 100)
        }
        result.push(objData)
    }, 100)

    return result;
}


export {resolveNestedDocumentList, transformArrayToReference, purgeUiOnlyFields};
