import {styled} from "@mui/material/styles";
import {Divider, Paper, TextField,} from "@mui/material";

const headerHeight = "7vh";
const bodyHeight = "93vh";
const DialogWidth = "35vw";

const GlobalStyledDivider = styled(Divider)(({theme}) => ({
    marginBottom: theme.spacing(1)
}));

const GlobalStyledTextField = styled(TextField)(({theme}) => ({
    margin: theme.spacing(1),
    width: `calc(100% - 4*${theme.spacing(1)})`,
    padding: `calc(1/2*${theme.spacing(1)})`,
}));

const GlobalStyledPaper = styled(Paper)(({theme}) => ({
    overflowY: "auto",
    width: `calc(100% - 4*${theme.spacing(1)} )`,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    height: `calc(${bodyHeight} - 4*${theme.spacing(1)})`,
}));

const GlobalStyledDialogPaper = styled(Paper)(({theme}) => ({
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: theme.palette.paper.light,
    maxHeight: `calc(${bodyHeight} - 4*${theme.spacing(1)})`,
    height: "auto",
    width: `calc(${DialogWidth} - 2*${theme.spacing(1)} )`,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
}));

const GlobalStyledHalfHeightPaper = styled(Paper)(({theme}) => ({
    overflow: "hidden",
    width: `calc(100% - 4*${theme.spacing(1)} )`,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    height: `calc( ( ${bodyHeight} / 2 ) - ( 4 * ${theme.spacing(1)} ) )`,
}));


export {
    headerHeight,
    bodyHeight,
    GlobalStyledDivider,
    GlobalStyledTextField,
    GlobalStyledPaper,
    GlobalStyledDialogPaper,
    GlobalStyledHalfHeightPaper,
};




