import React, {useContext, useEffect, useState} from "react";
import ConstructionSiteModel from "../../../../model/types/basistypes/ressources/ConstructionSiteModel";
import ConstructionSiteMask from "./ConstructionSiteMask";
import {getConstructionSite} from "../../../../model/ModelController/Resources/ConstructionSiteController";
import {useSnackbar, VariantType, withSnackbar} from "notistack";
import {AppBarInfoAreaContext} from "../../../Sidebar/AppBarInfoAreaContext";
import ResourceLayout from "../Helper/ResourcesLayout";
import ResourceList from "../Helper/ResourceList";
import {useTranslation} from "react-i18next";
import {getCurrentDateAsUTCTimestamp} from "../../../../utility/dateUtil";
import EmployeeModel from "../../../../model/types/basistypes/ressources/EmployeeModel";
import {getEmployeeFromLoggedInUserIfApplicableOfBeingAssignedAsConstructionManager} from "../../../../model/ModelController/Resources/EmployeeController";
import {Prompt} from "react-router-dom";

function ConstructionSiteOverview(this: any) {
    const [registered, setRegistered] = useState(false)
    const [isDirtyIndex, setIsDirtyIndex] = useState(-1)
    const [constructionSiteList, setConstructionSiteList] = useState<ConstructionSiteModel[]>([])
    const [employeeIfApplicableOfBeingAssignedAsConstructionManager, setEmployeeIfApplicableOfBeingAssignedAsConstructionManager] = useState<EmployeeModel | undefined>()
    const [selectedConstructionSite, setSelectedConstructionSite] = useState<number>(0)
    const {enqueueSnackbar} = useSnackbar();
    const appbarContext = useContext(AppBarInfoAreaContext);
    const {t} = useTranslation();

    appbarContext.updateContent(t("ManageConstructionSites"));

    useEffect(() => {
        getEmployeeFromLoggedInUserIfApplicableOfBeingAssignedAsConstructionManager()
            .then(employee => {
                setEmployeeIfApplicableOfBeingAssignedAsConstructionManager(employee)
            })
    }, []);

    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }

    const updateCallback = (index: number) => {
        return (update: ConstructionSiteModel) => {
            update = {...update, _isNew: false}
            let newState: ConstructionSiteModel[] = [...constructionSiteList]
            newState[index] = update;
            setConstructionSiteList(newState);
            setIsDirtyIndex(-1);

            showSnackbar("success", t("constructionSite.successfullyCreated", {
                title: update.title
            }))
        }
    }

    const deleteCallback = (index: number) => {
        return (update: ConstructionSiteModel) => {
            let newState: ConstructionSiteModel[] = constructionSiteList.filter(
                ((value, index1) => {
                    return index1 !== index
                })
            )
            setConstructionSiteList(newState);
            setSelectedConstructionSite(0);
            setIsDirtyIndex(-1);
            showSnackbar("success", t("constructionSite.successfullyDeleted", {
                title: update.title
            }))
        }
    }

    if (!registered) {
        getConstructionSite().then(
            (result: ConstructionSiteModel[] | undefined) => {
                if (result !== undefined) {
                    setConstructionSiteList(result)
                }
            }
        );
        setRegistered(true)
    }


    const getEmptyConstructionSite = () => {
        let newConstructionSite: ConstructionSiteModel = {
            id: Date.now(),
            title: t("constructionSite.placeholder"),
            validTo: getCurrentDateAsUTCTimestamp(),
            validFrom: getCurrentDateAsUTCTimestamp(),
            constructionManagers: (employeeIfApplicableOfBeingAssignedAsConstructionManager?.id) ? [employeeIfApplicableOfBeingAssignedAsConstructionManager] : [],
            _isNew: true
        }
        return newConstructionSite
    }

    const addConstructionSite = () => {
        if (discardChangesInputWindowConfirmDialog()) {
            let newConstructionSiteList = [...constructionSiteList];
            if (constructionSiteList[selectedConstructionSite]?._isNew) {
                newConstructionSiteList.splice(selectedConstructionSite, 1)
            }
            if (constructionSiteList === undefined) {
                let newState: ConstructionSiteModel[] = [getEmptyConstructionSite()];
                setConstructionSiteList(newState)
                setSelectedConstructionSite(newState.length - 1)
                setIsDirtyIndex(-1);

            } else {
                newConstructionSiteList.push(getEmptyConstructionSite());
                setConstructionSiteList(newConstructionSiteList)
                setSelectedConstructionSite(newConstructionSiteList.length - 1)
                setIsDirtyIndex(-1);

            }
        }
    }

    const setIndex = (resource: any, index: number) => {
        if (selectedConstructionSite === index) {
            return;
        }
        if (discardChangesInputWindowConfirmDialog()) {
            if (constructionSiteList[selectedConstructionSite]?._isNew) {
                let newConstructionSiteList = [...constructionSiteList];
                newConstructionSiteList.splice(selectedConstructionSite, 1)
                setSelectedConstructionSite(index);
                setConstructionSiteList(newConstructionSiteList)
            }
            setSelectedConstructionSite(index);
            setIsDirtyIndex(-1);
        } else {
            //do nothing -> stay on old selected resource
        }
    }

    const discardChangesInputWindowConfirmDialog = () => {
        if (isDirtyIndex !== -1 || constructionSiteList[selectedConstructionSite]?._isNew) {
            return window.confirm(t("discardChangesDialog.text"));
        } else {
            return true;
        }
    }

    return (
        <>
            <Prompt
                when={isDirtyIndex !== -1 || constructionSiteList[selectedConstructionSite]?._isNew === true}
                message={t("discardChangesDialog.leaveOverview")}
            />
            <ResourceLayout
                left={
                    <ResourceList resources={constructionSiteList}
                                  selectedItem={selectedConstructionSite}
                                  getListItemText={(constructionSite: ConstructionSiteModel) => {
                                      return constructionSite.title + ((constructionSite._isNew || constructionSiteList[isDirtyIndex]?.id === constructionSite.id) ? "*" : "")
                                  }}
                                  initialFilterValue={employeeIfApplicableOfBeingAssignedAsConstructionManager?.surname}
                                  resourceClickCallBack={setIndex}
                                  addItemCallback={addConstructionSite}/>
                }
                right={
                    (constructionSiteList.length > 0) ?
                        <ConstructionSiteMask editEnabled={true}
                                              ConstructionSite={constructionSiteList[selectedConstructionSite]}
                                              currentEmployee={employeeIfApplicableOfBeingAssignedAsConstructionManager}
                                              updateCallback={updateCallback(selectedConstructionSite)}
                                              deleteCallback={deleteCallback(selectedConstructionSite)}
                                              isDirtyCallback={() => setIsDirtyIndex(selectedConstructionSite)}
                                              asDialog={false}
                                              closeCallback={() => {
                                              }}/> : <div/>
                }/>
        </>)
}

export default withSnackbar(ConstructionSiteOverview)

