import Sidebar from "./components/Sidebar/Sidebar";
import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import EmployeeOverview from "./components/Administration/Resources/Employees/EmployeeOverview";
import {SnackbarProvider} from "notistack";
import VehicleOverview from "./components/Administration/Resources/Vehicles/VehicleOverview";
import EquipmentOverview from "./components/Administration/Resources/Equipment/EquipmentOverview";
import DrivingLicenseClassOverview
	from "./components/Administration/Resources/DrivingLicenseClasses/DrivingLicenseClassOverview";
import DayPlanWrapper from "./components/Planning/DayPlanWrapper";
import AppointmentTypeOverview from "./components/Time/AppointmentTypeOverview";
import AppointmentCalendar from "./components/Time/AppointmentCalendar";
import ConstructionSiteOverview from "./components/Administration/Resources/ConstructionSite/ConstructionSiteOverview";
import TaskTemplateOverview from "./components/Administration/Resources/tasks/TaskTemplate/TaskTemplateOverview";
import {ThemeProvider} from "@mui/material/styles";
import {getTheme} from "./components/Theme/MainTheme/MainTheme";
import {AppBarInfoAreaContext} from "./components/Sidebar/AppBarInfoAreaContext";
import Dashboard from "./components/Dashboard/Dashboard";
import "./i18n";

import {useTranslation} from "react-i18next";
import DayPlanView from "./components/Planning/DayPlanView";
import RoleOverview from "./components/Administration/Resources/Permissions/RoleOverview";
import UserAdministration from "./components/Administration/Resources/Permissions/UserAdministration";
import SignIn from "./components/SignIn";
import NotVerifiedScreen from "./components/NotVerifiedScreen";
import firebase from "firebase";
import {ThemeModeContext} from "./components/Theme/ThemeModeContext";
import {Box} from "@mui/material";
import {UserContext} from "./components/Sidebar/UserContext";
import {getUser} from "./model/ModelController/Permissions/UserController";
import UserModel from "./model/types/permissions/UserModel";
import {getFirebase} from "./model/ModelController/FirebaseWrapper";
import CustomerOverview from "./components/Administration/Resources/Customer/CustomerOverview";

function App() {
	const {t, i18n} = useTranslation();

	const [appBarInfoArea, setAppBarInfoArea] = useState<string>(t("welcomeTitle"));
	const [themeMode, setThemeMode] = useState<string>("dark");
	const [currentUser, setCurrentUser] = useState<UserModel | null>(null);

	useEffect(() => {
		restoreSettingsFromLocalStorage();
		getFirebase()
			.auth()
			.onAuthStateChanged((user) => {
				if (user !== null) {
					user.getIdToken().then((token) => console.log("Token", token));
				}
				loginCallback(user);
			});
	}, []);

	const restoreSettingsFromLocalStorage = () => {
		const themeModeFromLocalStorage = localStorage.getItem('theme-mode');
		if (themeModeFromLocalStorage) {
			setThemeMode(themeModeFromLocalStorage);
		}


		const languageSettingsFromLocalStorage = localStorage.getItem('language');
		if (languageSettingsFromLocalStorage) {
			i18n.changeLanguage(languageSettingsFromLocalStorage);
		}
	}

	const loginCallback = (user: firebase.User | null) => {
		if (user == null) {
			setCurrentUser(null);
		}
		if (user?.uid) {
			getUser(user?.uid).then((userWithRoles) => {
				if (userWithRoles === undefined) {
					setCurrentUser(null);
				} else {
					setCurrentUser(userWithRoles);
				}
			});
		}
	};

	return (
		<AppBarInfoAreaContext.Provider value={{ content: appBarInfoArea, updateContent: setAppBarInfoArea }}>
			<ThemeModeContext.Provider value={{ mode: themeMode, updateContent: setThemeMode }}>
				<UserContext.Provider value={{ user: currentUser, setCurrentUser }}>
					<ThemeProvider theme={getTheme(themeMode)}>
						<SnackbarProvider>
							<Router>
								{currentUser == null ? (
									<SignIn loggedInCallback={loginCallback} />
								) : //Wenn der User nicht eingeloggt ist wird immer
								//die SignIn Seite gerendert.
								firebase.auth().currentUser?.emailVerified ? ( //In das Div kommt alles für eingeloggte User
									<Box
										sx={{
											display: "absolute",
											height: "100vh",
											width: "100vw",
											overflow: "hidden",
											backgroundColor: "paper.default",
										}}
									>
										<Sidebar />
										<Switch>
											<Route path={"/dashboard"} exact component={Dashboard} />
											<Route path={"/work-schedule/daily"} exact component={DayPlanWrapper} />
											<Route path={"/work-schedule/view/:date"} render={(props) => <DayPlanView key={props.match.params.date} {...props} />}></Route>
											{/*<Route path={"/work-schedule/weekly"}/>*/}
											<Route path={"/work-schedule/calendar"} component={AppointmentCalendar} />
											<Route path={"/resources/employees"} component={EmployeeOverview} />
											<Route path={"/resources/vehicles"} component={VehicleOverview} />
											<Route path={"/resources/equipments"} component={EquipmentOverview} />
											<Route path={"/construction-site/construction-site-type"} component={ConstructionSiteOverview} />
											<Route path={"/administration/customer"} component={CustomerOverview} />
											<Route path={"/administration/task-types"} component={TaskTemplateOverview} />
											<Route path={"/administration/appointment-types"} component={AppointmentTypeOverview} />
											<Route path={"/administration/drivers-licences"} component={DrivingLicenseClassOverview} />
											<Route path={"/administration/roles"} component={RoleOverview} />
											<Route path={"/administration/users"} component={UserAdministration} />
										</Switch>
									</Box>
								) : (
									<NotVerifiedScreen />
								)}
							</Router>
						</SnackbarProvider>
					</ThemeProvider>
				</UserContext.Provider>
			</ThemeModeContext.Provider>
		</AppBarInfoAreaContext.Provider>
	);
}

export default App;
