import firebase from "firebase";

const peach = require("parallel-each");
const registerGenericCollectionListener = async <Type>(query: firebase.firestore.Query,
                                                       updateModelState: (objectsToAdd: Type[],
                                                                          objectsToModify: Type[],
                                                                          objectsToDelete: Type[]) => void,
                                                       resolverFunction: (document: firebase.firestore.QueryDocumentSnapshot) => Promise<Type>,
                                                       listenerId: string) => {
    let unsubscribe = query.onSnapshot(
        (snapshot) => {
            let update = async () => {
                let objectsToAdd: Type[] = [];
                let objectToModify: Type[] = [];
                let objectToDelete: Type[] = [];
                await peach(snapshot.docChanges(), async (change: any, index: any) => {
                    if (change.type === "added") {
                        objectsToAdd.push(await resolverFunction(change.doc));
                    }
                    if (change.type === "modified") {
                        objectToModify.push(await resolverFunction(change.doc));
                    }
                    if (change.type === "removed") {
                        objectToDelete.push(await resolverFunction(change.doc));
                    }

                })

                updateModelState(objectsToAdd, objectToModify, objectToDelete)
            }
            update()
        }
    )
    console.log("Listener Started:", listenerId)
    return unsubscribe;

}

//keep for future developments
const registerGenericDocumentListener = async <Type>(query: firebase.firestore.DocumentReference,
                                                     updateCallback: (object: Type) => void,
                                                     deleteCallback: () => void,
                                                     resolverFunction: (document: firebase.firestore.DocumentData) => Promise<Type>,
                                                     listenerId: string) => {

    let unsubscribe = query.onSnapshot((snapshot => {
            let update = async () => {
                console.log("Listener Started:", listenerId)
                let data = snapshot.data();
                if (snapshot.exists && data) {
                    updateCallback(await resolverFunction(data))
                } else {
                    deleteCallback()

                }
            }
            update()
        }
    ))

    return unsubscribe
}

export {registerGenericCollectionListener, registerGenericDocumentListener}

