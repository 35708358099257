import {FieldValidator, proxiedPropertiesOf, ValidatorType} from "../../../ValidatorHook";
import VehicleModel from "../../../../model/types/basistypes/ressources/VehicleModel";

const manufacturerError = "Der Hersteller darf nur aus Buchstaben bestehen und muss angegeben werden"
const licensePlateError = "Bitte geben Sie ein gültiges Nummernschild an."
const desrcriptionError = "Bitte geben Sie eine kurze Beschreibung an."
const classError = "Bitte geben Sie mindestens eine Führerscheinklasse an."

let vehicle: VehicleModel;

// @ts-ignore
const properties = proxiedPropertiesOf(vehicle)

const VehicleValidator: FieldValidator[] = [
    {
        FieldName: properties.producer,
        Validation: "^[a-zA-Z ]+$",
        Type: ValidatorType.REGEX,
        ErrorMessage: manufacturerError
    },
    {
        FieldName: properties.licensePlace,
        Validation: "^[A-Z]{1,3}-[A-Z]{1,2}-[0-9]{1,4}[HE]{0,1}$",
        Type: ValidatorType.REGEX,
        ErrorMessage: licensePlateError
    },
    {
        FieldName: properties.vehicleDescription,
        Validation: "^[a-zA-Z ]+$",
        Type: ValidatorType.REGEX,
        ErrorMessage: desrcriptionError
    },
]

export default VehicleValidator;
