import {FieldValidator, proxiedPropertiesOf, ValidatorType} from "../ValidatorHook";
import AppointmentTypeModel from "../../model/types/basistypes/times/AppointmentTypeModel";
import {ResourceType} from "../../model/types/basistypes/times/AppointmentModel";


let eventTyp: AppointmentTypeModel;

// @ts-ignore
const properties = proxiedPropertiesOf(eventTyp)
const nonEmptyString = "^(?!\\s*$).+";


const EventTypValidator: FieldValidator[] = [
    {
        FieldName: properties.description,
        Validation: nonEmptyString,
        Type: ValidatorType.REGEX,
        ErrorMessage: "validator.nonEmpty"
    },
    {
        FieldName: properties.available,
        Validation: (resourceTypeList: ResourceType[]) => {
            return (resourceTypeList.length > 0)
        },
        Type: ValidatorType.Function,
        ErrorMessage: "validator.eventType.classError"
    }
]

export default EventTypValidator;
