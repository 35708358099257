import React, {useContext, useEffect, useRef, useState} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import {AppointmentModel, ResourceType} from "../../model/types/basistypes/times/AppointmentModel";
import {registerAppointmentsForRangeSnapshotListener} from "../../model/ModelController/times/AppointmentController";
import {AppBarInfoAreaContext} from "../Sidebar/AppBarInfoAreaContext";
import {GlobalStyledPaper} from "../StyledComponents/GlobalStyled/GlobalStyled";
import AppointmentMask from "./AppointmentMask";
import {Autocomplete, Grid, Popover, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {utcTimestampToFormattedString} from "../../utility/dateUtil";
import {FilterAltOffRounded, FilterAltRounded} from "@mui/icons-material";
import {getFilteredEvents, removeDuplicates} from "./helper/AppointmentFilter";
import {Typography} from "@material-ui/core";
import GenericActionBar from "../ActionBar/GenericActionBar";
import {Action} from "../ActionBar/GenericActionBarIconButton";

interface propsType {
}

export const getResourceType = [
    "Mitarbeiter",
    "Fahrzeuge",
    "Geräte"
]
export default function AppointmentCalendar(props: propsType) {

    const [fromDate, setFromDate] = useState<number>(0)
    const [toDate, setToDate] = useState<number>(0)
    const [employeeAppointmentList, setEmployeeAppointmentList] = useState<AppointmentModel[]>([])
    const [vehicleAppointmentList, setVehiceAppointmentList] = useState<AppointmentModel[]>([])
    const [equipmentAppointmentList, setEquipmentAppointmentList] = useState<AppointmentModel[]>([])
    const appbarContext = useContext(AppBarInfoAreaContext);
    const [open, setOpen] = useState(false);
    const [openPopOver, setOpenPopOver] = useState(false);
    const [anchorEl, setAnchor] = useState<Element | null>(null);
    const [selected, setSelected] = useState<AppointmentModel>()
    const [filterActive, setFilterActive] = useState<boolean>(false);
    const [selectedResourceType, setSelectedResourceType] = useState<string | null>(null);
    const [selectedResources, setSelectedResources] = useState<any | null>([]);
    const [selectedAppointments, setSelectedAppointments] = useState<any | null>([]);
    const divRef = React.useRef();
    const [events, setEvents] = useState<AppointmentModel[]>([])
    const {t} = useTranslation();


    const employeeAppointmentRef = useRef(employeeAppointmentList);
    const vehicleAppointmentRef = useRef(vehicleAppointmentList);
    const equipmentApointmentRef = useRef(equipmentAppointmentList);


    const updateEmployeeAppointmentRef = (model: AppointmentModel[]) => {
        employeeAppointmentRef.current = model;
        setEmployeeAppointmentList(model);
        setEvents(employeeAppointmentRef.current.concat(vehicleAppointmentRef.current).concat(equipmentApointmentRef.current));
    }

    const updateVehicleAppointmentRef = (model: AppointmentModel[]) => {
        vehicleAppointmentRef.current = model;
        setVehiceAppointmentList(model);
        setEvents(employeeAppointmentRef.current.concat(vehicleAppointmentRef.current).concat(equipmentApointmentRef.current));
    }

    const updateEquipmentAppointmentRef = (model: AppointmentModel[]) => {
        equipmentApointmentRef.current = model;
        setEquipmentAppointmentList(model);
        setEvents(employeeAppointmentRef.current.concat(vehicleAppointmentRef.current).concat(equipmentApointmentRef.current));
    }

    const updateList = (reference: AppointmentModel[], objectsToAdd: AppointmentModel[], objectsToUpdate: AppointmentModel[], objectsToDelete: AppointmentModel[]) => {
        let copiedAppointments = [...reference]

        copiedAppointments = copiedAppointments.concat(objectsToAdd);
        for (let updatedObj of objectsToUpdate) {
            copiedAppointments = copiedAppointments.map(value => (value.id === updatedObj.id) ? updatedObj : value);
        }
        for (let deletedObj of objectsToDelete) {
            copiedAppointments = copiedAppointments.filter(value => (value.id !== deletedObj.id))
        }

        return copiedAppointments;

    }

    const handleEmployeeSnapshot = (objectsToAdd: AppointmentModel[], objectsToUpdate: AppointmentModel[], objectsToDelete: AppointmentModel[]) => {
        const updated = updateList(employeeAppointmentRef.current, objectsToAdd, objectsToUpdate, objectsToDelete);
        updateEmployeeAppointmentRef(updated)
    }

    const handleVehicleSnapshot = (objectsToAdd: AppointmentModel[], objectsToUpdate: AppointmentModel[], objectsToDelete: AppointmentModel[]) => {
        const updated = updateList(vehicleAppointmentRef.current, objectsToAdd, objectsToUpdate, objectsToDelete);
        updateVehicleAppointmentRef(updated)
    }

    const handleEquipmentSnapshot = (objectsToAdd: AppointmentModel[], objectsToUpdate: AppointmentModel[], objectsToDelete: AppointmentModel[]) => {
        const updated = updateList(equipmentApointmentRef.current, objectsToAdd, objectsToUpdate, objectsToDelete);
        updateEquipmentAppointmentRef(updated)
    }


    useEffect(() => {

       let unsubscribeFunctions: any[] = [];
        let registerListener = async () => {
            let promises: Promise<any>[] = [];
            promises.push(registerAppointmentsForRangeSnapshotListener(ResourceType.EMPLOYEE, fromDate, toDate, handleEmployeeSnapshot));
            promises.push(registerAppointmentsForRangeSnapshotListener(ResourceType.VEHICLE, fromDate, toDate, handleVehicleSnapshot));
            promises.push(registerAppointmentsForRangeSnapshotListener(ResourceType.EQUIPMENT, fromDate, toDate, handleEquipmentSnapshot));

            unsubscribeFunctions = await Promise.all(promises);

        }

        registerListener();

        return function cleanup() {
            for (let unsubscribeFunction of unsubscribeFunctions)
                unsubscribeFunction();

            employeeAppointmentRef.current = [];
            vehicleAppointmentRef.current = [];
            equipmentApointmentRef.current = [];
            setEquipmentAppointmentList([]);
            setVehiceAppointmentList([]);
            setEmployeeAppointmentList([]);
        }


    }, [fromDate, toDate])
    const handleDateChange = (newFromDate: number, newToDate: number) => {
        appbarContext.updateContent(t("appointmentCalendar.manageTitle", {
            from: utcTimestampToFormattedString(newFromDate),
            to: utcTimestampToFormattedString(newToDate),
        }));

        if (fromDate !== newFromDate || toDate !== newToDate) {
            setFromDate(newFromDate)
            setToDate(newToDate)
        }

    }

    const AppointmentCalendarActions = [
        {
            icon: (!filterActive) ? <FilterAltOffRounded/> : <FilterAltRounded/>,
            name: 'Filter',
            tooltip: t("filter.filter"),
            callback: (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
                setAnchor(e.currentTarget)
                setOpenPopOver(true)
            },

        } as Action,
    ]


    const getClickedEvent = (id: string) => {
        for (let localEvent of events) {
            if (localEvent.id === id) {
                return localEvent;
            }
        }
    }

    return (
        <GlobalStyledPaper>
            <Grid item
                  sx={{
                      height: "100%",
                      width: "100%",
                      "& a.fc-event:hover ": {cursor: "pointer"}
                  }}>
                <GenericActionBar actions={AppointmentCalendarActions}/>
                <FullCalendar
                    themeSystem={'standard'}
                    editable={false}
                    locale={"de"}
                    height={"90%"}
                    contentHeight={"90%"}
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    dayMaxEventRows={4} // for all non-TimeGrid views
                    navLinks={true}
                    navLinkDayClick={(date => alert(date.toDateString()))}
                    datesSet={arg => {
                        handleDateChange(arg.start.getTime(), arg.end.getTime())
                    }
                    }
                    events={getFilteredEvents(selectedResourceType, selectedResources, selectedAppointments, employeeAppointmentList, vehicleAppointmentList, equipmentAppointmentList)}
                    eventClick={arg => {
                        let clickedEvent = getClickedEvent(arg.event.id)
                        if (clickedEvent) {
                            setOpen(true);
                            setSelected(clickedEvent);
                        }
                    }}
                />
                {(open && selected) ?

                    <AppointmentMask
                        open={open}
                        editEnabled={true}
                        resource={selected.resource}
                        resourceType={selected.resourceType}
                        appointmentSeriesId={selected.appointmentSeriesId}
                        updateCallback={() => {
                            setOpen(false);
                            handleDateChange(fromDate, toDate)
                        }}
                        deleteCallback={() => {
                            setOpen(false);
                            handleDateChange(fromDate, toDate)
                        }}
                        closeCallback={() => setOpen(false)}/> : <div/>}
            </Grid>
            <Popover
                PaperProps={{sx: {backgroundColor: "background.default"}}}
                open={openPopOver}
                anchorEl={anchorEl}
                onClose={() => setOpenPopOver(!openPopOver)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Grid container sx={{width: "50vw", margin: 2,}} gap={2}>
                    <Grid item xs={12}>
                        <Typography align={"center"}>{t("filter.title")}</Typography>

                    </Grid>
                    <Grid item xs>
                        <Autocomplete
                            value={selectedResourceType}
                            onChange={(event: any, newValue: string | null) => {
                                setSelectedResourceType(newValue);
                                setSelectedResources([])
                                setSelectedAppointments([])
                                setFilterActive(!!(newValue))
                            }}
                            id="tags-outlined"
                            options={getResourceType}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("filter.resourceType")}
                                    placeholder={t("filter.resourceType")}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Autocomplete
                            multiple
                            value={selectedResources}
                            onChange={(event, newValue) => {
                                setSelectedResources(newValue);
                            }}
                            id="selected_resources"
                            options={removeDuplicates("selected_resources", selectedResourceType, employeeAppointmentList, vehicleAppointmentList, equipmentAppointmentList)}

                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("filter.resources")}
                                    placeholder={t("filter.resources")}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Autocomplete
                            multiple
                            value={selectedAppointments}
                            onChange={(event, newValue) => {
                                setSelectedAppointments(newValue);
                            }}
                            id="selected_appointments"
                            options={removeDuplicates("selected_appointments", selectedResourceType, employeeAppointmentList, vehicleAppointmentList, equipmentAppointmentList)}
                            //defaultValue={employeeAppointmentList[0]}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("filter.appointmentType")}
                                    placeholder={t("filter.appointmentType")}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Popover>
        </GlobalStyledPaper>
    )
}
