import {FieldValidator, proxiedPropertiesOf, ValidatorType} from "../../../ValidatorHook";
import UserModel from "../../../../model/types/permissions/UserModel";

let user: UserModel;
//@ts-ignore
const properties = proxiedPropertiesOf(user)
const emailRegex = "(?:[a-z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+\\/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])";

const UserValidator: FieldValidator[] = [
    {
        FieldName: properties.loginName,
        Validation: emailRegex,
        Type: ValidatorType.REGEX,
        ErrorMessage: "validator.user.emailIsIncorrect"
    }]

export default UserValidator;
