import React, {ReactNode, useEffect, useState} from "react";
import {Add, FilterAlt, FilterAltOff} from "@mui/icons-material";
import {Divider, Grid, ListItemButton, ListItemText,} from "@mui/material";
import {ResourceStyledFab, ResourceStyledPickList} from "../../../StyledComponents/ComponentStyled/ResourceStyled";
import {GlobalStyledPaper} from "../../../StyledComponents/GlobalStyled/GlobalStyled";
import FilterPopover from "./FilterPopover";
import DeepEquipmentListItem from "../Equipment/DeepEquipmentListItem";
import EquipmentModel from "../../../../model/types/basistypes/ressources/EquipmentModel";

interface propsType {
    resources: any[],
    getListItemText: (resource: any) => ReactNode,
    resourceClickCallBack: (resource: any, index: number) => void,

    addItemCallback: () => void,
    selectedItem: number,
    initialFilterValue?: string,
    isEquipment?: boolean
}

export default function ResourceList(props: propsType) {

    const [anchorEl, setAnchor] = useState<Element | null>(null);
    const [filterPopover, setFilterPopover] = useState({
        popover: false,
        resourceFilterValue: props.initialFilterValue ? props.initialFilterValue : ""
    });

    const containsFilterValue = (object: any, filtervalue: string) => {
        let copy = {...object};

        //Remove all unwanted properties
        delete copy.id;

        const objectAsString = JSON.stringify(copy);

        return objectAsString.toLowerCase().includes(filtervalue.toLowerCase())
    }


    useEffect(() => {
        setFilterPopover({
            popover: false,
            resourceFilterValue: props.initialFilterValue ? props.initialFilterValue : ""
        })
    }, [props.initialFilterValue])


    return (
        <GlobalStyledPaper>
            <Grid container xs={12}
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
            >
                {(props.addItemCallback) ?
                    <Grid item container xs={12} justifyContent={"center"}>
                        <Grid item xs={2}>
                            <ResourceStyledFab color={"secondary"} onClick={props.addItemCallback} size={"small"}>
                                <Add/>
                            </ResourceStyledFab>
                        </Grid>
                        <Grid item xs={2}>
                            <ResourceStyledFab color={"secondary"} size={"small"} onClick={(event) => {
                                setFilterPopover({...filterPopover, popover: true})
                                setAnchor(event.currentTarget)
                            }}>
                                {
                                    ((filterPopover.resourceFilterValue) ? (<FilterAlt/>) : (
                                        <FilterAltOff/>))
                                }
                            </ResourceStyledFab>
                            <FilterPopover open={filterPopover.popover}
                                           anchor={anchorEl}
                                           value={props.initialFilterValue}
                                           filterValueCallback={value => setFilterPopover({
                                               ...filterPopover,
                                               resourceFilterValue: value
                                           })}
                                           onCloseCallback={() => {
                                               setFilterPopover({...filterPopover, popover: false})
                                           }}/>
                        </Grid>
                    </Grid> : <div/>}
                <Grid item container xs={12} justifyContent={"center"}>
                    <ResourceStyledPickList>
                        {(!props.isEquipment) ? <Divider/> : <div/>}
                        {
                            props.resources.map((resource, index) => {
                                if (!containsFilterValue(resource, filterPopover.resourceFilterValue)) {
                                    return null;
                                }
                                return <>
                                    {(props.isEquipment) ? <DeepEquipmentListItem
                                            equipment={resource}
                                            selected={props.selectedItem}
                                            setSelected={(equipment: EquipmentModel) => {
                                                props.resourceClickCallBack(equipment, index)
                                            }}
                                            getListItemText={props.getListItemText}/> :

                                        <ListItemButton key={index}
                                                        selected={(index === props.selectedItem)}
                                                        onClick={() => {
                                                            props.resourceClickCallBack(resource, index)
                                                        }}
                                                        sx={{"&.Mui-selected:hover": {backgroundColor: "action.hover",},}}>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    color: (index === props.selectedItem) ? "text.secondary" : "text.primary"
                                                }}>
                                                {props.getListItemText(resource)}
                                            </ListItemText>
                                        </ListItemButton>

                                    }
                                    {(!props.isEquipment) ? <Divider/> : <div/>}
                                    </>

                                }
                            )
                        }
                    </ResourceStyledPickList>
                </Grid>
            </Grid>
        </GlobalStyledPaper>
    )
}
