import {Popover, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";

interface propsType {
    open: boolean,
    anchor: Element | null
    filterValueCallback: (value: string) => void,
    onCloseCallback: () => void
    value?: string;
}

export default function FilterPopover(props: propsType) {
    const [text, setText] = useState(props.value ? props.value : "");

    useEffect(() => {
        if (props.value) {
            setText(props.value)
        }
    }, [props.value]);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            props.onCloseCallback();
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setText(event.target.value)
        props.filterValueCallback(event.target.value)
    }

    return (
        <Popover
            open={props.open}
            anchorEl={props.anchor}
            onClose={props.onCloseCallback}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}>
            <TextField
                value={text}
                onFocus={event => {
                    event.target.select();
                }}
                autoFocus={true}
                onKeyUp={handleKeyPress}
                onChange={handleChange}/>
        </Popover>
    )
}
