import React, {useEffect, useState} from "react";
import VehicleModel from "../../../../model/types/basistypes/ressources/VehicleModel";
import DrivingLicenseClassModel from "../../../../model/types/basistypes/ressources/DrivingLicenseClassModel";
import {addVehicle, deleteVehicle} from "../../../../model/ModelController/Resources/VehicleController";
import DrivingLicenseClassSelectionDialog from "../DrivingLicenseClasses/DrivingLicenseClassSelectionDialog";
import AppointmentMask from "../../../Time/AppointmentMask";
import {ResourceType} from "../../../../model/types/basistypes/times/AppointmentModel";
import AppointmentList from "../../../Time/AppointmentList";
import useValidator, {proxiedPropertiesOf} from "../../../ValidatorHook";
import VehicleValidator from "./VehicleValidator";
import FullScreenDialogSidebar from "../Helper/FullScreenDialogSidebar";
import {Grid, Typography,} from "@mui/material";
import {Add, Clear, Delete, Save,} from "@mui/icons-material";
import {ResourceStyledDriversLicenceChip,} from "../../../StyledComponents/ComponentStyled/ResourceStyled";
import {
    GlobalStyledDivider,
    GlobalStyledPaper,
    GlobalStyledTextField
} from "../../../StyledComponents/GlobalStyled/GlobalStyled";
import {useTranslation} from "react-i18next";
import {Action} from "../../../ActionBar/GenericActionBarIconButton";
import GenericActionBar from "../../../ActionBar/GenericActionBar";

interface propsType {
    editEnabled: boolean
    asDialog: boolean
    vehicle: VehicleModel

    updateCallback(vehicle: VehicleModel): void

    deleteCallback(vehicle: VehicleModel): void

    isDirtyCallback?(): void

    closeCallback(): void
}

function VehicleMask(props: propsType) {
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [openAppointment, setOpenAppointment] = useState(false);
    const [localVehicle, setLocalVehicle] = useState<VehicleModel>(props.vehicle)
    const {t} = useTranslation();


    let vehicleFieldNames = proxiedPropertiesOf(localVehicle)
    let vehicleValidator = useValidator(VehicleValidator, localVehicle, localVehicle.id);

    useEffect(() => {
        if (props.vehicle._isNew) {
            setIsDirty(true);
        } else {
            setIsDirty(false);
        }
    }, [props.vehicle])

    if (props.vehicle === undefined) {
        setLocalVehicle(props.vehicle)
    }

    if (props.vehicle === undefined) {
        return (<div/>)
    }

    if (props.vehicle.id !== localVehicle.id) {
        setLocalVehicle(props.vehicle)
    }

    const wrapCallback = (callback: any, windowmode: boolean) => {
        return (windowmode) ?
            () => {
                callback()
                props.closeCallback();
            } : callback
    }

    const saveToFireBase = () => {
        let errors = vehicleValidator.performFullValidation();
        if (errors.errorCount > 0) {
            alert("Beheben Sie vor dem Speichern alle Fehler");
            return;
        }
        addVehicle(localVehicle)
            .then(value => setIsDirty(false))
            .catch(e => console.log(e))

        props.updateCallback(localVehicle)
    }

    const deleteFromFireBase = () => {
        deleteVehicle(localVehicle)
            .catch(e => console.log(e))
        props.deleteCallback(localVehicle)
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName: string = e.currentTarget.name;
        const newValue = e.currentTarget.value
        let newState: VehicleModel = {...localVehicle};
        if (props.isDirtyCallback) {
            setIsDirty(true);
            props.isDirtyCallback();
        }
        newState[fieldName] = newValue;
        setLocalVehicle(newState)
    }

    const handleDelete = (index: number) => {
        return () => {
            let newVehicle = {...localVehicle}
            newVehicle.drivingLicenseClasses.splice(index, 1)
            setLocalVehicle(newVehicle)
        }
    }

    const addDrivingLicenseClass = (drivingLicenseClass: DrivingLicenseClassModel) => {
        let newDrivingLicenseClass: DrivingLicenseClassModel[] = [...localVehicle.drivingLicenseClasses];
        newDrivingLicenseClass.push(drivingLicenseClass);
        let newVehicle = {...localVehicle};
        newVehicle.drivingLicenseClasses = newDrivingLicenseClass;
        setLocalVehicle(newVehicle)
        if (props.isDirtyCallback) {
            setIsDirty(true);
            props.isDirtyCallback();
        }
        setOpen(false)
    }

    const vehicleActions = [];

    if (!props.asDialog) {
        vehicleActions.push(
            {
                icon: <Save/>,
                name: 'Save',
                tooltip: t("actions.save"),
                callback: wrapCallback(saveToFireBase, props.asDialog),
                disabled: !isDirty,
                actionKey: "s"
            } as Action,
            {
                icon: <Delete/>,
                name: 'Delete',
                tooltip: t("actions.delete"),
                callback: wrapCallback(deleteFromFireBase, props.asDialog)
            } as Action,
        );
    }

    const appointmentActions: Array<Action> = [
        {
            icon: <Add/>, name: 'Appointment', tooltip: t("actions.appointment"), callback: () => {
                setOpenAppointment(true)
            }
        },
    ];

    const drivingLicenceClassActions: Array<Action> = [
        {
            icon: <Add/>, name: 'DrivingLicense', tooltip: t("actions.drivingLicense"), callback: () => {
                setOpen(true)
            }
        }];


    return (
        <Grid container xs={12}>
            <Grid item xs={12}>
                {
                    (props.asDialog) ?
                        <FullScreenDialogSidebar iconBackAction={props.closeCallback}
                                                 iconSaveAction={wrapCallback(saveToFireBase, props.asDialog)}
                                                 iconDeleteAction={wrapCallback(deleteFromFireBase, props.asDialog)}
                                                 title={t("vehicle.manageTitle")}/>
                        : <div/>
                }
            </Grid>
            <Grid item container xs={6}>
                <GlobalStyledPaper>
                    <Grid item container alignItems={"center"} justifyContent={"center"} direction={"row"}
                          xs={12}>
                        <GenericActionBar actions={vehicleActions}/>
                        <Grid item xs={12}>
                            <Typography variant={"h6"} align={"center"}>
                                {t("vehicle.title") + ": " + localVehicle.licensePlace}
                            </Typography>
                            <GlobalStyledDivider/>
                        </Grid>
                        <Grid item xs={6}>
                            <GlobalStyledTextField
                                name={vehicleFieldNames.licensePlace}
                                required
                                label={t(vehicleFieldNames.licensePlace)}
                                value={localVehicle.licensePlace}
                                error={vehicleValidator.containsError(vehicleFieldNames.licensePlace)}
                                helperText={vehicleValidator.getErrorMessage(vehicleFieldNames.licensePlace)}
                                onChange={vehicleValidator.wrapOnChangeInValidator(onChange)}
                                variant={"outlined"}/>
                        </Grid>
                        <Grid item xs={6}>
                            <GlobalStyledTextField
                                name={vehicleFieldNames.producer}
                                required
                                label={t(vehicleFieldNames.producer)}
                                value={localVehicle.producer}
                                error={vehicleValidator.containsError(vehicleFieldNames.producer)}
                                helperText={vehicleValidator.getErrorMessage(vehicleFieldNames.producer)}
                                onChange={vehicleValidator.wrapOnChangeInValidator(onChange)}
                                variant={"outlined"}/>
                        </Grid>
                        <Grid item xs={6}>
                            <GlobalStyledTextField
                                name={vehicleFieldNames.nickname}
                                label={t(vehicleFieldNames.nickname)}
                                value={localVehicle.nickname}
                                error={vehicleValidator.containsError(vehicleFieldNames.nickname)}
                                helperText={vehicleValidator.getErrorMessage(vehicleFieldNames.nickname)}
                                onChange={vehicleValidator.wrapOnChangeInValidator(onChange)}
                                variant={"outlined"}/>
                        </Grid>
                        <Grid item xs={6}>
                            <GlobalStyledTextField
                                name={vehicleFieldNames.vehicleDescription}
                                required
                                label={t(vehicleFieldNames.vehicleDescription)}
                                value={localVehicle.vehicleDescription}
                                error={vehicleValidator.containsError(vehicleFieldNames.vehicleDescription)}
                                helperText={vehicleValidator.getErrorMessage(vehicleFieldNames.vehicleDescription)}
                                onChange={vehicleValidator.wrapOnChangeInValidator(onChange)}
                                variant={"outlined"}/>
                        </Grid>
                        <Grid item xs={12}/>
                        <Grid item container alignItems={"center"} justifyContent={"flex-start"} xs={12}>
                            <GenericActionBar actions={drivingLicenceClassActions}/>
                            <Grid item xs={12}>
                                <Typography variant={"h6"} align={"center"}>{t("drivingLicense.classes")}</Typography>
                                <GlobalStyledDivider/>
                            </Grid>
                            {
                                (localVehicle.drivingLicenseClasses !== []) ?
                                    localVehicle.drivingLicenseClasses.map(
                                        (value, index) => {
                                            return (<Grid item xs={4}><ResourceStyledDriversLicenceChip
                                                label={value.description}
                                                onDelete={handleDelete(index)}
                                                deleteIcon={<Clear/>}/></Grid>)
                                        }
                                    ) : ""
                            }
                        </Grid>
                    </Grid>
                </GlobalStyledPaper>
            </Grid>
            <Grid item xs={6}>
                <GlobalStyledPaper>
                    <Grid item xs={12}>
                        <GenericActionBar actions={appointmentActions}/>
                        <Typography variant={"h6"} align={"center"}> Termine
                            für: {localVehicle.licensePlace}  </Typography>
                        <GlobalStyledDivider/>
                    </Grid>
                    <Grid item xs={12}>

                        {
                            (props.vehicle !== undefined) ?
                                <AppointmentList type={ResourceType.VEHICLE} resource={props.vehicle}
                                                 Id={props.vehicle.id}/>
                                : ""}
                    </Grid>
                    <Grid item container xs={12} alignItems={"flex-start"}>
                        {(openAppointment) ? <AppointmentMask open={openAppointment}
                                                              editEnabled={true}
                                                              resource={localVehicle}
                                                              resourceType={ResourceType.VEHICLE}
                                                              updateCallback={(termin) => {
                                                                  setOpenAppointment(false);
                                                              }}
                                                              deleteCallback={() => setOpenAppointment(false)}
                                                              closeCallback={() => setOpenAppointment(false)}/> : ""}
                    </Grid>
                </GlobalStyledPaper>
            </Grid>
            {(open) ? <DrivingLicenseClassSelectionDialog open={open}
                                                          classes={localVehicle.drivingLicenseClasses}
                                                          callBack={addDrivingLicenseClass}
                                                          closeCallback={() => setOpen(false)}/> : ""}
        </Grid>
    )
}

// @ts-ignore
export default VehicleMask;
