import {FieldValidator, proxiedPropertiesOf, ValidatorType} from "../../../ValidatorHook";
import EmployeeModel from "../../../../model/types/basistypes/ressources/EmployeeModel";
import {getCurrentDateAsUTCTimestamp} from "../../../../utility/dateUtil";

let employee: EmployeeModel;

// @ts-ignore
const properties = proxiedPropertiesOf(employee)
const yearOffset = 14;
const nameError = "Bitte geben Sie ein gültiges Namensformat ein."
const yearError = "Ein Mitarbeiter sollte mindestens " + yearOffset + " Jahre alt sein."
const roleError = "Bitte geben Sie eine gültige Rolle an."

const EmployeeValidator: FieldValidator[] = [
    {
        FieldName: properties.name,
        Validation: "^[a-zA-ZöäüÖÄÜ]+([ -][a-zA-ZöäüÖÄÜ]+)*$",
        Type: ValidatorType.REGEX,
        ErrorMessage: nameError
    },
    {
        FieldName: properties.surname,
        Validation: "^[a-zA-ZöäüÖÄÜ]+([ -][a-zA-ZöäüÖÄÜ]+)*$",
        Type: ValidatorType.REGEX,
        ErrorMessage: nameError
    },
    {
        FieldName: properties.birthday,
        Validation: (date: number) => {
            let todayAsDate = new Date(getCurrentDateAsUTCTimestamp());
            todayAsDate.setFullYear(todayAsDate.getUTCFullYear() - yearOffset);
            let todayNyearsAgo = todayAsDate.getTime();
            return (date <= todayNyearsAgo);
        },
        Type: ValidatorType.Function,
        ErrorMessage: yearError
    },
]

export default EmployeeValidator;
