import CustomerModel from "../../types/basistypes/ressources/CustomerModel";
import { getFirebase, getFireStore } from "../FirebaseWrapper";
import getCompanyAffiliation from "../CompanyAffiliation";
import firebase from "firebase";
import { purgeUiOnlyFields, resolveNestedDocumentList, transformArrayToReference } from "../FirebaseConverter";
import { registerGenericCollectionListener } from "../GenericFirebaseSnapshotListener";

const addCustomer = async (Customer: CustomerModel) => {
	Customer = purgeUiOnlyFields(Customer);

	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		dbConnection
			.collection(companyId)
			.doc("Customer")
			.collection("Customer")
			.doc(Customer.id.toString())
			.set({
				...Customer,
				usable: true,
			});
	}
};

const deleteCustomer = async (Customer: CustomerModel) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		dbConnection.collection(companyId).doc("Customer").collection("Customer").doc(Customer.id.toString()).update({ usable: false });
	}
};

const getCustomerDocumentId = async (id: number) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId) {
		return dbConnection.collection(companyId).doc("Customer").collection("Customer").doc(id.toString());
	}
};

const getCustomer = async () => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
			.collection(companyId)
			.doc("Customer")
			.collection("Customer")
			.where("usable", "==", true)
			.get();
		let result: CustomerModel[] = [];
		for (let doc of query.docs) {
			result.push((await doc.data()) as CustomerModel);
		}
		return result;
	}
	return [];
};

const registerAsyncCustomerSnapshotListener = async (
	updateCallback: (objectsToAdd: CustomerModel[], ojectsToUpdate: CustomerModel[], objectsToDelete: CustomerModel[]) => void
) => {
	const dbConnection = getFireStore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		let query: firebase.firestore.Query = await dbConnection.collection(companyId).doc("Customer").collection("Customer").where("usable", "==", true);

		let unsubscribe = registerGenericCollectionListener<CustomerModel>(query, updateCallback, async (document) => document.data() as CustomerModel, "CustomerListener");
		return unsubscribe;
	}
};

export { addCustomer, getCustomer, deleteCustomer, getCustomerDocumentId, registerAsyncCustomerSnapshotListener };
