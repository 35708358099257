import React, {useEffect, useState} from "react";
import CustomerModel from "../../../../model/types/basistypes/ressources/CustomerModel";
import {addCustomer, deleteCustomer} from "../../../../model/ModelController/Resources/CustomerController";
import useValidator, {proxiedPropertiesOf} from "../../../ValidatorHook";
import FullScreenDialogSidebar from "../Helper/FullScreenDialogSidebar";
import {Grid, Typography} from "@mui/material";
import {Delete, Save} from "@mui/icons-material";
import {
	GlobalStyledDivider,
	GlobalStyledPaper,
	GlobalStyledTextField
} from "../../../StyledComponents/GlobalStyled/GlobalStyled";
import {useTranslation} from "react-i18next";
import CustomerValidator from "./CustomerValidator";
import CustomerAddressMask from "./UI-Elements/CustomerAddressMask";
import GenericActionBar from "../../../ActionBar/GenericActionBar";
import CustomerContactMask from "./UI-Elements/CustomerContactMask";
import CustomerAddressValidator from "./CustomerAddressValidator";

interface propsType {
	editEnabled: boolean;
	asDialog: boolean;
	customer: CustomerModel;

	updateCallback(customer: CustomerModel): void;

	deleteCallback(customer: CustomerModel): void;

	isDirtyCallback?(): void;

	closeCallback(): void;
}

function CustomerMask(props: propsType) {
	const [localCustomer, setLocalCustomer] = useState<CustomerModel>(props.customer);
	const {t} = useTranslation();
	const [isDirty, setIsDirty] = useState<boolean>(false);
	let CustomerFieldNames = proxiedPropertiesOf(localCustomer);
	let customerValidator = useValidator(CustomerValidator, localCustomer, localCustomer.id);
	let customerAddressValidator = useValidator(CustomerAddressValidator, localCustomer.address, localCustomer.id);


	useEffect(() => {
		if (props.customer._isNew) {
			setIsDirty(true);
		} else {
			setIsDirty(false);
		}

		setLocalCustomer(props.customer);
	}, [props.customer.id, props.customer]);

	if (props.customer === undefined) {
		return <div />;
	}

	const wrapCallback = (callback: any, windowmode: boolean) => {
		return windowmode
			? () => {
					callback();
					props.closeCallback();
			  }
			: callback;
	};

	const saveToFireBase = () => {
		let customerErrors = customerValidator.performFullValidation();
		let customerAddressErrors = customerAddressValidator.performFullValidation();
		if (customerErrors.errorCount > 0 || customerAddressErrors.errorCount > 0) {
			alert(t("validator.errorPreventingSave"));
			return;
		}
		addCustomer(localCustomer)
			.then(value => {
				setIsDirty(false);
			})
			.catch((e) => console.log(e));

		props.updateCallback(localCustomer);
	};

	const deleteFromFireBase = () => {
		deleteCustomer(localCustomer).catch((e) => console.log(e));
		props.deleteCallback(localCustomer);
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const fieldName: string = e.currentTarget.name;
		const newValue = e.currentTarget.value;
		let newState: CustomerModel = { ...localCustomer };
		if (props.isDirtyCallback) {
			setIsDirty(true);
			props.isDirtyCallback();
		}
		newState[fieldName] = newValue;
		setLocalCustomer(newState);
	};

	const actions = [];
	if (!props.asDialog) {
		actions.push(
			{
				icon: <Save/>,
				name: "Save",
				tooltip: t("actions.save"),
				callback: wrapCallback(saveToFireBase, props.asDialog),
				disabled: !isDirty,
				actionKey: "s"
			},
			{
				icon: <Delete/>,
				name: "Delete",
				tooltip: t("actions.delete"),
				callback: wrapCallback(deleteFromFireBase, props.asDialog),
			}
		);
	}

	return (
		<Grid container xs={12}>
			<Grid item xs={12}>
				{props.asDialog ? (
					<FullScreenDialogSidebar
						iconBackAction={props.closeCallback}
						iconSaveAction={wrapCallback(saveToFireBase, props.asDialog)}
						iconDeleteAction={wrapCallback(deleteFromFireBase, props.asDialog)}
						title={t("customer.manageTitle")}
					/>
				) : (
					<div/>
				)}
			</Grid>
			<Grid item container xs={12}>
				<GlobalStyledPaper>
					<Grid item container alignItems={"flex-start"} direction={"row"} xs={12}>
						<GenericActionBar actions={actions}/>
						<Grid item xs={12}>
							<Typography variant={"h6"} align={"center"}>
								{t("customer.title") + ": " + localCustomer.name}
							</Typography>
							<GlobalStyledDivider/>
						</Grid>
						<Grid item xs={12}>
							<GlobalStyledTextField
								name={CustomerFieldNames.name}
								required
								label={t("customer." + CustomerFieldNames.name)}
								value={localCustomer.name}
								error={customerValidator.containsError(CustomerFieldNames.name)}
								helperText={customerValidator.getErrorMessage(CustomerFieldNames.name)}
								onChange={customerValidator.wrapOnChangeInValidator(onChange)}
								variant={"outlined"}
							/>
						</Grid>
						<CustomerAddressMask
							customerAddress={localCustomer.address}
							customerId={localCustomer.id}
							updateAddress={(newAddress) => {
								setLocalCustomer({...localCustomer, address: newAddress});
								if (props.isDirtyCallback) {
									setIsDirty(true);
									props.isDirtyCallback();
								}
							}}
						/>
					</Grid>
					<CustomerContactMask
						customerId={localCustomer.id}
						contacts={localCustomer.contacts}
						updateContacts={(contacts) => {
							setLocalCustomer({...localCustomer, contacts: contacts});
							if (props.isDirtyCallback) {
								setIsDirty(true);
								props.isDirtyCallback();
							}
						}}
					/>
				</GlobalStyledPaper>
			</Grid>
		</Grid>
	);
}

// @ts-ignore
export default CustomerMask;
