import {FieldValidator, proxiedPropertiesOf, ValidatorType} from "../../../ValidatorHook";
import CustomerModel, {CustomerContactModel} from "../../../../model/types/basistypes/ressources/CustomerModel";

let customer: CustomerModel;

// @ts-ignore
const properties = proxiedPropertiesOf(customer);
const nonEmptyString = "^(?!\\s*$).+";

const CustomerValidator: FieldValidator[] = [
	{
		FieldName: properties.name,
		Validation: "^[a-zA-Z ]+$",
		Type: ValidatorType.REGEX,
		ErrorMessage: "validator.nonEmpty",
	},

	{
		FieldName: properties.contacts,
		Validation: (contacts: CustomerContactModel[]) => {
			let regex = new RegExp(nonEmptyString)
			let customerContactModels = contacts.filter(contact => !regex.test(contact.name));
			return customerContactModels.length === 0;
		},
		Type: ValidatorType.Function,
		ErrorMessage: "validator.nonEmpty",
	},
];

export default CustomerValidator;
