import React, {useContext, useEffect, useRef, useState} from "react";
import EmployeeModel from "../../../../model/types/basistypes/ressources/EmployeeModel";
import {getEmployee} from "../../../../model/ModelController/Resources/EmployeeController";

import {registerUserSnapshotListener} from "../../../../model/ModelController/Permissions/UserController";
import UserModel from "../../../../model/types/permissions/UserModel";
import Role from "../../../../model/types/permissions/Role";
import {getRoles} from "../../../../model/ModelController/Permissions/RoleController";
import ResourceLayout from "../Helper/ResourcesLayout";
import ResourceList from "../Helper/ResourceList";
import UserItem from "./UserItem";
import {AppBarInfoAreaContext} from "../../../Sidebar/AppBarInfoAreaContext";
import {useTranslation} from "react-i18next";


export default function UserAdministration() {
    const [employees, setEmployees] = useState<EmployeeModel[]>([]);
    const [users, setUsers] = useState<UserModel[]>([]);
    const [roles, setRoles] = useState<Role[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<number>(0)
    const {t} = useTranslation();
    const appbarContext = useContext(AppBarInfoAreaContext);
    appbarContext.updateContent(t("users.manageTitle"));

    const userRef = useRef<UserModel[]>(users)
    const updateUserRef = (userModel: UserModel[]) => {
        userRef.current = userModel;
        setUsers(userModel);
    }

    const getLoginUser = (employee: EmployeeModel, users: UserModel[]): UserModel | null => {
        let filteredUsers = users.filter((value) => employee.id === value.employee.id)
        return (filteredUsers.length > 0) ? filteredUsers[0] as UserModel : null;
    }

    const updateUserSnapshot = (objectsToAdd: UserModel[], objectsToModify: UserModel[], objectsToDelete: UserModel[]) => {
        let newState = [...userRef.current];
        newState = newState.concat(objectsToAdd);

        for (let modifiedObject of objectsToModify) {
            let index = newState.findIndex(user => user.id === modifiedObject.id);
            if (index >= 0) {
                newState.splice(index, 1, modifiedObject);
            }
        }

        for (let deletedObject of objectsToDelete) {
            let index = newState.findIndex(user => user.id === deletedObject.id);
            if (index >= 0) {
                newState.splice(index, 1);
            }
        }

        updateUserRef(newState)
    }
    useEffect(() => {
        let unsubscribe: any;
        const fetchData = async () => {
            let localEmployees = await getEmployee();
            let localRoles = await getRoles();

            unsubscribe = await registerUserSnapshotListener(localEmployees, localRoles, updateUserSnapshot)

            setEmployees(localEmployees);
            setRoles(localRoles);
        }
        fetchData();

        return function cleanup() {
            console.log("Unsubscribed User Listener")
            if (unsubscribe)
                unsubscribe()
        }

    }, [])
    
    const setIndex = (resource: any, index: number) => {
        setSelectedEmployee(index)
    }
    return (
        <ResourceLayout
            left={
                <ResourceList resources={employees}
                              selectedItem={selectedEmployee}
                              getListItemText={(employee: EmployeeModel) => {
                                  return employee.surname + ", " + employee.name;
                              }}
                              resourceClickCallBack={setIndex}
                              addItemCallback={() => {
                              }}/>
            }
            right={
                (employees.length > 0) ?
                    <UserItem editEnabled={true}
                              employee={employees[selectedEmployee]}
                              roles={roles}
                              user={getLoginUser(employees[selectedEmployee], users)}
                              updateUserCallback={() => {
                              }}
                              index={selectedEmployee}
                    /> : <div/>
            }
        />)

}
